import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

import AlignEnum from '../../../helper/enumerador/AlignEnum';
import TableTypeEnum from '../../../helper/enumerador/TableTypeEnum';

class Table extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            modalCadastro: false,
            tituloCadastro: 'Novo'
        }
    }

    validarEditar() {
        return (this.props.remover !== undefined || this.props.editar !== undefined || this.props.buttonCallback !== undefined);
    }
    
    editarTd(item, i ) {
        if(!this.validarEditar()) {
            return;
        }

        return (
            <th className="text-right">
                { 
                    this.props.editar !== undefined &&
                    <button className="btnDefault"
                        onClick={() => this.setState({modalCadastro: true, tituloCadastro: 'Editar Usuario', editarItem: item, editarIndice: i})}>
                        <i className="fa fa-wrench" />
                    </button>
                }

                { 
                    this.props.remover !== undefined &&
                    <button className="btnDanger"
                        onClick={() => this.setState({modal: true, removerItem: item, removerIndice: i})}>
                        <i className="fa fa-times" />
                    </button>
                }

                { 
                    this.props.buttonCallback !== undefined &&
                    <button className="btnCallback"
                        onClick={() => this.props.onCallback(item)}>                        
                        <i className="fas fa-exchange-alt"></i>
                    </button>
                }
            </th>
        )
    }

    remover () {
        this.props.remover(this.state.removerItem, this.state.removerIndice);
        this.setState({modal: false})
    }

    cadastro() {
        if(this.props.cadastro === true) {
            return this.props.component({item: this.state.editarItem, indice: this.state.editarIndice});
        }
    }

    fecharCadastro() {
        this.setState({modalCadastro: false})
    }

    align(itemHead) {
        if(itemHead.align === undefined)
            return AlignEnum.LEFT.className;

        return itemHead.align.className;
    }

    exibiHeader(item) {         
        if(typeof item.nome === 'function')    
            return item.nome();
        
        return item.nome;
    } 

    exibeCampo(item, index, itemHead) {
        if(itemHead.type === undefined)
            return item[itemHead.campo];
        
        let tableType = TableTypeEnum.get(itemHead.type.enumName);        
        
        if(itemHead.type === TableTypeEnum.CHECKBOX)            
            return <input type="checkbox" value={item[itemHead.campo]} onClick={() => this.props.onCheck(item)} />        

        if(itemHead.scale !== undefined)
            return tableType.format(item[itemHead.campo], itemHead.scale, itemHead.isInteger);                     

        return tableType.format(item[itemHead.campo]); 
    }

    className(item, itemHead) {
        if(this.props.onClassName === undefined)
            return '';

        return this.props.onClassName(item, itemHead);        
    }

    onClick(item) {        
        if(this.props.clickCallback === undefined)
            return ;

        this.props.onCallback(item);
    }

    render() {
        return (
            <div>
                <Modal
                    backdrop={'static'}
                    show={this.state.modalCadastro}
                    onHide={() => this.setState({ modalCadastro: false })}>

                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.tituloCadastro}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.cadastro()}
                    </Modal.Body>

                </Modal>

                <Modal
                    show={this.state.modal}
                    onHide={() => this.setState({ modal: false })}>

                    <Modal.Body>
                        <Modal.Title>Tem certeza que deseja remover este registro?</Modal.Title>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btnDefault"
                                onClick={() => this.setState({ modal: false })}
                                style={{marginRight: '10px'}}>Não</button>
                        <button className="btnDanger"
                                onClick={() => this.remover()}>Sim</button>

                    </Modal.Footer>
                </Modal>

                {
                    this.props.cadastro &&
                    <button className="btnPrimary" 
                            style={{marginBottom: '15px'}}
                            onClick={() => this.setState({modalCadastro: true, tituloCadastro: 'Novo', editarItem: undefined, editarIndice: undefined})} >
                        <i className="fa fa-plus"/> Novo
                    </button>
                }

                <table className="table table-striped">
                    <thead>                        
                        <tr>
                            {this.props.head.map((item, i) => {
                                return (
                                    <th 
                                        key={i} 
                                        className={this.align(item)}
                                        width={item.width}
                                    >
                                        {this.exibiHeader(item)}
                                    </th>
                                )
                            })}

                            {this.validarEditar() && <th className="col-md-2" />}
                        </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(this.props.body) && this.props.body.map((item, i) => {
                        return (
                        <tr key={i} onClick={() => this.onClick(item)}>
                            {this.props.head.map((itemHead, i) => {
                                return (
                                    <td 
                                        key={i} 
                                        className={this.align(itemHead) + " " + this.className(item, itemHead)}                                        
                                    >
                                        {this.exibeCampo(item, i, itemHead)}
                                    </td>
                                )
                            })}

                            {this.editarTd(item, i)}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

Table.defaultProps = {
    codigo: 'id',    
};

export default  Table;