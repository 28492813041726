import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import InputRadio from './InputRadio';

import TipoEnderecoEnum from '../../../helper/enumerador/TipoEnderecoEnum';

class InputRadioTipoEndereco extends AbstractComponent {           
    constructor(props) {
        super(props);

        this.state = {
            tipoEndereco: TipoEnderecoEnum.getAll()        
        }        
    }

    getIndex(valor) {
        const tipoEndereco = this.state.tipoEndereco;
        let index = 0;
        let achou = false;

        tipoEndereco.forEach(element => {            
            if(element.valor === valor) {
                achou = true;
                return ;                
            }

            if(!achou)
                index++;
        });

        return index;
    }

    unCheckedAll() {
        const tipoEndereco = this.state.tipoEndereco;        

        tipoEndereco.forEach(element => {
            element.checked = false;            
        });
        
        this.setState({tipoEndereco: tipoEndereco});
    }

    onClick(nome) {
        this.unCheckedAll();

        let tipoEndereco = this.state.tipoEndereco;
        const index = this.getIndex(nome);

        tipoEndereco[index].checked = true;

        this.setState({tipoEndereco: tipoEndereco});

        this.props.onClick(tipoEndereco[index].valor);
    }

    componentDidMount() {
        this.onClick(this.props.padrao);
    }

    render() {
        return (
            <InputRadio
                md={this.props.md} 
                xs={this.props.xs} 
                sm={this.props.sm} 
                lg={this.props.lg}
                className={this.props.className}                

                options={this.state.tipoEndereco}                
                erro={this.props.erro}

                onClick={(e) => this.onClick(e.target.name)}
                onChange={(e) => this.props.onChange(e)}
            />
        )
    }
}

export default InputRadioTipoEndereco;