import React from 'react';
import AbstractComponent from '../AbstractComponent';
import InfiniteScrollTable from '../layout/infinitescroll/InfiniteScrollTable';
import FiltrosSelecionados from '../layout/pesquisa/FiltrosSelecionados';
import Button from '../layout/button/Button';

import FiltroEnum from '../../helper/enumerador/FiltroEnum';
import TableTypeEnum from '../../helper/enumerador/TableTypeEnum';

class CategoriaDesktopContent extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            body: [],            

            preloadFetch: false,

            pagina: 1,            

            items: [],

            selecionados: []
        }

        this.head = [
            {nome: 'DESCRIÇÃO', campo: 'descricao', type: TableTypeEnum.TEXT, width: '92%'}
        ]

        if(this.props.filtros.length > 0)
            this.state.selecionados = this.props.filtros;
    }                             
    
    autoComplete(e) {       
        const search = e.target.value;
        
        if(search === '')
            return ;                    

        FiltroEnum.get(
            this.props.header
        ).autoComplete(                        
            search
        ).then(resposta => {                 
            this.setState({                    
                items: resposta.data
            });            
        }).catch(erro => {
            this.props.onErro(this.trataErro(erro));
        });        
    }

    loadCategoria(fetchMoreData, inSearch) {
        if (fetchMoreData)
            this.setState({ preloadFetch: this.state.body.length > 0 });

        if (inSearch) 
            this.props.onPreload(true);

        let pagina = this.state.pagina;

        if (inSearch) {
            pagina = 1;
            this.setState({ pagina: 1 });
        }                       
        
        var input = document.getElementById('inputSearch');        

        let searchKey = '';

        if ((input !== null)&&(input !== undefined))
            searchKey = input.value;        

        FiltroEnum.get(
            this.props.header
        ).getAll(                        
            pagina,
            20,
            searchKey
        ).then(resposta => {     
            pagina = (this.state.pagina + 1);

            this.props.onPreload(false);

            this.setState({                                
                preloadFetch: false,
                pagina: pagina,                
                body: inSearch ? resposta.data : this.state.body.concat(resposta.data)
            });
        }).catch(erro => {
            this.props.onPreload(false);            

            this.setState({                                
                preloadFetch: false                
            });
        });
    }

    componentDidMount() {          
        this.loadCategoria(false, true);
    }        

    remove(item) {
        let selecionados = this.state.selecionados;                

        const index = selecionados.indexOf(item);        

        if(index > -1)
            selecionados.splice(index, 1);

        this.setState({selecionados: selecionados}); 
    }

    onInfiniteScrollCallback(item) {    
        if(this.props.onCallback !== undefined)
            this.props.onCallback(item.descricao);

        const chosen = item;
        let selecionados = this.state.selecionados;        

        chosen.marcado = chosen.marcado === 1 ? 0 : 1;
        
        if(chosen.marcado === 0) {                        
            this.remove(item);

            return ;
        }

        const index = selecionados.indexOf(item.descricao);

        if(index > -1)
            return ;

        selecionados.push(item.descricao);

        this.setState({selecionados: selecionados});
    }

    render() {
        return (
            <div style={{backgroundColor: '#ffffff', height: '100vh'}}>                
                <FiltrosSelecionados
                    md={12} 
                    xs={12} 
                    sm={12} 
                    lg={12}
                    filtro={this.state.selecionados}

                    onRemove={(item) => this.remove(item)}
                    clearFiltro={() => this.setState({selecionados: []})}
                />

                <InfiniteScrollTable
                    md={12} 
                    xs={12} 
                    sm={12} 
                    lg={12}                                      

                    className={"fixed-scroll-bottom"}
                    style={{
                        marginTop: '83px',
                        padding: '0px', 
                        position: 'fixed',
                        width: '94vw'
                    }}

                    scroll='67vh'

                    head={this.head}
                    body={this.state.body}  

                    cadastro={false}                    
                    clickCallback={true}  
                    
                    preloadFetch={this.state.preloadFetch}

                    onCallback={(item) => this.onInfiniteScrollCallback(item)}
                    fetchMoreData={() => this.loadCategoria(true, false)}                    
                />                          

                <div 
                    className="col-md-12
                               col-sm-12
                               col-lg-12
                               col-xs-12"                
                    style={{
                        bottom: '10px', 
                        position: 'fixed',
                        paddingLeft: '0px',
                        paddingRight: '20px',
                        backgroundColor: '#ffffff'
                    }}
                >
                    <Button
                        type="button"                        
                        className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"  
                        onClick={() => this.props.onApply(this.state.selecionados)}  
                        disabled={this.state.selecionados.length === 0}                                           
                    >                    
                        MOSTRAR ITENS
                    </Button>                    
                </div>
            </div>
        )
    }
}

export default CategoriaDesktopContent;