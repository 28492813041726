import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

import AutenticarService from '../../../service/AutenticarService';

import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';

import {moeda} from '../../../helper/Formatar';

class CarrinhoButton extends AbstractComponent {   
    constructor(props) {
        super(props);                   
        
        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    }                 

    render() {
        return(       
            <Fragment>
                {this.props.show &&
                    <button 
                        type="button" 
                        className={"btn btn--default btn--size-m context-card__btn-open " + (this.props.preload ? 'btn--preload' : '')}
                        onClick={() => this.props.onClick()}>
                        <div className={"product-action__add-button " + (this.props.preload ? '' : 'display-none')}>
                            <img src={Config.urlImgBase + "preload_botao.gif"} style={{width: '35px', height: '35px', marginTop: '-8px'}} alt="Loading..."/> <br/>
                        </div>

                        <div className={"row " + (this.props.preload ? 'display-none' : '')}>                            
                            <div className="col-md-2 
                                            col-sm-2
                                            col-lg-2
                                            col-xs-2
                                            text-left">
                                <span className="icon-marmita icon-marmita--bag">
                                    <i className={"header-icon-font icon-cart " + this.shoppingBagType.icon()} />
                                </span>
                                
                                <span className="cart-bar__amount">
                                    {(this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length)}
                                </span>
                            </div>                                                    

                            <div className="col-md-6 
                                            col-sm-6
                                            col-lg-6
                                            col-xs-6
                                            text-center ">
                                <span className="cart-bar__title">
                                    Ver {this.shoppingBagType.name()}
                                </span>
                            </div>
                            
                            <div className="col-md-4 
                                            col-sm-4
                                            col-lg-4
                                            col-xs-4
                                            text-right">
                                <span className="cart-bar__cost">
                                    {moeda(this.props.carrinho.totalizador.total)}
                                </span>
                            </div>                                                                                    
                        </div>
                    </button>
                }   
            </Fragment>
        )
    }
}

export default CarrinhoButton;