import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import Alerta from '../layout/alert/Alerta';
import ModalCadastro from '../cliente/ModalCadastro';
import Cadastro from '../cliente/Cadastro';
import LoginForm from './LoginForm'; 
import { getToken } from '../push-notification/firebase';

import AutenticarService from '../../service/AutenticarService';
import CarrinhoService from '../../service/CarrinhoService';
import ClienteAparelhoService from '../../service/ClienteAparelhoService';
import UsuarioService from '../../service/UsuarioService';

import Preload from '../layout/preload/Preload';

class LoginContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            modalCadastro: false,

            form: {},

            preload: false,

            erro: ''
        }        
    }                                      

    registraLogar(userName, token, documentoCliente, tipoLogin) {
        AutenticarService.logar(
            token,
            documentoCliente,
            userName,
            tipoLogin            
        );        

        this.setState( {preload: false} );
    }
    
    atribuirAparelho(userName, documentoCliente, token, tipoLogin) {
        ClienteAparelhoService.atribuirCliente(                       
            documentoCliente,
            AutenticarService.getUuidAparelho()
        ).then((resposta) => {
            if(resposta.status === 201) {                                
                AutenticarService.setNotificacaoOk(0);
                getToken(undefined);
            }

            this.registraLogar(userName, token, documentoCliente, tipoLogin);            
        }).catch(() => {
            AutenticarService.setNotificacaoOk(0);
            getToken(undefined);           
            this.registraLogar(userName, token, documentoCliente, tipoLogin);             
        });
    }  

    onLoginOk(userName, token, documentoCliente, tipoLogin) {    
        this.setState( {preload: true} );

        CarrinhoService.atribuirCliente(            
            AutenticarService.getIdCarrinho(),
            documentoCliente
        ).then(() => {
            this.atribuirAparelho(userName, documentoCliente, token, tipoLogin);
        }).catch(() => {
            CarrinhoService.getIdCarrinhoByDocumento(                
                documentoCliente
            ).then(response => {
                AutenticarService.setIdCarrinho(response.data);
                this.atribuirAparelho(userName, documentoCliente, token, tipoLogin);
            }).catch(() => {
                AutenticarService.setIdCarrinho('');
                this.atribuirAparelho(userName, documentoCliente, token, tipoLogin);                
            });
        });
    }

    logarByRedeSocial(documentoCliente, idRedeSocial, tipo_rede_social) {
        tipo_rede_social.logar(
            documentoCliente,
            idRedeSocial
        ).then(resposta => {
            this.onLoginOk(
                resposta.data.nome, 
                resposta.data.token, 
                resposta.data.documentoCliente,
                tipo_rede_social.enumName           
            );            
        }).catch(() => {
            this.setState({                 
                erro: 'Usuário ou senha inválido!'
            });
        });
    }

    logarByCredentials(email, senha) {
        UsuarioService.logarCredentials(            
            email,
            senha
        ).then(resposta => {
            this.onLoginOk(
                resposta.data.nome, 
                resposta.data.token, 
                resposta.data.documentoCliente,
                'credentials'
            );            
        }).catch((error) => {
            this.setState({                 
                erro: this.trataErro(error)
            });
        });
    }

    logar(email, senha, documentoCliente, idRedeSocial, tipo_rede_social) {                      
        if(typeof tipo_rede_social === 'object') {
            this.logarByRedeSocial(documentoCliente, idRedeSocial, tipo_rede_social);

            return ;
        }

        this.logarByCredentials(email, senha);
    } 

    render() {
        return (
            <Fragment>
                <Preload exibir={this.state.preload || this.props.inPreload} />

                <Alerta 
                    exibir={this.state.erro !== ''}
                    texto={this.state.erro} 
                />

                <ModalCadastro
                    show={this.state.modalCadastro}
                    fechar={e => this.setState({modalCadastro: false})}
                    cpf={this.state.form.cpf}
                    cadastro={true}
                    empresa={this.props.empresa}    
                    onLoginOk={(userName, token, documentoCliente, tipoLogin) => this.onLoginOk(userName, token, documentoCliente, tipoLogin)}
                    logar={(email, senha, documentoCliente, idRedeSocial, tipo_rede_social) => this.logar(email, senha, documentoCliente, idRedeSocial, tipo_rede_social)}
                    component={(props) => {
                        return <Cadastro
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}
                        form={this.state.form} {...props} />
                    }}
                />

                <LoginForm                          
                    empresa={this.props.empresa}
                                  
                    onRegister={(form) => this.setState({modalCadastro: true, form: form})}
                    onLoginOk={(userName, token, documentoCliente, tipoLogin) => this.onLoginOk(userName, token, documentoCliente, tipoLogin)}
                    logar={(email, senha, documentoCliente, idRedeSocial, tipo_rede_social) => this.logar(email, senha, documentoCliente, idRedeSocial, tipo_rede_social)}
                    route={(item) => this.props.route(item)}
                />
            </Fragment>
        )
    }
}

export default LoginContent;
