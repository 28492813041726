import React from "react";
import RequisicaoService from "../../../service/RequisicaoService";
import AbstractComponent from "../../AbstractComponent";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";


class Resgate extends AbstractComponent {
  render() {
    return (
      <>
        <h1>Resgates executados</h1>
        <Table hover>
          <thead>
            <tr>
              <th>Data</th>
              <th>Nota</th>
              <th>Emisor Fiscal</th>
              <th className="valorResgatado">Valor</th>
            </tr>
          </thead>
          <tbody>
            {this.props.resgate.map((item, i) => (
              <tr key={i}>
                {parseInt(this.props.idCab) == parseInt(item.id_cab) && (
                  <>
                    <th scope="row">{item._d_a_t_a}</th>
                    <td>{item.nota}</td>
                    <td>{item.ecf}</td>
                    <td className="valorResgatado">
                      {parseFloat(item.debito).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                  </>
                ) }
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default Resgate;
