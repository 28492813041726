import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import EnderecoContent from './content/EnderecoContent';

import EmpresaFreteService from '../../service/EmpresaFreteService';

import TipoEnderecoEnum from '../../helper/enumerador/TipoEnderecoEnum';
import TipoCalculoFreteEnum from '../../helper/enumerador/TipoCalculoFreteEnum';

import { buscaConfiguracao } from '../../helper/Validar';
import Alerta from '../layout/alert/Alerta';

class Endereco extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            forcaPontoInteresse: false,
            
            erro : '',

            form: {                
                tipo_endereco: 'Bairro',
                cep: '',
                logradouro: '',
                endereco: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                uf: '',
                ponto_de_interesse: '',
                ponto_de_interesse_manual: 'Nao',
                ponto_de_interesse_descricao: '',
                codigo_municipio: ''                
            },

            erroForm: {
                cep: '',
                logradouro: '',
                endereco: '',
                numero: '',
                bairro: '',
                cidade: '',
                ponto_de_interesse: '',
                ponto_de_interesse_descricao: '',
                codigo_municipio: ""
            },
        }
    }

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;        

        if(e.target.name === 'ponto_de_interesse') {
            form['ponto_de_interesse_manual'] = 'Sim';

            if(form['tipo_endereco'] === TipoEnderecoEnum.POI.name)  
                form['codigo_municipio'] = this.props.empresa.codigo_municipio
        }

        this.setState({ form: form });

        
    }

    onBlurBairro(pontoDeInteresse, forcaPontoInteresse) {
        const form = this.state.form;

        form['ponto_de_interesse'] = pontoDeInteresse;              

        this.setState({ 
            form: form,
            forcaPontoInteresse: forcaPontoInteresse
        });
    }


    avisoCidade() {
        let cidadeDisponivel = true;

        for (let j = 0; j < this.props.cidadesDisponiveis.length; j++) {
          if (
            this.props.cidadesDisponiveis[j].codigo_municipio !==
            this.state.form.codigo_municipio
          ) {
            cidadeDisponivel = false;
          } else {
            cidadeDisponivel = true;
            this.setState({ erro: ""})
          }
          if (cidadeDisponivel == false) {
            this.setState({ erro: "Não realizamos entregas no seu município."})
          }
        }
      }

    onBlurCep(endereco) {        
        this.preencheEndereco(endereco);
        this.avisoCidade();
        EmpresaFreteService.pontoDeInteresseExists(            
            this.state.form.tipo_endereco,
            endereco['bairro']
        ).then(() => {   
            const form = this.state.form;

            form.ponto_de_interesse = endereco['bairro'];            

            this.setState({ form: form });

            if(this.state.form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
                this.setState({forcaPontoInteresse: false});                            
        }).catch(() => {                                    
            this.setState({forcaPontoInteresse: true});            
        });        
    }  

    onRadioTipoEnderecoClick(tipo_endereco) {
        const form = this.state.form;

        form.tipo_endereco = tipo_endereco;

        this.setState({form: form});
    }

    preencheEndereco(endereco) {
        const form = this.state.form;

        form.logradouro = endereco['logradouro'];
        form.endereco = endereco['endereco'];
        form.bairro = endereco['bairro'];
        form.cidade = endereco['cidade'];
        form.uf = endereco['uf'];
        form.codigo_municipio = endereco['codigo_municipio'];
        form.numero = '';
        form.complemento = '';
        form.ponto_de_interesse = ''; 
        form.ponto_de_interesse_manual = 'Nao';
        form.ponto_de_interesse_descricao = '';

        this.setState({
            form: form
        });   
    }  

    limpaVariavelErro() {
        let erro = this.state.erroForm;

        erro.cep = '';
        erro.logradouro = '';
        erro.endereco = '';
        erro.numero = '';
        erro.bairro = '';
        erro.cidade = '';
        erro.uf = '';
        erro.ponto_de_interesse = ''; 
        erro.ponto_de_interesse_manual = 'Nao';
        erro.ponto_de_interesse_descricao = '';
        erro.codigo_municipio = '';

        this.setState({ erroForm: erro });
    } 

    valida() {
        this.limpaVariavelErro();

        let form = this.state.form;
        let erro = this.state.erroForm;
        let exibeErro = false;

        if(form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name) {
            if (!form.cep) {
                erro.cep = "Informe o campo CEP";
                exibeErro = true;
            }

            if (!form.logradouro) {
                erro.logradouro = "Informe o campo Logradouro";
                exibeErro = true;
            }

            if (!form.endereco) {
                erro.endereco = "Informe o campo Endereço";
                exibeErro = true;
            }

            if (!form.numero) {
                erro.numero = "Informe o campo Número";
                exibeErro = true;
            }

            if (!form.bairro) {
                erro.bairro = "Informe o campo Bairro";
                exibeErro = true;
            }

            if (!form.cidade) {
                erro.cidade = "Informe o campo Cidade";
                exibeErro = true;
            }

            if (!form.uf) {
                erro.uf = "Informe o campo UF";
                exibeErro = true;
            }

            if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.BAIRRO_POI.name) {
                if ((!form.ponto_de_interesse)||(form.ponto_de_interesse === '0')) {          
                    erro.ponto_de_interesse = 'Informe o ponto de interesse';
                    exibeErro = true;
                }
            }
        }

        if(form.tipo_endereco === TipoEnderecoEnum.POI.name) {            
            if(!form.ponto_de_interesse) {            
                erro.ponto_de_interesse = 'Informe o ponto de interesse';
                exibeErro = true;
            }

            if(!form.ponto_de_interesse_descricao) {            
                erro.ponto_de_interesse_descricao = 'Informe o campo descritivo do ponto de interesse';
                exibeErro = true;
            }            
        }        

        if (exibeErro) {
            this.setState({ erroForm: erro });

            return;
        }
                
        this.props.onComplete(this.state.form);
    }

    showComponent() {
        if(!this.props.show)
            return ;        

        return (
            <Fragment>
                <Alerta exibir={this.state.erro !== ""} texto={this.state.erro} />
                <EnderecoContent
                cidadesDisponiveis = {this.props.cidadesDisponiveis}
                    form={this.state.form}                            
                    erroForm={this.state.erroForm} 
                    empresa={this.props.empresa}     
                    forcaPontoInteresse={this.state.forcaPontoInteresse}   
                    isEndereco={true}  
              

                    onChange={(e) => this.onChange(e)}
                    onBlurCep={(e) => this.onBlurCep(e)}
                    onBlurBairro={(pontoDeInteresse, forcaPontoInteresse) => this.onBlurBairro(pontoDeInteresse, forcaPontoInteresse)}
                    onRadioTipoEnderecoClick={(tipo_endereco) => this.onRadioTipoEnderecoClick(tipo_endereco)}             
                    onTitulo={(title) => this.props.onTitulo(title)}
                />

                <div className="row">
                    <div className="text-right col-md-12">
                        {
                            !this.props.editando &&
                            <button
                                type="button"
                                className="btn btn-success btn-lg btn-block"
                                onClick={() => this.valida()}>
                                { this.props.btnName }
                                &nbsp;
                                &nbsp;

                                {
                                    this.props.image &&
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                }
                            </button>
                        }

                        {
                            this.props.editando &&
                            <button className="btn btn-success btn-lg btn-block"
                                    type="submit"
                                    disabled={(this.props.btnFinalizarTxt !== 'FINALIZAR')}>
                                {this.props.btnFinalizarTxt}
                            </button>
                        }
                    </div>
                </div>
            </Fragment>
        );
    }    

    render() {
        return (
            <Fragment>
                {this.showComponent()}
            </Fragment>
        );
    }
}

Endereco.defaultProps = {
    editando: false,
    image: false
}

export default Endereco;