import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import Alerta from '../layout/alert/Alerta';
import Lista from './Lista';

import AutenticarService from '../../service/AutenticarService';
import NotificacaoService from '../../service/NotificacaoService';

import AtivoEnum from '../../helper/enumerador/AtivoEnum';

class NotificacaoContent extends AbstractComponent {
    constructor(props) {
        super(props);        

        this.state = {                                                                           
            userLogged: true,              
                                
            body: []
        }

    }    

    afterLoad(index) {
        let body = this.state.body;
        let item = body[index];

        item.visualizado = AtivoEnum.SIM;

        body.splice(index, 1, item);

        this.setState({body: body}); 

        this.props.onUpdateNotificacao();
    }

    componentDidMount() {
        this.props.showPreload(true);

        this.setState({userLogged: this.isLogged()});                                                          

        const userLogged = this.state.userLogged;            
        
        NotificacaoService.index(            
            userLogged ? AutenticarService.getDocumentoCliente() : '',
            AutenticarService.getUuidAparelho()
        ).then(response => {                     
            this.setState({body: response.data});             

            this.props.showPreload(false);
        }).catch(erro => {      
            this.props.onErro(this.trataErro(erro));      
            this.props.showPreload(false);
        });
    }

    render() {
        return (
            <Fragment>                                                                                          
                <Alerta
                    texto="FAÇA O LOGIN PARA ACESSAR AS NOTIFICAÇÕES EXCLUSIVAS"
                    exibir={!this.state.userLogged}
                />                
                
                <Lista
                    body={this.state.body}

                    afterLoad={(index) => this.afterLoad(index)}
                    onErro={(erro) => this.props.onErro(erro)}                    
                />                                                 
            </Fragment>
        )
    }
}

export default NotificacaoContent;