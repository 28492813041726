
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class ProdutoDepartamentoService {    
    static async getAll(pagina, limite, searchKey) {        
        return Requisicao.post(Config.urlBaseApi + 'produto/departamento', {            
            pagina: pagina,
            limite: limite,
            searchKey: searchKey
        });
    }  
    
    static async autoComplete(search) {        
        return Requisicao.get(Config.urlBaseApi + 'produto/departamento/autocomplete/' + search);
    }
}

export default ProdutoDepartamentoService;