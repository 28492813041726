import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from "../../layout/input/Input";
import InputCep from '../../layout/input/InputCep';
import InputSelectPontoInteresse from '../../layout/input/InputSelectPontoInteresse';
import InputSelectLogradouro from '../../layout/input/InputSelectLogradouro';
import InputSelectUF from '../../layout/input/InputSelectUF';
import InputRadioTipoEndereco from '../../layout/input/InputRadioTipoEndereco';
import InputTextArea from '../../layout/input/InputTextArea';
import InputBairro from '../../layout/input/InputBairro';

import TipoCalculoFreteEnum from '../../../helper/enumerador/TipoCalculoFreteEnum';
import TipoEnderecoEnum from '../../../helper/enumerador/TipoEnderecoEnum';

import { buscaConfiguracao } from '../../../helper/Validar';

class EnderecoContent extends  AbstractComponent {   
    constructor(props) {
        super(props);
        
        this.state = {
            logradouros: [],            
        }              
    }   

    onLoad(lista) {                
        this.setState({logradouros: lista});        
    }                              

    showTipoEndereco() {     
        if(this.props.isEndereco)
            return ;
                
        if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.CIDADE.name) 
            return ;
        
        if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.DISTANCIA.name)
            return ;        

        return (
            <div className="row">
                <InputRadioTipoEndereco
                    md={12}
                    xs={12}
                    sm={12}
                    lg={12}  
                    
                    padrao={this.props.form.tipo_endereco}

                    onClick={tipo_endereco => this.props.onRadioTipoEnderecoClick(tipo_endereco)}
                    onChange={tipo_endereco => this.props.onRadioTipoEnderecoClick(tipo_endereco)}
                />                       
            </div>
        )
    }        

    showBairro() {        
        if(this.props.form.tipo_endereco !== TipoEnderecoEnum.BAIRRO.name){
            return ;

        }  
        return (
            <Fragment>
                <div className="row">                    
                    <InputCep
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        maxlength={9}    
                        inputRef={e => this.cep = e}
                        id="cep"
                        nome="cep"                                                        
                        logradouros={this.state.logradouros}
                        value={this.props.form.cep}
                        valueOld={this.props.form.cepOld}
                        defaultValue={this.props.form.cep}
                        defaultFoco={true}
                        erro={this.props.erroForm.cep}
                        proxFoco={() => this.foco('numero')}
                        onChange={e => this.props.onChange(e)}
                        onBlur={e => this.props.onBlurCep(e)}>
                        CEP
                    </InputCep>                                                
                </div>

                <div className="row">                      
                    <InputSelectLogradouro
                        md={4}
                        xs={4}
                        sm={4}
                        lg={4}                                                        
                        nome="logradouro"                         
                        value={this.props.form.logradouro}    
                        defaultValue={this.props.form.logradouro}    
                        erro={this.props.erroForm.logradouro}                          
                        onLoad={ e => this.onLoad(e) }                                
                        onChange={ e => this.props.onChange(e) } />
                    
                    <Input
                        md={8}
                        xs={8}
                        sm={8}
                        lg={8}
                        nome="endereco"                        
                        value={this.props.form.endereco}
                        defaultValue={this.props.form.endereco}
                        erro={this.props.erroForm.endereco}
                        onChange={e => this.props.onChange(e)}>
                        Endereço
                    </Input>                                                        
                </div>

                <div className="row">                        
                    <Input
                        md={3}
                        xs={3}
                        sm={3}
                        lg={3}
                        inputRef={e => this.numero = e}
                        id="numero"
                        nome="numero"                        
                        value={this.props.form.numero}
                        defaultValue={this.props.form.numero}
                        erro={this.props.erroForm.numero}
                        onChange={e => this.props.onChange(e)}>
                        Nro
                    </Input>

                    <Input
                        md={9}
                        xs={9}
                        sm={9}
                        lg={9}
                        nome="complemento"                        
                        value={this.props.form.complemento}
                        defaultValue={this.props.form.complemento}                                
                        onChange={e => this.props.onChange(e)}>
                        Complemento
                    </Input>                                                       
                </div>         

                <div className="row">
                    <InputBairro
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        nome="bairro"        
                        tipo_endereco={this.props.form.tipo_endereco} 
                        empresa={this.props.empresa}
                                       
                        value={this.props.form.bairro}
                        defaultValue={this.props.form.bairro}
                        erro={this.props.erroForm.bairro}
                        onChange={e => this.props.onChange(e)}
                        onBlur={(pontoDeInteresse, forcaPontoInteresse) => this.props.onBlurBairro(pontoDeInteresse, forcaPontoInteresse)}>
                        Bairro
                    </InputBairro> 
                </div>               

                <div className="row">
                    <Input
                        md={8}
                        xs={8}
                        sm={8}
                        lg={8}
                        nome="cidade"                        
                        value={this.props.form.cidade}
                        defaultValue={this.props.form.cidade}
                        erro={this.props.erroForm.cidade}
                        onChange={e => this.props.onChange(e)}>
                        Cidade
                    </Input>                                                       

                    <InputSelectUF
                        md={4}
                        xs={4}
                        sm={4}
                        lg={4}                                                        
                        nome="uf"                         
                        value={this.props.form.uf}   
                        defaultValue={this.props.form.uf}   
                        erro={this.props.erroForm.uf}                               
                        onChange={e => this.props.onChange(e)} />
                </div> 
            </Fragment>
        )
    }

    showPontoInteresse() {                     
        if((this.props.form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)&&(this.props.form.cep === ''))
            return ;
           
        if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.CIDADE.name)
            return ;

        if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.DISTANCIA.name)
            return ;
        
        if((this.props.form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)&&(!this.props.forcaPontoInteresse))
            return ;           
            
        return (
            <div className="row">
                <InputSelectPontoInteresse
                    md={12}
                    xs={12}
                    sm={12}
                    lg={12} 
                    nome="ponto_de_interesse"
                    tipoEndereco={this.props.form.tipo_endereco}
                    value={this.props.form.ponto_de_interesse}
                    defaultValue={this.props.form.ponto_de_interesse}
                    erro={this.props.erroForm.ponto_de_interesse}                        
                    onChange={e => this.props.onChange(e)}
                >
                    Ponto de interesse
                </InputSelectPontoInteresse>
            </div>   
        )
    }        

    showPontoInteresseDescricao() {
        if(this.props.form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
            return ;

        return (
            <div className="row">
                <InputTextArea
                    md={12}
                    xs={12}
                    sm={12}
                    lg={12} 
                    rows={3}
                    maxlength={255}
                    nome="ponto_de_interesse_descricao"  
                    placeholder="Exemplo: Entregar na loja 01 para Maria"                  
                    value={this.props.form.ponto_de_interesse_descricao}
                    defaultValue={this.props.form.ponto_de_interesse_descricao}
                    erro={this.props.erroForm.ponto_de_interesse_descricao}                        
                    onChange={e => this.props.onChange(e)}
                >
                    Detalhe em poucas palavras o Ponto de Interesse
                </InputTextArea>
            </div>   
        )
    }

    componentDidMount() {
        if(this.props.onTitulo !== undefined)
            this.props.onTitulo('Passo 2: Endereço');
    }

    render () {
        return (                                             
            <Fragment> 
                {this.showTipoEndereco()}

                {this.showBairro()}    

                {this.showPontoInteresse()}                                

                {this.showPontoInteresseDescricao()}   
            </Fragment>                
        )
    }
}

EnderecoContent.defaultProps = {
    addEndereco: false,
    isEndereco: false
}

export default EnderecoContent;