import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Config from '../../Config';

class PedidoEmpty extends AbstractComponent { 
    render() {
        return (  
            <div className="col-md-12                                 
                            col-sm-12                                                
                            col-lg-12                                                
                            col-xs-12">                
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-6">
                        <div className="text-center mt-4">
                            <img 
                                className="mb-4 img-error" 
                                alt="Produto não encontrado" 
                                src={Config.urlImgBase + "product-not-found-cupomTriste.png"}
                            />
                            
                            <h5 className="lead">
                                <b>
                                    <span className="product-not-found">
                                        Ops! &nbsp;
                                    </span> 
                                                                        
                                    nenhum pedido encontrado                                    
                                </b>
                            </h5>
                        </div>                        
                    </div>
                </div> 
            </div>                                             
        )
    }
}

export default PedidoEmpty;