import Config from "../Config";
import Requisicao from "./RequisicaoService";

class PedidoService {
    static async getPedidosByCpf(documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'pedido/getPedidosByCpf', {            
            documentoCliente: documentoCliente
        });
    }

    static async gerar(dados) {
        return Requisicao.post(Config.urlBaseApi + 'pedido', dados);
    }
}

export default PedidoService;