import React, {Fragment} from 'react';
import AbstractComponent from '../../AbstractComponent';

import Config from '../../../Config';

import './MyCarousel.css';

class MyCarousel extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: [],
            active: 0,
            total: 0
        }
    }

    componentDidMount() {
        this.setState({
            selectedItem: this.props.galeria.length > 0 ?  this.props.galeria[0] : {},
            total: this.props.galeria.length
        });
    }

    getImage() {
        if((this.state.selectedItem.imagem_path === undefined)||(this.state.selectedItem.imagem_path === null)||(this.state.selectedItem.imagem_path === ''))
            return (
                <img
                    src={Config.urlImgBase + "produto-sem-imagem.jpg"}
                    alt={"Imagem de " + this.props.descricao}
                    style={{width: '200px', height: 'auto'}}/>
            );

        return (
            <img                                
                src={Config.urlImgBase + this.state.selectedItem.imagem_path}
                data-size="618x463"
                alt={"Imagem de " + this.props.descricao}
                title={this.props.descricao}
            />
        );
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div
                        className="col-md-2
                                   col-sm-2
                                   col-lg-2
                                   col-xs-12
                                   text-center
                                   carousel-list" >
                        <ul className={this.props.className} style={{marginTop: '0.5rem'}}>
                            {this.props.galeria.map((item, i) => {
                                return (
                                    <Fragment key={i}>
                                        {
                                            // i < this.props.maxThumbs &&
                                            <li
                                                className={"showcase-product__thumbs " + (this.state.active === i ? 'showcase-product__thumb--active' : '')}
                                                style={{backgroundColor: '#ffffff'}}
                                                key={i}
                                            >
                                                <a
                                                    className="showcase-product__thumb js-showcase-thumb js-showcase-thumb-img"
                                                    title={"Link para " + this.props.descricao}
                                                    onClick={() => this.setState({selectedItem: item, active: i})}
                                                >
                                                    <img
                                                        itemProp="thumbnailUrl"
                                                        className="carousel-thumbnail"                                                                                                                
                                                        src={Config.urlImgBase + item.imagem_path}
                                                        alt={"Imagem de " + this.props.descricao}
                                                    />
                                                </a>
                                            </li>
                                        }

                                        {/* {
                                            i === this.props.maxThumbs &&
                                            <li
                                                className="showcase-product__thumbs"
                                                style={{height: '24px', backgroundColor: '#F1EFF1', border: 0}}
                                                key={i}
                                            >
                                                <a
                                                    className="showcase-product__thumb js-showcase-thumb js-showcase-thumb-img"
                                                    style={{height: '24px'}}
                                                    title={"Link para " + this.props.descricao}
                                                    // onClick={() => this.setState({selectedItem: item})}
                                                    onClick={() => console.log(item)}
                                                >
                                                    <strong className="showcase-product__thumb-layer-number js-pop-up js-carousels" data-title="Showcase" data-wrapperid="popup-product" data-content="showcase">
                                                        + {this.state.total - i}
                                                    </strong>
                                                </a>
                                            </li>
                                        } */}
                                    </Fragment>
                                )
                            })}
                        </ul>
                    </div>

                    <div align="center"
                         className="col-md-10
                                    col-sm-10
                                    col-lg-10
                                    col-xs-12
                                    text-center
                                    carousel-img">
                        <a
                            title={"Link para " + this.props.descricao}>
                            {this.getImage()}
                        </a>
                    </div>
                </div>
            </Fragment>
        )
    }
}

MyCarousel.defaultProps = {
    descricao: '',
    maxThumbs: 99
}

export default MyCarousel;