import {isNumero} from '../Validar';

var TipoCalculoEnum = {
    QUANTIDADE: {
        enumName: 'QUANTIDADE',
        getValue: function(value) {
            return parseInt(value, 10)
        },
        show: function(value) {
            return value;
        },
        prepare: function(value) {
            return value;
        },
        isNumber: function(key) {
            return isNumero(key);
        }
    },

    PESO: {
        enumName: 'PESO',
        getValue: function(value) {
            if((typeof value) === 'string')
                value = value.replace(',', '.');

            return parseFloat(value);
        },
        show: function(value) {
            return Number(value).toLocaleString(undefined, {
                style                : 'decimal',
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
            });
        },
        prepare: function(value) {
            return value.toFixed(3);
        },
        isNumber: function(key) {
            return key;
        }
    },

    get(value) {
        if((value === null)||(value === undefined))
            return TipoCalculoEnum.QUANTIDADE;

        var array = Object.values(TipoCalculoEnum);
        var tipoCalculoFound = {};

        array.forEach(element => {
            if(element.enumName === value.toUpperCase()) {
                tipoCalculoFound = element;
                return ;
            }
        });

        return tipoCalculoFound;
    }
}

export default TipoCalculoEnum;