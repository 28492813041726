import React, { Fragment, useState } from "react";
import AbstractComponent from "../../AbstractComponent";
import Config from "../../../Config";
import Cpf from "./Cpf";
import Password from "./Password";

class LoginByCredentials extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        id: 0,
        idEmpresa: 0,
        idRedeSocial: "",
        nome: "",
        cpf: "",
        email: "",
        tipo_rede_social: "",
      },
    };
  }

  onCpfOk(form) {
    this.setState({ form: form });
    this.props.nextStep();
  }

  macScanner() {
    if (navigator.userAgent.indexOf("Mac") != -1) {
      this.props.nextStep();
    } else if (navigator.userAgent.indexOf("like Mac") != -1) {
      this.props.nextStep();
    } else if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      this.props.nextStep();
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
    } else {
    }
  }

  componentDidMount() {
    this.macScanner();
  }

  showCredentials() {
    if (this.props.passo === 1) return;

    return (
      <Fragment>
        <Cpf
          show={this.props.passo === 2}
          form={this.state.form}
          social={this.props.social}
          readOnly={this.props.onReadOnly}
          onCpfOk={(form) => this.onCpfOk(form)}
          onCpfErro={(form) => this.props.onRegister(form)}
        />

        <Password
          form={this.state.form}
          show={this.props.passo === 3}
          social={this.props.social}
          onLoginOk={(userName, token, documentoCliente, tipoLogin) =>
            this.props.onLoginOk(userName, token, documentoCliente, tipoLogin)
          }
          route={(item) => this.props.route(item)}
        />

        <div className="row">
          <div className="col-md-12 text-center">
            <h6>
              Ao cadastrar-se, você concorda com nossos
              <br />
              <a href={Config.urlSite + "termos-de-uso"}>Termos</a> e{" "}
              <a href={Config.urlSite + "politica-de-privacidade"}>
                Política de Privacidade
              </a>
            </h6>
          </div>
        </div>
      </Fragment>
    );
  }

  showComponent() {
    if (this.props.passo !== 1) return;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 text-center">
            <span
              style={{
                fontSize: "12px",
                color: "#999999",
              }}
            >
              Seus dados pessoais serão respeitados de acordo
              <br />
              com nossa &nbsp;
              <a href={Config.urlSite + "politica-de-privacidade"}>
                <strong>política de privacidade</strong>
              </a>
              . Nada será
              <br />
              publicado sem sua autorização.
            </span>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-md-12 text-center">
            <button
              id="btnEntrar"
              type="button"
              className="btn btn-control btn-success btn-lg"
              disabled={this.props.readOnly}
              onClick={() => this.props.nextStep()}
            >
              <i className="fa fa-user" />

              <span className="text-size">ENTRE COM SEU CPF</span>
            </button>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.showComponent()}

        {this.showCredentials()}
      </Fragment>
    );
  }
}

export default LoginByCredentials;
