import Config from "../Config";
import Requisicao from "./RequisicaoService";

class CampanhaBannerService {
    static async banners() {
        var retorno = Requisicao.get(Config.urlBaseApi + 'campanha');
        return retorno;
    }
    static async produtosCampanha(uuid_campanha) {
        // alert(uuid_campanha)
        var retorno = Requisicao.get(Config.urlBaseApi + 'campanhakeys/' + uuid_campanha);
        return retorno;
    }
}

export default CampanhaBannerService;