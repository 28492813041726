function decimalAdjust(type, value, exp) {
    // Se exp é indefinido ou zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }

    value = +value;
    exp = +exp;

    // Se o valor não é um número ou o exp não é inteiro...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }

    // Transformando para string
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));

    // Transformando de volta
    value = value.toString().split('e');
    
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// Arredondamento decimal
// round(1.005, -2); // 1.01
export function roundNum(value, exp) {
    return decimalAdjust('round', value, exp);    
}

// Decimal arredondado para baixo
// floor(55.59, -1); // 55.5
export function floor(value, exp) {
    return decimalAdjust('floor', value, exp); 
}

// Decimal arredondado para cima
// ceil(55.51, -1); // 55.6
export function ceil(value, exp) {    
    return decimalAdjust('ceil', value, exp);    
}

export function calculaDesconto(venda, promocao) {    
    let valor = venda;

    if(valor === 0)
        return 0;                                      

    return roundNum((1 - (promocao / valor)), -2) * 100;
}    

export function imprimirPorcentagem(value) {     
    const desconto = value.toString();                    

    return Math.round(desconto) + '% OFF';
}