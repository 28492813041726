import React from "react";
import AbstractComponent from "../../../AbstractComponent";
import DesktopMenu from "./DesktopMenu";
import MenuPerfil from "../MenuPerfil";

class DesktopPerfilMenu extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLogged: false,
    };

    this.state.isLogged = this.isLogged();
  }

  buttonClick() {
    if (!this.state.isLogged) {
      this.props.onClick(this.login());
      return;
    }

    this.props.onShowUserMenu();
  }

  render() {
    return (
        <DesktopMenu
          md={this.props.md}
          xs={this.props.xs}
          sm={this.props.sm}
          lg={this.props.lg}
          showUserMenu={this.props.showUserMenu}
          icon="far fa-user"
          buttonClick={() => this.buttonClick()}
        >
          <MenuPerfil
            cliente={this.props.cliente}
            onClick={(item) => this.props.onClick(item)}
          />
        </DesktopMenu>
    );
  }
}

export default DesktopPerfilMenu;
