import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Image from '../layout/image/Image';
import Config from '../../Config';

class FotoPerfil extends AbstractComponent {    
    render() {
        return (
            <div className="logo text-center" style={{ height: '' }}>
                <div className="row justify-content-center align-self-center">
                    <Image
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}  
                        semImagem={Config.urlImgBase + "logo/logo.png"}                      
                        imagem={this.props.image}
                        width='auto'
                        heightImage='6em'  
                        height='6em'
                        borderRadius="50px"   
                        semImagemBorderRadius="0px"                   
                        alt="Logo"
                    />                   
                </div>                

                <div className="row justify-content-center align-self-center" style={{marginTop: '5px'}}>
                    <div className="col-md-12 ">
                        <h4>
                            Bem vindo!
                            <br/>                            
                            Entre ou crie sua conta
                        </h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default FotoPerfil;