import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from './Input';
import InputSelect from './InputSelect';

import AmbienteEnum from '../../../helper/enumerador/AmbienteEnum';

import { CARTAO_CREDITO_HOMOLOGACAO } from '../../../helper/Validar';

class InputCredCard extends AbstractComponent {   
    constructor(props) {
        super(props);        

        this.listas = CARTAO_CREDITO_HOMOLOGACAO;

        let lista = this.listas;

        if(lista[0].valor !== 0) {
            this.listas.unshift({valor: 0, texto: 'Selecione'})        
        }
    }   

    onChange(input) {                   
        var inputStr = input.target.value;
        
        inputStr = inputStr.replace(/\D/g,"");        
        inputStr = inputStr.replace(/(\d{4})(\d)/,"$1 $2");
        inputStr = inputStr.replace(/(\d{4})(\d)/,"$1 $2");
        inputStr = inputStr.replace(/(\d{4})(\d)/,"$1 $2");
        inputStr = inputStr.replace(/(\d{4})(\d)/,"$1 $2");        

        input.target.value = inputStr;

        if (this.props.onChange !== undefined) {
          this.props.onChange(input);
        }        
    }        
    
    onChangeSelect(input) {
        if (this.props.onChange !== undefined) {
            this.props.onChange(input);
        }
    }

    showComponent() {
        if(this.props.empresa.ambiente === AmbienteEnum.DEVELOPMENT) {
            return (
                <InputSelect 
                    md={this.props.md} 
                    xs={this.props.xs} 
                    sm={this.props.sm} 
                    lg={this.props.lg} 
                    classNameInput={this.props.classNameInput}                        
                    nome={this.props.nome}                                                         
                    id={this.props.nome} 
                    options={this.listas}                            
                    value={this.props.value}                             
                    defaultValue={this.props.defaultValue}
                    erro={this.props.erro}
                    onChange={e => this.onChangeSelect(e)}                    
                >
                    Número do Cartão 
                </InputSelect> 
            )
        }

        return (
            <Input
                md={this.props.md}
                xs={this.props.xs}
                sm={this.props.sm}
                lg={this.props.lg}                                                                        
                id={this.props.id}
                type="tel"
                nome={this.props.nome}
                inputRef={this.props.inputRef}
                value={this.props.value}
                defaultValue={this.props.defaultValue}
                erro={this.props.erro}
                defaultFoco={this.props.defaultFoco}
                maxLength={19}

                onChange={e => this.onChange(e)}  
                onFocus={this.props.onFocus}            
            >
                Número do Cartão
            </Input>
        )
    }

    render() {        
        return (
            <>
                {this.showComponent()}
            </>
        )
    }
}

export default InputCredCard;