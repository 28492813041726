
import Config from "../Config";
import Requisicao from "./RequisicaoService";
import AutenticarService from './AutenticarService';

class ClienteProdutoFavoritoService {
    static async listar(idCarrinho, documentoCliente) {
        return Requisicao.get(Config.urlBaseApi + 'favorito/' + idCarrinho + '/' + documentoCliente);
    }    

    static async like(documentoCliente, uuid) {        
        return Requisicao.post(Config.urlBaseApi + 'favorito', {            
            documentoCliente: documentoCliente,
            uuid: uuid,
            userName: AutenticarService.getUserName()
        });
    }

    static async unlike(documentoCliente, uuid) {
        return Requisicao.delete(Config.urlBaseApi + 'favorito/' + documentoCliente + '/' + uuid);
    }

    static async unlikeAll(documentoCliente) {
        return Requisicao.delete(Config.urlBaseApi + 'favorito/' + documentoCliente);        
    }
}

export default ClienteProdutoFavoritoService;