import TipoCalculoEnum from './enumerador/TipoCalculoEnum';

import { buscaItemVitrine } from './InputSpin';

export function atualizaVitrine(vitrine, carrinho, indiceItem, indiceCarrinhoItem, quantidade) {
    if (indiceItem !== null) {        
        if (indiceCarrinhoItem === -1)
            indiceCarrinhoItem = 0;

        if ((typeof indiceItem) === 'object') //testo se o indiceItem é um objeto, se por, ele sempre será um objeto ProdutoEntity
            indiceItem = buscaItemVitrine(vitrine, indiceItem.uuid);

        if ((indiceItem > -1) && (vitrine[indiceItem].tipo_calculo.toUpperCase() === TipoCalculoEnum.QUANTIDADE.enumName)) {
            let estoque = 0;

            //A vitrine tem uma referencia da informacao do carrinho, então se for undefined, significa que o item não está no carrinho
            if (vitrine[indiceItem].carrinho_item_quantidade !== undefined)
                //Se o item constar no carrinho, significa que estou alterando a quantidade, então eu pego a quantidade anterior a alteração
                estoque = vitrine[indiceItem].carrinho_item_quantidade.quantidade;

            if (quantidade < 0) {//se quantidade é menor que zero, é pq o usuario exclui o item, então nesse caso eu zero a quantidade e deixo a
                //referencia do carrinho no item como undefined
                quantidade = 0;
                vitrine[indiceItem].carrinho_item_quantidade = undefined;
            }
            else //Se quantidade for maior que zero, eu atribuo a referencia do carrinho no item.
                vitrine[indiceItem].carrinho_item_quantidade = carrinho.carrinho_item_quantidade[indiceCarrinhoItem];

            //Alterando o estoque do item na vitrine
            vitrine[indiceItem].estoque = (vitrine[indiceItem].estoque + estoque - quantidade);


            // if(vitrine[indiceItem].carrinho_item_quantidade !== undefined)
            //     vitrine[indiceItem].estoque = vitrine[indiceItem].carrinho_item_quantidade.estoque;

            if (quantidade > 0)
                //Se a quantidade for maior que zero, então eu altero o estoque do item no carrinho.
                //Essa informacao é util no caso de alteração do item, para poder exibir o estoque corretamente.
                carrinho.carrinho_item_quantidade[indiceCarrinhoItem].estoque = vitrine[indiceItem].estoque;            
        }        
    }   

    return vitrine;
}