import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Form from '../../layout/form/Form';
import InputPassword from '../../layout/input/InputPassword';

import UsuarioService from '../../../service/UsuarioService';

import { route } from '../../../helper/Route';

class Password extends AbstractComponent {    
    constructor(props) {
        super(props);

        this.state = {            
            senha: '',

            erro: '',

            btnLogarTxt: 'LOGAR',
        }
    }           

    valida() {
        this.setState({ erro: '' });        

        if (!this.state.senha) {                        
            this.setState({ erro: "Informe o campo senha" });

            return false;
        }

        return true;
    }
    
    onKeyPress(e) {
        if (e.key === "Enter") {
            if(!this.valida())
                return;
        }
    }         

    vinculaRedeSocial(nome, token, documentoCliente) {
        this.props.social.tipo_rede_social.set(
            this.props.form.cpf,
            this.props.social.idRedeSocial,
            this.props.social.foto_social
        ).then(() => {
            this.props.onLoginOk(
                nome, 
                token, 
                documentoCliente,
                this.props.social.tipo_rede_social.enumName                
            );
        }).catch(() => {
            this.setState({ 
                btnLogarTxt: 'LOGAR', 
                erro: 'Erro ao registrar dados de login!'
            });
        });
    }

    logar() {
        this.setState({ btnLogarTxt: 'Aguarde...' });        

        UsuarioService.logarCredentials(            
            this.props.form.email,
            this.state.senha
        ).then(resposta => {
            if(this.props.social.idRedeSocial !== undefined && this.props.social.idRedeSocial !== null && this.props.social.idRedeSocial !== '') {            
                this.vinculaRedeSocial(
                    resposta.data.nome, 
                    resposta.data.token, 
                    resposta.data.documentoCliente                    
                );

                return
            }

            this.props.onLoginOk(
                resposta.data.nome, 
                resposta.data.token, 
                resposta.data.documentoCliente,
                'credentials'                
            );
        }).catch(() => {
            this.setState({ 
                btnLogarTxt: 'LOGAR', 
                erro: 'Usuário ou senha inválido!'
            });
        });
    }

    onSubmit() {        
        if (!this.valida())
            return;

        this.logar();
    } 

    showComponent() {        
        if(!this.props.show)
            return ;

        return (            
            <Form onSubmit={(e) => this.onSubmit(e)} id="form">
                <div className="row"></div>                

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        inputRef={e => this.senha = e}
                        id="senha"
                        nome="senha"
                        value={this.state.senha}
                        defaultValue={this.state.senha}
                        defaultFoco={true}
                        erro={this.state.erro}
                        onChange={e => this.setState({senha: e.target.value})}
                        onKeyPress={e => this.onKeyPress(e)}>
                        SENHA
                    </InputPassword>
                </div>

                <div className="row"></div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <button id="btnLogar"
                            type="submit"
                            className="btn btn-control btn-info btn-lg"
                            disabled={(this.state.btnLogarTxt !== 'LOGAR')}>
                            <span className="text-size">{this.state.btnLogarTxt}</span>
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <button 
                            id="btnRecuperarSenha"
                            type="button"
                            className="btn btn-control btn-link btn-lg"
                            onClick={e => this.props.route(
                                route(
                                    '',
                                    'Recuperar Senha',
                                    'recuperar-senha/?documentoCliente=' + this.props.form.cpf
                                )
                            )}
                        >
                            <span className="text-size">Esqueceu a senha?</span>
                        </button>
                    </div>
                </div>

                <div className="row" style={{marginBottom: '13px'}} />
            </Form>
        );
    }        

    render() {
        return (
            <Fragment>
                {this.showComponent()}
            </Fragment>
        );
    }
}

export default Password;