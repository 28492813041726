import React, { Fragment } from 'react';
import AbstractComponent from '../../../../AbstractComponent';
import Config from '../../../../../Config';

class VitrineGrade extends AbstractComponent {
    getImage() {
        if(
            (this.props.item.galeria_imagem === undefined)||
            (this.props.item.galeria_imagem.length === 0)||
            (this.props.item.galeria_imagem[0].imagem_path === undefined)||
            (this.props.item.galeria_imagem[0].imagem_path === '')
        )
            return (
                <Fragment>
                    <img
                        alt="120x120"
                        className="vitrine-imagem"
                        src={Config.urlImgBase + "produto-sem-imagem.jpg"} />
                </Fragment >

            );

        return (
            <Fragment>
                <img
                    alt="120x120"
                    src={Config.urlImgBase + this.props.item.galeria_imagem[0].imagem_path}
                    data-holder-rendered="true"
                    className="vitrine-imagem" />
            </Fragment>

        );
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div
                        className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-center
                                    container-imagem">
                        { this.getImage() }
                    </div>
                </div>

                <div
                    className="row"
                    style={{marginTop: '20px'}}
                >

                </div>

                <div
                    className="row"
                    style={{
                        height: '46px'
                    }}
                >
                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12">
                        <div className="media-heading-GRADE">
                            {this.props.item.descricao}
                        </div>
                    </div>
                </div>

                <div
                    className="row"
                    style={{marginTop: '20px'}}
                >

                </div>

                <div className="row">
                    {this.props.showValor(this.props.item)}
                </div>
            </Fragment>
        )
    }
}

export default VitrineGrade;