import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';

class Breadcrumb extends AbstractComponent {
    showComponent() {
        if(!this.props.show)
            return ;

        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {
                        this.props.breadcrumb.map((item, i) => {
                            return (
                                <Fragment>
                                    {
                                        (i < (this.props.breadcrumb.length - 1)) &&
                                        <li key={i} className="breadcrumb-item">
                                            <a 
                                                style={{textTransform: "none", color: "usent"}}
                                                href={item.link}                                            
                                            >
                                                {item.descricao}
                                            </a>
                                        </li>
                                    }        

                                    {   
                                        (i === (this.props.breadcrumb.length - 1)) &&
                                        <li key={i} className="breadcrumb-item active" aria-current="page">                                            
                                            {item.descricao}                                            
                                        </li>
                                    }                       
                                </Fragment>
                            )
                        })
                    }
                </ol>
            </nav>
        );
    }

    render() {
        return (
            <Fragment>
                {this.showComponent()}
            </Fragment>  
        )
    }
}

export default Breadcrumb;