import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import MyModal from './MyModal';

class ModalWarning extends AbstractComponent {    
    fechar() {
        if(this.props.fechar !== undefined) {
            this.props.fechar();
        }
    }

    render() {
        return (
            <MyModal styleHeader="bg-warning"
                     styleIcon="fa fa-exclamation-triangle"
                     styleText={this.props.styleText}
                     className="modal_alert "                                          
                     styleButton="btn btn-warning"
                     show={this.props.show}                      
                     header={this.props.header}
                     texto={this.props.texto}                     
                     buttonTxt="Ok" 
                     fechar={e => this.fechar()} />            
        )
    }
}

ModalWarning.defaultProps = {
    header: 'Aviso',
    styleText: 'text-dark'
}

export default ModalWarning;