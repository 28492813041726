import React, { Fragment } from "react";
import AbstractComponent from "../../../AbstractComponent";
import ButtonEscolher from "../../../layout/button/ButtonEscolher";
import OpcaoEntregaModal from "./OpcaoEntregaModal";

import { moeda } from "../../../../helper/Formatar";

class OpcaoEntrega extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpcaoEntrega: false,
    };
  }

  getLineHeight() {
    return parseInt(this.props.carrinho.entrega.id, 10) > 0
      ? "36px"
      : "inherit";
  }

  onUpdateCart(cart) {
    this.setState({ modalOpcaoEntrega: false });

    this.props.onUpdateCart(cart);
  }

  onClick() {
    if (!this.autenticar()) return;

    this.setState({ modalOpcaoEntrega: true });
  }

  getDescricaoOpcaoEntrega() {
    if (this.props.carrinho.entrega.valor_pedido_minimo === 0.0)
      return this.props.carrinho.entrega.descricao;

    if (
      this.props.carrinho.entrega.valor_pedido_minimo <=
      this.props.carrinho.totalizador.sub_total
    )
      return this.props.carrinho.entrega.descricao;

    return (
      this.props.carrinho.entrega.descricao +
      " (Compras acima de " +
      moeda(this.props.carrinho.entrega.valor_pedido_minimo) +
      ")"
    );
  }

  fechar() {
    this.setState({ modalOpcaoEntrega: false });

    if (this.props.fecharChooseOpcaoEntrega !== undefined) {
      this.props.fecharChooseOpcaoEntrega();
    }
  }

  render() {
    return (
      <Fragment>
        <OpcaoEntregaModal
        stylle={{backggroundColor: "red"}}
        cidadesDisponiveis={this.props.cidadesDisponiveis}
        className={"modal-detail"}
        show={this.state.modalOpcaoEntrega || this.props.chooseOpcaoEntrega}
        update={true}
        fechar={() => this.fechar()}
          cliente={this.props.cliente}
          empresa={this.props.empresa}
          carrinho={this.props.carrinho}
          opcaoEntrega={this.props.opcaoEntrega}
          onUpdateCart={(cart) => this.onUpdateCart(cart)}
          onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
            this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)
        }
        />
        
        <ButtonEscolher
          type="button"
          showButton={true}
          texto="Opção de Entrega"
          textoSelecionar={
              this.props.carrinho.entrega.id === 0 ? "Escolher" : "Trocar"
            }
            subtitulo={this.getDescricaoOpcaoEntrega()}
            showSubtitulo={true}
            onClick={() => this.onClick()}
            />
      </Fragment>
    );
  }
}

export default OpcaoEntrega;
