import React, { Fragment } from 'react';
import {Col} from 'react-bootstrap';
import { ArrowsLeftRight, Pencil } from "phosphor-react";
import Config from "../../../Config";

import AbstractComponent from '../../AbstractComponent';

import TipoEnderecoEnum from '../../../helper/enumerador/TipoEnderecoEnum';

import {formataEndereco} from '../../../helper/Formatar';
class InputEndereco extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            isLogged: false
        }

        this.state.isLogged = this.isLogged();
    }

    
    editEndereco() {
        window.location.href = Config.urlBase + "enderecos";
    }


    showPoI() {
        if(this.props.cliente.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
            return ;

        return (
            <Fragment>
                <div 
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                        WebkitLineClamp: "1"
                    }}
                >
                    <label>
                        Ponto de Interesse:
                    </label>
                    
                    &nbsp;

                    { this.props.cliente.ponto_de_interesse + ' - ' + this.props.cliente.ponto_de_interesse_descricao }
                </div>
            </Fragment>
        )
    } 

    showEndereco() {
        if(this.props.cliente.tipo_endereco === TipoEnderecoEnum.POI.name)
            return ;

            const clienteObj = this.props.cliente.cliente_endereco


        return (
            <Fragment>
                <div 
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                        WebkitLineClamp: "1"
                    }}
                >
                    <span style={{fontWeight: 'bold'}}>{
                        clienteObj != undefined ? 
                        clienteObj.map(item => {
                            return item.padrao == 1 || item.padrao == '1' ? 
                            '(' + item.destinatario + ') '
                            : null
                            // formataEndereco(item.endereco.logradouro, item.endereco.endereco) + ', '
                            // : ''
                        })
                        : '' } 
                    </span>
                    {
                        clienteObj != undefined ? 
                        clienteObj.map(item => {
                            return item.padrao == 1 || item.padrao == '1' ? 
                            formataEndereco(item.endereco.logradouro, item.endereco.endereco) + ', '
                            : null
                            // formataEndereco(item.endereco.logradouro, item.endereco.endereco) + ', '
                            // : ''
                        })
                    : '' 
                        // formataEndereco(this.props.cliente.logradouro, this.props.cliente.endereco) + ', '
                    }

                    <span className="delivery-input__address-number">
                        {

                            clienteObj != undefined ? 
                                clienteObj.map(item => {
                                    return item.padrao == 1 || item.padrao == '1' ? 
                                        item.endereco.numero
                                    : ''
                                })
                            : '' 
                            // clienteObj.map(item => (console.log(item.nome)))


                            // this.props.cliente.forEach(element => {
                            //     return console.log(element)
                            // })
                        }
                    </span>
                </div>
                
            </Fragment>
        )
    }  

    render() {
        return(            
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>
                <div className={this.props.inPreload || this.props.cliente.id === 0 ? 'display-none' : ''}>
                    <div>
                        <span className="delivery-input-wrapper__title">
                            Entregar em
                        </span>
                        
                        <a style={{marginLeft: 15 + 'px'}} onClick={() => this.editEndereco()}>
                            <Pencil  color="red" size={25}/>
                        </a>
                    </div>

                    <button className="delivery-input" style={{cursor: 'default'}}>
                        <span className="delivery-input__icon-map">
                            <span className="icon-search icon-search--home">                            
                                <i className="icon-home-font pe-7s-home" />
                            </span>
                        </span>
                        
                        <div className="delivery-input__item">
                            <span className="delivery-input__address">
                                { this.showEndereco() }

                                { this.showPoI() }                                
                            </span>
                            
                            {/* <span className="delivery-input__icon-arrow">
                                <span className="icon-search icon-search--arrow-down">
                                    <i className="icon-search-seta-font pe-7s-angle-down" />
                                </span>
                            </span> */}
                        </div>

                    </button>
                </div>
            </Col>
        )
    }
}

export default InputEndereco;