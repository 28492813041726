import React from "react";
import Config from "../Config";

import AutenticarService from "../service/AutenticarService";
import { route } from "../helper/Route";

class AbstractComponent extends React.Component {
  P_ERRO_PADRAO() {
    return "Ocorreu um erro inesperado, tente novamente mais tarde!";
  }

  onTitulo(titulo) {
    if (this.props.onTitulo !== undefined) this.props.onTitulo(titulo);
  }

  isLogged() {
    return !(
      AutenticarService.getToken() === "" ||
      AutenticarService.getToken() === undefined ||
      AutenticarService.getToken() === null
    );
  }

  autenticar() {
    if (!this.isLogged()) {
      this.props.route(this.login());
      return false;
    }

    return true;
  }

  trataErro(erro) {
    let erroTxt = "";

    if (
      erro !== undefined &&
      erro.response === undefined &&
      typeof String(erro)
    )
      return erro.toString();

    if (
      erro === undefined ||
      erro.response === undefined ||
      erro.response.data === undefined ||
      erro.response.data === ""
    ) {
      erroTxt = this.P_ERRO_PADRAO();

      return erroTxt;
    }

    erroTxt = erro.response.data;

    if (erroTxt === "" || erroTxt === undefined) return this.P_ERRO_PADRAO();

    if (typeof erroTxt === "object") {
      if (
        erroTxt.message !== undefined &&
        erroTxt.message === "Slim Application Error"
      )
        return this.P_ERRO_PADRAO();

      return erroTxt;
    }

    let pos = erroTxt.indexOf('"');

    if (pos === -1) return erroTxt;

    if (pos === 0) erroTxt = erroTxt.substring(1, erroTxt.length);

    pos = erroTxt.indexOf('"');

    if (pos > -1) erroTxt = erroTxt.substring(0, pos);

    return erroTxt;
  }

  login() {
    return route("", "Login", "login");
  }

  login2() {
    window.location.href = Config.urlBase + "login";
  }

  homePage() {
    return route("", "Vitrine", "");
  }

  homePage2() {
    window.location.href = Config.urlBase;
  }

  areaAdminMsg() {
    // return route(
    //     '',
    //     'Área Restrita',
    //     'area-restrita'
    // );
    return this.login();
  }

  addMenu(
    icone,
    descricao,
    nome,
    nomePai,
    link,
    visible = true,
    submenu = undefined
  ) {
    let menu = {};

    menu.icone = icone;
    menu.descricao = descricao;
    menu.nome = nome;
    menu.nomePai = nomePai;
    menu.link = link;
    menu.visible = visible;
    menu.submenu = submenu;

    return menu;
  }

  foco(nome) {
    var el = document.getElementById(nome);

    if (el === undefined || el === null) return;

    setTimeout(() => {
      el.focus();
      el.select();
    }, 1 * 310);
  }

  removeComponent(nome) {
    var el = document.getElementById(nome);

    if (el === undefined || el === null) return;

    setTimeout(() => {
      el.remove();
    }, 1 * 310);
  }
}

export default AbstractComponent;
