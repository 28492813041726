import Config from "../Config";
import Requisicao from "./RequisicaoService";

class CarrinhoService {
    static async add(item) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho/item/add', item);
    }

    static async update(item) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho/item/update', item);
    }

    static async remove(documento, id, idCarrinhoItem) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho/item/remove', {            
            documento: documento,
            id: id,
            idCarrinhoItem: idCarrinhoItem
        });
    }

    static async carrinho(documento, idCarrinho) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho', {            
            documento: documento,
            idCarrinho: idCarrinho
        })
    }

    static async atribuirCliente(idCarrinho, documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho/atribuir-cliente', {            
            idCarrinho: idCarrinho,
            documentoCliente: documentoCliente
        })
    }

    static async gravarOpcaoEntrega(dados) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho/opcao-entrega', dados)
    }    

    static async getIdCarrinhoByDocumento(documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'carrinho/getIdCarrinhoByDocumento', {            
            documentoCliente: documentoCliente
        })
    }
}

export default CarrinhoService;