export const P_ERRO_PADRAO = (setError) => {
    setError('Ocorreu um erro inesperado, tente novamente mais tarde!');
}        

export const trataErro = (erro, setError) => {                        
    let erroTxt = '';                                

    if((erro !== undefined)&&(erro.response === undefined)&&(typeof String(erro))) {
        setError(erro.toString());

        return ;
    }
        
    if((erro === undefined || erro.response === undefined || erro.response.data === undefined || erro.response.data === '')) {                                           
        P_ERRO_PADRAO(setError);

        return ;
    }             

    erroTxt = erro.response.data;        
    
    if((erroTxt === '')||(erroTxt === undefined)) {
        P_ERRO_PADRAO(setError);

        return ;
    }

    if((typeof erroTxt) === 'object') {
        if(erroTxt.message !== undefined && erroTxt.message === 'Slim Application Error') {
            P_ERRO_PADRAO(setError);

            return ;
        }
            
        setError(erroTxt);

        return ;        
    }

    let pos = erroTxt.indexOf('"');                                

    if(pos === -1) {
        setError(erroTxt);

        return ;
    }
                    
    if(pos === 0) {
        erroTxt = erroTxt.substring(1, erroTxt.length);        
    }
        
    pos = erroTxt.indexOf('"');                    
    
    if(pos > -1) {
        erroTxt = erroTxt.substring(0, pos);                
    }
     
    setError(erroTxt);

    return ;
}