import React, { Fragment } from "react";
import AbstractComponent from "../../AbstractComponent";
import ModalAlerta from "../modal/ModalAlerta";
import ModalSucesso from "../modal/ModalSucesso";
import PreloadContent from "../preload/PreloadContent";
import { isMobile } from "react-device-detect";

class ContentMini extends AbstractComponent {
  body() {
    return this.props.component({
      body: this.props.body,
    });
  }

  render() {
    return (
      <Fragment>
        <ModalAlerta
          show={this.props.modalErro !== ""}
          texto={this.props.modalErro}
          fechar={() => this.props.onCloseAlerta()}
        />

        <ModalSucesso
          show={this.props.modalSucesso !== ""}
          header="Sucesso"
          texto={this.props.modalSucesso}
          fechar={(e) => this.props.onCloseSucesso()}
        />
        <div className={isMobile ? "login-mobile-superwide" : "mini-content"}>
          <div className={"mini-content-form " + this.props.classNameContent}>
            <PreloadContent tipo="mini-content" exibir={this.props.preload} />

            <div
              className={
                (this.props.preload === true ? "display-none" : "") +
                " " +
                this.props.className
              }
            >
              {this.body()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ContentMini.defaultProps = {
  modalErro: "",
  modalSucesso: "",
  preload: false,
  className: "",
  classNameContent: "",
};

export default ContentMini;
