import React from 'react';
import AbstractMenu from '../AbstractMenu';

class MobileMenu extends AbstractMenu {
    render() {
        return(
            <a
                className={"tab-bar-item " + (this.props.active === this.props.index ? 'active' : '')}
                onClick={() => this.props.onClick()}
                key={this.props.index}
            >
                <div className="row">
                    <div
                        className="col-md-12
                                col-sm-12
                                col-lg-12
                                col-xs-12
                                text-center"
                        style={{height: "32px"}}>
                        <span>
                            <i
                                className={"icon-marmita " + this.props.icon}
                                style={{height: "100%"}}
                            >
                                <span className={"icon-badge " + (this.props.badge === 0 ? 'display-none' : '') }>                                    
                                    { ( this.props.badge <= 9 ? this.props.badge : '9+') }
                                </span>
                            </i>
                        </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12
                                col-sm-12
                                col-lg-12
                                col-xs-12
                                tab-bar-item__title">
                        {this.props.title}
                    </div>
                </div>
            </a>
        )
    }
}

MobileMenu.defaultProps = {
    badge: 0,
    title: '',
    active: -1,
    index: 0
}

export default MobileMenu;