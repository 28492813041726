import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';

import './InputAutoComplete.css';

class InputAutoComplete extends AbstractComponent {     
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            focus: false
        }
    }   

    onChange(e) {        
        if(this.props.onChange !== undefined)
            this.props.onChange(e);

        let search = this.search;

        search = e.target.value;

        this.setState({search: search});

        this.props.autoComplete(e);        
    }

    onKeyDown(e) {
        if(e.keyCode !== 13)
            return ;
                            
        this.setState({focus: false});        
        this.props.search();
    }

    onClick() {                
        this.setState({ search: '' });
        this.props.onClearItems();             
        this.props.search();         
    }
    
    onItemClick(item) { 
        this.props.onItemClick(item.value);
        this.setState({search: item.value, focus: false});         
        this.props.search();         
    }

    onFocus(value) {
        this.setState({
            focus: value
        });
    }     

    show() {
        if((!this.state.focus)||(this.state.search === '')||(this.props.items.length === 0))
            return ;

        return (            
            <div className="autocomplete-items">
                <ul>
                    {this.props.items.map((item, i) => {
                        return (
                            <li key={i} onClick={() => this.onItemClick(item)}>
                                {item.value}
                            </li>
                        )
                    })}                        
                </ul>
            </div>
        )
    }

    render() {
        return(       
            <Fragment>
                <span className="icon-search icon-search--search search-input__icon-search">
                    <i className="icon-search-font pe-7s-search" />
                </span>    

                <input 
                    autoComplete="off"                           
                    type="text"
                    name={this.props.name}
                    id={this.props.id}
                    value={this.state.search}
                    //defaultValue={this.state.search}
                    className="search-input__field"
                    placeholder={this.props.placeholder}                    

                    onChange={e => this.onChange(e)} 
                    onFocus={() => this.onFocus(true)}
                    //onBlur={() => this.onFocus(false)}   
                    onKeyDown={(e) => this.onKeyDown(e)}                           
                />                    
                
                <button 
                    type="button"                             
                    className="btn-icon btn-icon--gray btn-icon--size-m btn-icon--transparent search-input__erase-button"
                    aria-hidden="false" 
                    aria-label="Limpar Campo"
                    style={this.props.style}
                    hidden={this.state.search === ''}
                    onClick={() => this.onClick()}
                >
                    <span 
                        className="icon-search icon-search-close icon-search--error"
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        <i className="pe-7s-close"/>
                    </span>
                </button> 

                { this.show() }                       
            </Fragment>                    
        )
    }
}

InputAutoComplete.defaultProps = {    
    placeholder: '',
    items: [],
    className: '',
    id: ''
}

export default InputAutoComplete;