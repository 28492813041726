import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';    
import Config from '../../../Config';
class Preload extends AbstractComponent {    
    render() {
        return (
            <Modal                
                backdrop={'static'}
                show={this.props.exibir}
                bsSize="small"
                backdropClassName="modal-preload"
                style={{zIndex: '9999'}}>

                <Modal.Body style={{color: '#4A6A95', textAlign: 'center'}}>
                    <img src={Config.urlImgBase + "preload.gif"} width="16px" height="16px" alt="Loading..."/> <br/>
                    Carregando
                </Modal.Body>

            </Modal>
        )
    }
}

export default Preload;