import React from "react";
import AbstractComponent from "../../AbstractComponent";
import Config from "../../../Config";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import "./Cupons.css";
import RequisicaoService from "../../../service/RequisicaoService";
import Resgate from "./Resgate";
import InputMask from "react-input-mask";

class CupomUnitario extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      id_cab: 0,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  itensResgate(idCab) {
    let id = idCab;
    this.setState({ id_cab: id });
  }

  render() {
    return (
      <>
        {this.props.cupom.map((item, i) => (
          <tr key={i} className="cupomUnitario">
            <td>
              <div
                className="col-md-12
                            col-sm-12 
                            col-lg-12 
                            col-xs-12"
              >
                <hr className="linhatracejada" />
                <div
                  className="col-md-3
                            col-sm-6 
                            col-lg-3 
                            col-xs-6"
                >
                  <p className="h4 text-body">
                    {" "}
                    <b>Data: </b> {item._d_a_t_a}{" "}
                  </p>

                  <p className="h4 text-body">
                    <b>{item.ecf}</b>
                  </p>
                </div>

                <div
                  className="col-md-3 
                            col-sm-6 
                            col-lg-3 
                            col-xs-6"
                >
                  <p className="h4 text-body">
                    {" "}
                    <b>Validade: </b>
                    {item.validade}{" "}
                  </p>
                  <p className="h4 text-body">
                    {" "}
                    <b>Valor do cupom: </b>
                    {parseFloat(item.valor_original_vale).toLocaleString(
                      "pt-br",
                      { style: "currency", currency: "BRL" }
                    )}
                    {""}
                  </p>
                </div>
                <div
                  className="col-md-6
                            col-sm-12 
                            col-lg-6
                            col-xs-12"
                  style={{
                    padding: "2px",
                    height: "100%",
                  }}
                >
                  {parseFloat(item.saldo) == item.valor_original_vale ? (
                    <div
                      className="d-block justify-content-evenly"
                      onClick={() => this.itensResgate(item.id_cab)}
                    >
                      <a className="linkBotao">
                        <div>
                          <div
                            className="cupom-valido"
                            onClick={this.toggle}
                            style={{ height: "100%", width: "100%" }}
                          >
                            <div >

                            <div className="blockcupom">
                              <p id="chave" className="h1 text-light">
                                <InputMask
                                  className="valorcupom"
                                  mask="***  ***  ***"
                                  value={item.chave}
                                  />
                                {""}
                              </p>
                            </div>
                                  </div>
                            <div className="blockvalor">
                              <p className="psaldo h2 text-light">
                                {parseFloat(item.saldo).toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : (
                    <div
                      className="d-block justify-content-evenly"
                      onClick={() => this.itensResgate(item.id_cab)}
                    >
                      <a className="linkBotao">
                        <div>
                          <div
                            className={
                              parseFloat(item.saldo) == 0
                                ? "cupom-invalido"
                                : "cupomsemi"
                            }
                            onClick={this.toggle}
                            style={{ height: "100%", width: "100%" }}
                          >
                            <div className="block">
                              <p
                                className={
                                  parseFloat(item.saldo) == 0
                                    ? "h1 text-light"
                                    : "h1 text"
                                }
                                id="chave"
                              >
                                <InputMask
                                  className="valorcupom"
                                  mask="***  ***  ***"
                                  value={item.chave}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>

                      <Modal
                        isOpen={this.state.modal}
                        fade={false}
                        toggle={this.toggle}
                        className={this.props.className}
                      >
                        <ModalBody>
                          <Resgate
                            resgate={this.props.resgate}
                            idCab={this.state.id_cab}
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={this.toggle}>
                            Fechar
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </td>
            <hr className="linhatracejada" />
          </tr>
        ))}
      </>
    );
  }
}
export default CupomUnitario;
