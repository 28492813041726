import React, { Fragment } from "react";
import AbstractComponent from "../../AbstractComponent";
import VitrineProdutoContent from "./VitrineProdutoContent";
import VitrineModalDetail from "./VitrineModalDetail";
import ModalAlerta from "../../layout/modal/ModalAlerta";

import AutenticarService from "../../../service/AutenticarService";

import ShoppingBagEnum from "../../../helper/enumerador/ShoppingBagEnum";
import AtivoEnum from "../../../helper/enumerador/AtivoEnum";

import { buscaItemCarrinho } from "../../../helper/InputSpin";
import VitrineCarrouleul from "./VitrineCarousel";
import VitrineCarousel from "./VitrineCarousel";

class VitrineContent extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      item: [],

      indice: -1,

      alerta: "",
    };

    this.shoppingBagType = ShoppingBagEnum.get(
      AutenticarService.getShoppingBag()
    );
  }

  onUpdateCart(carrinho, indiceCarrinhoItem, quantidade) {
    const indice = this.state.indice;

    if (
      this.state.item.carrinho_item_quantidade !== undefined &&
      this.props.carrinho.carrinho_item_quantidade !== undefined
    )
      indiceCarrinhoItem = buscaItemCarrinho(
        this.props.carrinho.carrinho_item_quantidade,
        this.state.item.carrinho_item_quantidade.id
      );

    this.setState({ item: [] });

    this.props.onUpdateCart(carrinho, indice, indiceCarrinhoItem, quantidade);
  }

  onUpdateBadgeFavorito(tipo, index) {
    this.props.onUpdateBadgeFavorito(tipo, index);

    if (this.props.telaFavorito) {
      this.setState({ item: [] });
    }
  }

  onClick(item, indice) {
    if (item.estoque <= 0 && item.controla_estoque === AtivoEnum.SIM) {
      this.setState({
        alerta:
          "Produto com estoque INDISPONÍVEL, não é possível adicioná-lo " +
          this.shoppingBagType.add().toUpperCase(),
      });

      return;
    }

    this.setState({
      item: item,
      indice: indice,
    });
  }

  getQuantidadeCarrinho() {
    if (this.state.item.carrinho_item_quantidade === undefined) return 1;

    if (this.state.item.carrinho_item_quantidade.length === 0) return 1;

    return this.state.item.carrinho_item_quantidade.quantidade;
  }

  update() {
    if (this.state.item.carrinho_item_quantidade === undefined) return false;

    if (this.state.item.carrinho_item_quantidade === null) return false;

    if (this.state.item.carrinho_item_quantidade.length === 0) return false;

    return true;
  }

  showVitrine() {
    if (this.state.item.length === 0) return;

    return (
      <VitrineModalDetail
        className={"vitrine-detail"}
        header="Detalhes do Pedido"
        show={true}
        update={this.update()}
        fechar={() => this.setState({ item: [] })}
        item={this.state.item}
        indice={this.state.indice}
        estoque={this.state.item.estoque}
        carrinho={this.props.carrinho}
        idCarrinhoItem={
          !this.update() ? 0 : this.state.item.carrinho_item_quantidade.id
        }
        quantidade={this.getQuantidadeCarrinho()}
        onUpdateCart={(carrinho, indiceCarrinhoItem, quantidade) =>
          this.onUpdateCart(carrinho, indiceCarrinhoItem, quantidade)
        }
        onUpdateVitrine={(index) => this.props.onUpdateVitrine(index)}
        onUpdateBadgeFavorito={(tipo, index) =>
          this.onUpdateBadgeFavorito(tipo, index)
        }
        route={(item) => this.props.route(item)}
      />
    );
  }

  acessarProdsCampanha(imagemRef) {

    this.props.onPressAcessarProdsCampanha(imagemRef);
  }

  render() {
    return (
      <Fragment>
        <VitrineCarousel props={this.props}
                  onPressAcessarProdsCampanha = {(imagemRef) => {
                    this.acessarProdsCampanha(imagemRef);
                  }}
        />
        <ModalAlerta
          show={this.state.alerta !== ""}
          texto={this.state.alerta}
          fechar={() => this.setState({ alerta: "" })}
        />

        <VitrineProdutoContent
          inPreload={this.props.inPreload}
          vitrinePreload={this.props.vitrinePreload}
          telaFavorito={this.props.telaFavorito}
          vitrine={this.props.vitrine}
          searchKey={this.props.searchKey}
          indice={this.state.indice}
          fetchMoreData={() => this.props.fetchMoreData()}
          onClick={(item, indice) => this.onClick(item, indice)}
          onClickAviseMe={(item, indice) => this.onClickAviseMe(item, indice)}
          changeQuantidade={(item, quantidade) =>
            this.props.changeQuantidade(item, quantidade)
          }
          onUpdateCart={(carrinho, indiceCarrinhoItem, quantidade) =>
            this.onUpdateCart(carrinho, indiceCarrinhoItem, quantidade)
          }
          onUpdateVitrine={(index) => this.props.onUpdateVitrine(index)}
          onUpdateBadgeFavorito={(tipo, index) =>
            this.onUpdateBadgeFavorito(tipo, index)
          }
          route={(item) => this.props.route(item)}
        />

        {this.showVitrine()}
      </Fragment>
    );
  }
}

export default VitrineContent;
