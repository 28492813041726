import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import InputLabelMoeda from '../input/InputLabelMoeda';

import {calculaDesconto, imprimirPorcentagem} from '../../../helper/Matematica';

class InputLabelMoedaDetail extends AbstractComponent {               
    render() {
        return (
            <div className="product-content__container product-content__container-price">                                        
                <span className={this.props.por === 0 ? "display-none" : ""}>
                    <div className="row">                        
                        <InputLabelMoeda

                            style={{textDecoration: 'line-through'}}
                            symbol={this.props.symbol}
                            value={this.props.de}>
                            De:
                        </InputLabelMoeda>

                        &nbsp; 
                        &nbsp;
                        &nbsp;
                        <span className="text-porcentagem" style={{lineHeight: '29px'}}>
                            {imprimirPorcentagem(calculaDesconto(this.props.de, this.props.por))}
                        </span>
                    </div>                                                        
                </span> 

                <span>
                    <InputLabelMoeda
                        symbol={this.props.symbol}
                        value={this.props.por > 0 ? this.props.por : this.props.de}>
                        {this.props.por > 0 ? "Por" : ""}
                    </InputLabelMoeda>
                </span>                       
            </div>
        )
    }
}

InputLabelMoedaDetail.defaultProps = {
    de: 0,
    por: 0,
    symbol: 'R$'
}

export default InputLabelMoedaDetail;