import Config from "../Config";
import Requisicao from "./RequisicaoService";

class OpcaoEntregaService {
  static async listar(tipoEndereco, codigoMunicipio, pontoDeInteresse) {
    return Requisicao.post(Config.urlBaseApi + "opcao-entrega", {
      tipoEndereco: tipoEndereco,
      codigoMunicipio: codigoMunicipio,
      pontoDeInteresse: pontoDeInteresse,
    });
  }

  static async freteCodigoMunicipio() {
    return Requisicao.get(Config.urlBaseApi  + 'frete-disponivel');
  }

}

export default OpcaoEntregaService;
