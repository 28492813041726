const OpcaoEntregaTipoEnum = {
  RETIRAR: {
    enumName: "RETIRAR",
    name: function () {
      return "Retirar";
    },
    pagarNa: function () {
      return "Retirada";
    },
  },
  ENTREGAR: {
    enumName: "ENTREGAR",
    name: function () {
      return "Entregar";
    },
    pagarNa: function () {
      return "Entrega";
    },
  },
  get(value) {
    if (value === null || value === undefined || value === "undefined" || value === "") {
      return OpcaoEntregaTipoEnum.RETIRAR;
    }

    var array = Object.values(OpcaoEntregaTipoEnum);
    var found = {};

    array.forEach((element) => {
      if (element.enumName === value.toUpperCase()) {
        found = element;
        return;
      }
    });

    return found;
  },
};

export default OpcaoEntregaTipoEnum;
