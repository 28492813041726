import React, { Fragment } from 'react';
import VitrineGrade from '../../component/shopping/vitrine/e-commerce/grade/VitrineGrade';
import VitrineLista from '../../component/shopping/vitrine/e-commerce/lista/VitrineLista';
import Oferta from '../../component/shopping/vitrine/oferta/Oferta';
import Favorito from '../../component/shopping/vitrine/e-commerce/favorito/Favorito';

import AtivoEnum from '../enumerador/AtivoEnum';

import {moeda} from '../Formatar';
import {calculaDesconto} from '../Matematica';

function showValor(item) {    
    if( item.controla_estoque === AtivoEnum.SIM && parseFloat(item.estoque) <= 0.000 && item.ativo === AtivoEnum.SIM )
        return (
            <Fragment>
                <div
                    className="col-md-12
                            col-sm-12
                            col-lg-12
                            col-xs-12
                            text-center"
                >
                    <h4>** PRODUTO INDISPONÍVEL **</h4>
                </div>
         </Fragment>
        )

    return (
        <div
            className="col-md-12
                       col-sm-12
                       col-lg-12
                       col-xs-12"
            style={{height: '76px'}}
        >
            <h1 className="text-preco-desconto">
                {
                    item.promocao === 0.00 &&
                    <div style={{height: '17px'}}>

                    </div>
                }

                {
                    item.promocao > 0.00 &&
                    <Fragment>
                        <span style={{textTransform: 'initial'}}>
                            De:
                        </span>

                        &nbsp;

                        { moeda(item.venda) }
                    </Fragment>
                }
            </h1>

            <h1 className="text-preco">
                {
                    item.promocao > 0.00 &&
                    <Fragment>
                        <span style={{textTransform: 'initial'}}>
                            Por:
                        </span>

                        &nbsp;
                    </Fragment>
                }

                { moeda(item.promocao > 0.00 ? item.promocao : item.venda) }

                &nbsp;&nbsp;&nbsp;
                <span className={"text-porcentagem " + (Math.round(item.promocao, 2) === 0.00 ? 'display-none' : '')}>
                    {item.venda && item.promocao ? imprimirPorcentagem(calculaDesconto(item.venda, item.promocao)) : ""}
                </span>
            </h1>
        </div>
    );
}

function imprimirPorcentagem(value) {
    if(value === 0.00)
        return '';

    const desconto = value.toString();

    return (
        <span>
            {Math.round(desconto) + '% OFF'}
        </span>
    );
}

var vitrineEnum = {
    LISTA: {
        enumName: 'LISTA',
        content: function(item) {
            return <VitrineLista
                item={item}

                showValor={(item) => showValor(item)}
            />
        },
        css: function() {
            return "col-md-5 col-sm-5 col-lg-3 col-xs-12";
        },
        classContentExtra: function() {
            return "";
        }
    },
    GRADE: {
        enumName: 'GRADE',
        content: function(item) {
            return <VitrineGrade
                item={item}

                showValor={(item) => showValor(item)}
            />
        },
        css: function() {
            return "col-md-5 col-sm-5 col-lg-3 col-xs-12";
        },
        classContentExtra: function() {
            return "pd-0";
        }
    },
    OFERTA: {
        enumName: 'OFERTA',
        content: function(item) {
            return <Oferta
                item={item}

                calculaDesconto={(item) => calculaDesconto(
                    (item.promocao === 0 ? item.venda : item.promocao),
                    item.valorFolheto
                )}
            />
        },
        css: function() {
            return "col-md-5 col-sm-5 col-lg-3 col-xs-12";
        },
        classContentExtra: function() {
            return "";
        }
    },

    FAVORITO: {
        enumName: 'FAVORITO',
        content: function(item) {
            return <Favorito
                item={item}

                showValor={(item) => showValor(item)}
            />
        },
        css: function() {
            return "col-md-5 col-sm-5 col-lg-3 col-xs-12";
        },
        classContentExtra: function() {
            return "pd-0";
        }
    },
}

export default vitrineEnum;