import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../../../AbstractComponent';
import ModalTitle from '../../../layout/modal/ModalTitle';
import InputMoeda from '../../../layout/input/InputMoeda';
import Button from '../../../layout/button/Button';
import Alerta from '../../../layout/alert/Alerta';

import { moeda, strTofloat } from '../../../../helper/Formatar';

import '../../../layout/modal/Modal.css';

class TrocoModal extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {   
            showAlerta: false,
            
            textoAlerta: '',
            
            troco: 0.00            
        }
    }          

    valida() {                         
        var troco = strTofloat(this.state.troco, 2);        
        var total = strTofloat(this.props.totalPedido, 2);        

        if(troco > total) {
            this.setState({troco: 0});
            this.props.onTroco(troco);
            return ;       
        }     

        this.setState({
            showAlerta: true,
            textoAlerta: 'Você deve pedir troco para um valor maior que ' + moeda(this.props.totalPedido)
        });                
    }

    onChangeEvent(e, maskedValue, floatValue) {
        let troco = this.state.troco;

        troco = maskedValue;

        this.setState({troco: troco});
    }  

    render() { 
        return (            
            <Modal show={this.props.show} 
                   className={this.props.className}
                   style={{zIndex: 9999}}
                   onHide={() => this.props.fechar()}>  
                <ModalTitle
                    icon="down"
                    escondeLupa={false}
                    header={this.props.header}
                    fechar={() => this.props.fechar()}                                                                                                                    
                />                              
                
                <Modal.Body>  
                    <Alerta
                        exibir={this.state.showAlerta}
                        timeOut={3}
                        texto={this.state.textoAlerta}
                        onTimeOut={() => this.setState({showAlerta: false, textoAlerta: ''})}
                    />

                    <div className="row" style={{margin: '20px 0px'}}>                        
                        <div className="col-md-12 
                                        col-sm-12
                                        col-lg-12
                                        col-xs-12
                                        text-center">
                            Seu pedido deu {moeda(this.props.totalPedido)}.
                        </div>                        
                        
                        <div className="col-md-12 
                                        col-sm-12
                                        col-lg-12
                                        col-xs-12
                                        text-center">
                            Digite quanto vai pagar em dinheiro para que o entregador leve o seu troco.
                        </div>                          
                    </div>
                    
                    <InputMoeda                                                
                        symbol="R$"
                        id="troco"
                        name="troco"
                        value={this.state.troco}                        
                        onChangeEvent={(e, maskedValue, floatValue) => this.onChangeEvent(e, maskedValue, floatValue)}
                    />                                        

                    <Button
                        type="button"                     
                        width={'100%'}
                        background={1}
                        color={true}
                        justifyContent='center' 
                        onClick={() => this.valida()}                                               
                    >
                        Confirmar
                    </Button>                    
                </Modal.Body>
            </Modal>
        )
    }
}

TrocoModal.defaultProps = {
    header: '',
    update: false,
    item: [],
    id: 0
}

export default TrocoModal;