import React, {Fragment} from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import InputSearch from '../input/InputSearch';

class ModalTitle extends AbstractComponent { 
    constructor(props) {        
        super(props);

        this.state = {
            escondeLupa: false,            
        }
    }    

    onChange(e) {
        if(this.props.onChange !== undefined)
            this.props.onChange(e);
    }

    onClearItems() {
        var input = document.getElementById('inputSearch');        

        if ((input !== null)&&(input !== undefined))
            input.value = '';

        if(this.props.onClearItems !== undefined)
            this.props.onClearItems();
    }                     

    children() {
        if(!this.props.children)
            return ;

        return (            
            <div 
                className="col-md-12
                           col-sm-12
                           col-lg-12
                           col-xs-12" 
                style={{marginTop: '10px'}}
            >                                                                                                                                    
                {this.props.children}
            </div>            
        );
    }

    render() {
        return (            
            <Modal.Header 
                className={"__title " + (this.state.escondeLupa ? 'full-content' : '') + ' ' + this.props.className}
                style={{                    
                    height: (this.props.children ? 'auto' : '54px'),
                    zIndex: 1048
                }}
            >             
                <div className="header-modal-title __title">
                    <div className="row" style={{height: '100%'}}>                                                  
                        {
                            !this.state.search &&                    
                            <Fragment>                                                                                              
                                <div className="col-md-10 col-xs-10 col-sm-10 col-lg-10 text-center">
                                    <div 
                                        className="col-md-2 col-xs-2 col-sm-2 col-lg-2 text-center"
                                        style={{
                                            display: 'flex',
                                            height: '100%',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            this.props.fechar !== undefined &&
                                            <Fragment>                                                                        
                                                <div className={(this.props.icon === 'down' ? '' : 'display-none')} onClick={() => this.props.fechar()} >
                                                    <i 
                                                        className={"fa fa-arrow-down"}
                                                        style={{marginTop: '4px'}}
                                                        onClick={() => this.props.fechar()} 
                                                    />
                                                </div>  

                                                <div className={(this.props.icon === 'left' ? '' : 'display-none')} onClick={() => this.props.fechar()} >
                                                    <i 
                                                        className={"fa fa-arrow-left"}
                                                        style={{marginTop: '6px'}}
                                                        onClick={() => this.props.fechar()} 
                                                    />
                                                </div>
                                            </Fragment>
                                        }                                                                  
                                    </div>

                                    <div className="col-md-10 col-xs-10 col-sm-10 col-lg-10 text-center">                                                        
                                        <h1>
                                            <i className={this.props.classNameIcon} />

                                            {this.props.header}

                                            {
                                                this.props.subheader &&
                                                <>
                                                    <br />

                                                    { this.props.subheader }
                                                </>
                                            }
                                        </h1>
                                    </div>                            
                                </div>
                                
                                {
                                    this.props.exibeLupa &&
                                    <div 
                                        className="col-md-2 col-xs-2 col-sm-2 col-lg-2 text-right"
                                        style={{
                                            padding: '15px 0px 0px 0px',
                                            right: '30px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => this.setState({search: true})}
                                    > 
                                        <span 
                                            className="icon-search icon-search--search search-input__icon-search"
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.setState({search: true})}
                                        >
                                            <i 
                                                className="icon-search-font pe-7s-search icon-search-mobile" 
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => this.setState({search: true})}
                                            />
                                        </span> 
                                    </div>   
                                }
                            </Fragment>
                        }

                        {
                            this.state.search &&                        
                            <InputSearch
                                md={12} 
                                xs={12} 
                                sm={12} 
                                lg={12} 
                                className={"responsive-header__search " + (this.state.search ? '' : 'display-none')}
                                style={{
                                    height: '105%',
                                    borderRadius: '0'
                                }}
                                id="inputSearch"  
                                name="inputSearch"  
                                arrowLeft={true}                                
                                search={() => this.props.search()}
                                items={this.props.items} 
                                placeholder={this.props.placeholder}
                                
                                autoComplete={(e) => this.props.autoComplete(e)}
                                onItemClick={(value) => this.props.onItemClick(value)}
                                onClearItems={() => this.onClearItems()}
                                onCloseButtonClick={() => this.setState({search: false})}
                                onChange={(e) => this.onChange(e)}                        
                            />                                                                                                    
                        }

                    </div> 
                    
                    {this.children()}                           
                </div> 
            </Modal.Header>            
        )
    }
}

ModalTitle.defaultProps = {
    icon: 'down',
    exibeLupa: false    
}

export default ModalTitle;