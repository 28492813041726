import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';
import Content from '../layout/content/Content';
import ModalTitle from '../layout/modal/ModalTitle';
import CategoriaDesktopContent from './CategoriaDesktopContent';

class CategoriaDesktop extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {            
            preload: false,
            
            erro: ''
        }        
    }

    fechar() {
        if(this.props.fechar !== undefined) {
            this.props.fechar();
        }
    }        

    onCallback(item) {
        if(this.props.onCallback !== undefined)
            this.props.onCallback(item);
    }

    render() {
        return (
            <Modal id="categoria"
                   show={this.props.show}
                   className={this.props.className}
                   style={{ zIndex: 9999, overflow: "auto" }}
                   onHide={() => this.props.fechar()}>                
                <ModalTitle
                    icon="left"
                    escondeLupa={false}
                    header={this.props.header}
                    fechar={() => this.props.fechar()}
                    placeholder={"Qual " + this.props.header.toLowerCase() + " está procurando?"}

                    items={this.state.items} 
                    search={() => this.loadCategoria(false, true)}
                          
                    autoComplete={(e) => this.autoComplete(e)}
                    onItemClick={() => this.loadCategoria(false, true)}                    
                >

                </ModalTitle>

                <Modal.Body>
                    <Content
                        Style={{ width: "34%" }}
                        preload={this.state.preload}
                        showSummary={false}
                        modalErro={this.state.erro}
                        onCloseAlerta={() => this.setState({ erro: '' })}

                        component={(props) => {
                            return <CategoriaDesktopContent
                                {...props}                                

                                filtros={this.props.filtros}

                                onCallback={(item) => this.onCallback(item)}
                                onApply={(items) => this.props.onApply(items)}
                                onPreload={(value) => this.setState({preload: value})}
                                onErro={(erro) => this.setState({erro: erro})}
                            />                                                                            
                        }}
                    />                    
                </Modal.Body>
            </Modal>            
        )
    }
}

CategoriaDesktop.defaultProps = {
    header: 'Alerta'    
}

export default CategoriaDesktop;