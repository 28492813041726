import React from 'react';
import AbstractComponent from '../AbstractComponent';
import FotoPerfil from './FotoPerfil';
import LoginByRedeSocial from './login-rede-social/LoginByRedeSocial';
import LoginByCredentials from './login-credentials/LoginByCredentials';

class LoginForm extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            passo: 1,

            readOnly: false,

            social: {
                idRedeSocial: '',                 
                email: '', 
                nome: '',
                foto_social: '', 
                cpf: '',
                tipo_rede_social: undefined
            }            
        }
    }    

    render() {
        return (            
            <div className="form">                   
                <FotoPerfil
                    image={this.state.social.foto_social}
                />
                
                <LoginByRedeSocial                     
                    show={this.state.passo === 1}
                    readOnly={this.state.readOnly}
                    empresa={this.props.empresa}
                    
                    logar={(email, senha, documentoCliente, idRedeSocial, tipo_rede_social) => this.props.logar(email, senha, documentoCliente, idRedeSocial, tipo_rede_social)}
                    onNextStep={(social) => this.setState({passo: this.state.passo + 1, social: social})}
                    onReadOnly={() => this.setState({readOnly: true})}
                    onFailure={() => this.setState({readOnly: false})}
                />                

                <LoginByCredentials
                    passo={this.state.passo}
                    social={this.state.social}                    
                    readOnly={this.state.readOnly}

                    nextStep={() => this.setState({passo: this.state.passo + 1})}
                    onLoginOk={(userName, token, documentoCliente, tipoLogin) => this.props.onLoginOk(userName, token, documentoCliente, tipoLogin)}
                    onRegister={(form) => this.props.onRegister(form)} 
                    route={(item) => this.props.route(item)}                   
                />                
            </div>            
        )
    }
}

export default LoginForm;