import React from 'react';
import AbstractComponent from '../../AbstractComponent';

class Footer extends AbstractComponent {        
    render() {
        return(
            <footer className="desktop-container footer">
            {/* //     <section className="footer__section">
            //         <h2 className="footer__title">
            //             Tipos de Comida
            //         </h2>
                    
            //         <ul className="footer__links-list">
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/mercado">
            //                     Mercado em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/pizzaria">
            //                     Pizza em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/crepe">
            //                     Crepe em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/tapioca">
            //                     Tapioca em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/pastel">
            //                     Pastel em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/panqueca">
            //                     Panqueca em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/cafeteria">
            //                     Cafeteria em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/padaria">
            //                     Padaria em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/chinesa">
            //                     Comida chinesa em Sao Paulo
            //                 </a>
            //             </li>
                        
            //             <li className="footer__list-item">
            //                 <a className="footer__text-link" href="/delivery/sao-paulo-sp/cozinha/yakisoba">
            //                     Yakisoba em Sao Paulo
            //                 </a>
            //             </li>
            //         </ul>                                                                                                                                                        
            //     </section>

            //     <section className="footer__section footer__section--about">
            //         <div className="footer__column">
            //             <h4 className="footer__subtitle">
            //                 iFood
            //             </h4>
                            
            //             <ul className="footer__links-list">
            //                 <li className="footer__list-item">
            //                     <a target="_blank" className="footer__text-link" title="Fale conosco" rel="noopener" href="/ajuda">
            //                         Fale conosco
            //                     </a>
            //                 </li>
                            
            //                 <li className="footer__list-item">
            //                     <a target="_blank" className="footer__text-link" title="Carreiras" rel="noopener" href="/carreiras">
            //                         Carreiras
            //                     </a>
            //                 </li>
                            
            //                 <li className="footer__list-item">
            //                     <a target="_blank" className="footer__text-link" title="iFood Colômbia" rel="noopener" href="https://www.ifood.com.co/?utm_source=Site_iFood&amp;utm_medium=Site_iFood&amp;utm_campaign=iFood-Rodape">
            //                         iFood Colômbia
            //                     </a>
            //                 </li>                                                                                                                        
            //             </ul>                                
            //         </div>
                    
            //         <div className="footer__column">
            //             <h4 className="footer__subtitle">
            //                 Descubra
            //             </h4>
                        
            //             <ul className="footer__links-list">
            //                 <li className="footer__list-item">
            //                     <a target="_blank" className="footer__text-link" title="Como pedir" rel="noopener" href="/como-pedir">
            //                         Como pedir
            //                     </a>
            //                 </li>
                            
            //                 <li className="footer__list-item">
            //                     <a target="_blank" className="footer__text-link" title="Cadastre seu restaurante" rel="noopener" href="https://restaurante.ifood.com.br/">
            //                         Cadastre seu restaurante
            //                     </a>
            //                 </li>                                                                            
            //             </ul>                                    
            //         </div>
                    
            //         <div className="footer__column footer__column--social">
            //             <h4 className="footer__subtitle">
            //                 Social
            //             </h4>
                        
            //             <ul className="footer__social">
            //                 <li>
            //                     <a className="btn-icon btn-icon--primary btn-icon--size-m btn-icon--transparent footer__social-icon" role="button" aria-label="Facebook" aria-hidden="false" target="_blank" rel="noopener" href="https://www.facebook.com/iFood/">
            //                         <canvas height="0" width="0" 
            //                         // style="border-radius: inherit; height: 100%; left: 0px; position: absolute; top: 0px; width: 100%;"
            //                         >
            //                         </canvas>
                                    
            //                         <span className="icon-marmita icon-marmita--social-facebook">
            //                             <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                                 <path d="M27.629 47V23.997h6.509L35 16.07h-7.371l.011-3.967c0-2.067.201-3.175 3.245-3.175h4.069V1h-6.51c-7.818 0-10.57 3.845-10.57 10.312v4.76H13v7.926h4.874V47h9.755z">
            //                                 </path>
            //                             </svg>
            //                         </span>
            //                     </a>
            //                 </li>
                            
            //                 <li>
            //                     <a className="btn-icon btn-icon--primary btn-icon--size-m btn-icon--transparent footer__social-icon" role="button" aria-label="Twitter" aria-hidden="false" target="_blank" rel="noopener" href="https://twitter.com/ifood">
            //                         <canvas height="0" width="0" 
            //                         // style="border-radius: inherit; height: 100%; left: 0px; position: absolute; top: 0px; width: 100%;"
            //                         >
            //                         </canvas>
                                    
            //                         <span className="icon-marmita icon-marmita--social-twitter">
            //                             <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                                 <path d="M22.821 14.375l.102 1.686-1.698-.206c-6.18-.792-11.58-3.477-16.165-7.987L2.82 5.63l-.58 1.653c-1.222 3.683-.441 7.574 2.106 10.19 1.358 1.446 1.053 1.652-1.29.792-.816-.276-1.529-.482-1.597-.379-.237.241.578 3.374 1.223 4.613.883 1.721 2.683 3.408 4.652 4.407L9 27.697l-1.97.035c-1.901 0-1.969.034-1.765.757.679 2.238 3.362 4.613 6.35 5.646l2.106.723-1.834 1.102c-2.717 1.583-5.909 2.478-9.101 2.547-1.528.035-2.785.172-2.785.275 0 .345 4.143 2.273 6.554 3.03 7.234 2.238 15.826 1.274 22.278-2.548 4.585-2.72 9.17-8.124 11.309-13.357 1.154-2.788 2.31-7.883 2.31-10.328 0-1.583.101-1.79 2.003-3.683 1.12-1.102 2.173-2.307 2.377-2.65.34-.655.306-.655-1.426-.07-2.887 1.033-3.294.895-1.868-.654 1.053-1.101 2.31-3.098 2.31-3.683 0-.104-.51.069-1.088.378-.61.345-1.97.861-2.988 1.17l-1.834.586-1.664-1.136c-.917-.62-2.207-1.308-2.887-1.515-1.732-.482-4.38-.413-5.943.138-4.245 1.55-6.928 5.542-6.622 9.915z">
            //                                 </path>
            //                             </svg>
            //                         </span>
            //                     </a>
            //                 </li>                                                                            
            //             </ul>
            //         </div>
            //     </section>
                
            //     <section className="footer__section footer__section--copyright">
            //         <div className="footer__copyright-text">
            //             <a target="_blank" title="Voltar para home" href="/lista-restaurantes">
            //                 <span className="icon-marmita icon-marmita--logo-ifood-smile">
            //                     <svg viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                         <path d="M11.34 21.34c8.49 0 14.37-7.71 14.37-14 0-4.58-4.35-6.59-8.49-6.59-9.3 0-14.37 8.3-14.37 14 0 4.59 4.4 6.6 8.5 6.6zm22.66 0c8.49 0 14.37-7.71 14.37-14 0-4.58-4.36-6.59-8.49-6.59-9.3 0-14.37 8.3-14.37 14 0 4.59 4.4 6.6 8.5 6.6m3.91 14.41c-5.25 4.45-12.27 6.74-19.9 6.26A19.2 19.2 0 0 1 .36 26.5h.46a17.83 17.83 0 0 0 14.05 9.97c6.46.71 14.37-2.2 18.71-6.34l-4.87-3.67 14.47.04-3.11 15.25-2.16-5.98z">
            //                         </path>
            //                     </svg>
            //                 </span>
            //             </a>
                        
            //             <p className="footer__company-copyright">
            //                 © Copyright 2020 - iFood - Todos os direitos reservados iFood com Agência de Restaurantes Online S.A.
            //             </p>
                        
            //             <p className="footer__company-informations">
            //                 CNPJ 14.380.200/0001-21 / Avenida dos Autonomistas, nº 1496, Vila Yara, Osasco/SP - CEP 06.020-902
            //             </p>
            //         </div>
                    
            //         <a target="_blank" title="Termos e condições de uso" className="footer__text-link" href="/termos">
            //             Termos e condições de uso
            //         </a>
                    
            //         <a target="_blank" title="Código de conduta" className="footer__text-link" href="/codigo-de-conduta">
            //             Código de conduta
            //         </a>
                    
            //         <a target="_blank" title="Privacidade" className="footer__text-link" href="/privacidade">
            //             Privacidade
            //         </a>
                    
            //         <a target="_blank" title="Dicas de segurança" className="footer__text-link" href="/seguranca">
            //             Dicas de segurança
            //         </a>
            //     </section> */}
            </footer>
        )
    }
}

export default Footer;