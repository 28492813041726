import React, {Fragment} from 'react';
import AbstractComponent from '../../AbstractComponent';
import PreloadContent from '../preload/PreloadContent';
import ModalAlerta from '../modal/ModalAlerta';
import Summary from '../summary/Summary';
import Breadcrumb from '../breadcrumb/Breadcrumb';

class Content extends AbstractComponent {
    classNamePreload() {
        return this.props.preload === true ? 'backgroundContent-preload' : '';
    }

    onModalErroClick() {
        if(this.props.onModalErroClick !== undefined)
            this.props.onModalErroClick();
    }

    showSummary() {
        return this.props.showSummary === undefined
            ? false
            : this.props.showSummary;
    }

    body() {
        return this.props.component({
            body: this.props.body
        });
    }

    showErro() {
        if((typeof String(this.props.modalErro))&&(this.props.modalErro === ''))
            return false;

        if(((typeof this.props.modalErro) === 'object')&&(this.props.modalErro.length === 0))
            return false;

        return true;
    }

    render() {
        return (
            <Fragment>
                <ModalAlerta
                    show={this.props.modalErro !== ''}
                    texto={this.props.modalErro}

                    fechar={() => this.onModalErroClick()}
                />

                <div
                    className={"content-container " + this.props.className + ' ' + this.classNamePreload()}
                    style={this.props.style}
                >                    
                    <Breadcrumb
                        show={this.props.breadcrumb !== undefined}
                        breadcrumb={this.props.breadcrumb}

                        route={(item) => this.props.route(item)}
                    />                    

                    <PreloadContent
                        exibir={this.props.preload}
                    />

                    <div className={(this.props.preload === true ? 'display-none' : '')}>
                        { this.body() }
                    </div>
                </div>

                <Summary
                    show={this.showSummary()}
                    texto={this.props.textoSummary}
                />
            </Fragment>
        )
    }
}

Content.defaultProps = {
    modalErro: '',
    modalSucesso: '',
    className: '',
    preload: false,
    head: '',
    textoSummary: '',
    style: {}
}

export default Content;