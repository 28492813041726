import React from "react";
import AbstractComponent from "../../AbstractComponent";
import Button from "../../layout/button/Button";
import ModalAlerta from "../../layout/modal/ModalAlerta";
import ModalWarning from "../../layout/modal/ModalWarning";
import ShoppingSuccess from "../ShoppingSuccess";
import ModalPagamento from "./ModalPagamento";

import AutenticarService from "../../../service/AutenticarService";
import PedidoService from "../../../service/PedidoService";

import TemProdutoPesavelEnum from "../../../helper/enumerador/TemProdutoPesavelEnum";
import OpcaoPagamentoEnum from "../../../helper/enumerador/OpcaoPagamentoEnum";

import { moeda } from "../../../helper/Formatar";
import { buscaConfiguracao } from "../../../helper/Validar";

class FechamentoButton extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      success: false,

      atualizeForm: false,

      atualizaCarrinho: false,

      modalProdPesavel: false,

      modalPagamento: false,

      modalAviso: false,

      idPedido: 0,

      erro: [],

      cartao: {
        numero: "",
        nome: "",
        expira_em: "",
        cvv: "",
        brand: "",
        parcelamento: 0,
        parcelamento_descricao: "",
      },
    };

    let produtoPesavel = [];

    produtoPesavel.push("Existem produtos pesáveis no carrinho.");
    produtoPesavel.push(
      "O valor total do pedido pode mudar de acordo com a pesagem e/ou disponibilidade de estoque!"
    );

    this.alertaProdutoPesavel = produtoPesavel;
  }

  validar() {
    let msgErro = this.state.erro;
    let erro = false;

    this.setState({ erro: [] });

    if (
      this.props.carrinho.entrega.valor_pedido_minimo >
      this.props.carrinho.total
    ) {
      erro = true;
      msgErro.push(
        "O valor do seu pedido deve ser maior que " +
          moeda(this.props.carrinho.entrega.valor_pedido_minimo) +
          "."
      );
    }

    if (parseInt(this.props.carrinho.entrega.id, 10) === 0) {
      erro = true;
      msgErro.push("Informe uma opção de entrega");
    }

    if (
      this.props.carrinho.entrega.pergunta_forma_pagamento === "Sim" &&
      this.props.pagamento.id === 0
    ) {
      erro = true;
      msgErro.push("Informe a forma de pagamento");
    }

    if (erro) {
      msgErro.splice(0, 0, "Por favor, corrija os problemas abaixo: ");

      this.setState({ erro: msgErro });

      return false;
    }

    return true;
  }

  onSubmit(e) {
    if (e !== undefined) e.preventDefault();

    if (!this.autenticar()) return;

    if (!this.validar()) return;

    this.setState({
      preload: true,
      erro: [],
    });

    let form = {};

    form.idCarrinho = AutenticarService.getIdCarrinho();
    form.documentoCliente = AutenticarService.getDocumentoCliente();
    form.destinatario = this.props.cliente.destinatario;
    form.tipo_endereco = this.props.cliente.tipo_endereco;
    form.cep = this.props.cliente.cep;
    form.logradouro = this.props.cliente.logradouro;
    form.endereco = this.props.cliente.endereco;
    form.numero = this.props.cliente.numero;
    form.complemento = this.props.cliente.complemento;
    form.bairro = this.props.cliente.bairro;
    form.cidade = this.props.cliente.cidade;
    form.uf = this.props.cliente.uf;
    form.codigo_municipio = this.props.cliente.codigo_municipio;
    form.ponto_de_interesse = this.props.cliente.ponto_de_interesse;
    form.idPagamento = this.props.pagamento.id;
    form.totalCarrinho = this.props.carrinho.totalizador.total;
    form.valorTroco = this.props.pagamento.valorTroco;
    form.cartao = this.state.cartao;

    PedidoService.gerar(form)
      .then((response) => {
        this.setState({
          preload: false,
          success: true,
          modalPagamento: false,
          idPedido: response.data,
        });
      })
      .catch((erro) => {
        let msgErro = this.state.erro;
        let atualizeForm = false;
        let atualizaCarrinho = false;

        if (erro.response.status === 402) atualizeForm = true;

        if (erro.response.status === 403) atualizaCarrinho = true;

        msgErro.push("Por favor, corrija os problemas abaixo:");
        msgErro.push(this.trataErro(erro));

        this.setState({
          preload: false,
          erro: msgErro,
          atualizeForm: atualizeForm,
          atualizaCarrinho: atualizaCarrinho,
        });
      });
  }

  onPagamentoOk(cartao) {
    let cartaoNew = this.state.cartao;

    cartaoNew.numero = cartao.numero;
    cartaoNew.nome = cartao.nome;
    cartaoNew.expira_em = cartao.expira_em;
    cartaoNew.cvv = cartao.cvv;
    cartaoNew.parcelamento = cartao.parcelamento;
    cartaoNew.parcelamento_descricao = cartao.parcelamento_descricao;
    cartaoNew.brand = cartao.brand;

    this.setState({ cartao: cartaoNew });

    this.onSubmit();
  }

  fecharPedido() {
    this.setState({ modalProdPesavel: false });

    if (
      this.props.pagamento.modalidade.opcaoPagamento ===
      OpcaoPagamentoEnum.PAGO_NO_SITE
    ) {
      this.setState({ modalPagamento: true });

      return;
    }

    this.onSubmit();
  }

  onClick() {
    if (parseInt(this.props.carrinho.entrega.id, 10) === 0) {
      this.props.chooseOpcaoEntrega();

      return;
    }

    this.props.chooseOpcaoPagamento();
  }

  texto() {
    if (parseInt(this.props.carrinho.entrega.id, 10) === 0)
      return "Escolher Opção de Entrega";

    return "Escolher Opção de Pagamento";
  }

  fecharModalAlerta() {
    if (this.state.atualizeForm) this.homePage2();

    if (this.state.atualizaCarrinho) this.props.refreshCart();

    this.setState({
      erro: [],
      atualizeForm: false,
      atualizaCarrinho: false,
    });
  }

  pegaMensagem() {
    return buscaConfiguracao(
        this.props.empresa.configuracao,
        "Pedido",
        "Mensagem_final",
        ""
      );
  }

  fechar() {
    this.homePage2();

    setTimeout(function () {
      this.setState({ success: false });
    }, 5000);
  }

  showButton() {
    if (
      parseInt(this.props.carrinho.entrega.id, 10) === 0 ||
      (this.props.carrinho.entrega.pergunta_forma_pagamento === "Sim" &&
        this.props.pagamento.id === 0)
    ) {
      return (
        <Button
          type="button"
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          onClick={() => this.onClick()}
        >
          {this.texto()}
        </Button>
      );
    }

    if (this.props.carrinho.tem_produto_pesavel === TemProdutoPesavelEnum.SIM) {
      return (
        <Button
          type="button"
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          onClick={() => this.setState({ modalProdPesavel: true })}
        >
          Fechar Pedido
        </Button>
      );
    }

    if (
      this.props.pagamento.modalidade.opcaoPagamento ===
      OpcaoPagamentoEnum.PAGO_NO_SITE
    ) {
      return (
        <Button
          type="button"
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          onClick={() => this.setState({ modalPagamento: true })}
        >
          Fechar Pedido
        </Button>
      );
    }

    return (
      <>
        <Button
          type={this.pegaMensagem() == '' ? "submit" : "button"}
          onClick={() => {
            if(this.pegaMensagem() != ''){
                this.setState({ modalAviso: true });
            }
          }}
          preload={this.state.preload}
          className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
          disabled={this.state.preload}
        >
          Fechar Pedido
        </Button>
        <ModalWarning
          styleText="ml-3"
          header={"Aviso"}
          show={this.state.modalAviso}
          texto={this.pegaMensagem()}
          fechar={() =>
            {
                this.onSubmit();
                this.setState({ modalAviso: false })
            }}
        />
      </>
    );
  }

  showModalPagamento() {
    if (this.props.empresa.id === 0) {
      return;
    }

    if (!this.state.modalPagamento) {
      return;
    }

    return (
      <ModalPagamento
        show={true}
        carrinho={this.props.carrinho}
        pagamento={this.props.pagamento}
        empresa={this.props.empresa}
        preload={this.state.preload}
        fechar={() => this.setState({ modalPagamento: false })}
        onPagamentoOk={(cartao) => this.onPagamentoOk(cartao)}
      />
    );
  }

  render() {
    return (
      <div className="area-cart__line_button">
        <ModalAlerta
          styleText="ml-3"
          header={"Erro de validação"}
          show={this.state.erro.length > 0}
          texto={this.state.erro}
          fechar={() => this.fecharModalAlerta()}
        />

        <ModalWarning
          styleText="ml-3"
          header={"Atenção"}
          show={this.state.modalProdPesavel}
          texto={this.alertaProdutoPesavel}
          fechar={() => this.fecharPedido()}
        />

        <ShoppingSuccess
          show={this.state.success}
          idPedido={this.state.idPedido}
          empresa={this.props.empresa}
          fechar={() => this.fechar()}
          route={(route) => this.props.route(route)}
        />

        {this.showModalPagamento()}

        <form onSubmit={(e) => this.onSubmit(e)}>{this.showButton()}</form>
      </div>
    );
  }
}

export default FechamentoButton;
