import Config from '../Config';

export function route(icone, nome, link) {
    let route = {};

    route.icone = icone;
    route.nome = nome;
    route.link = Config.urlBase + link;

    return route;
}