import React from 'react';
import AbstractComponent from '../../AbstractComponent';

class InputRadio extends AbstractComponent {        
    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }

    className() {
        return this.props.className !== undefined ? this.props.className : '';
    }    
   
    classNameInput() {
        let total = this.props.options.length;

        if(total === 0)
            return ;

        let qtd = Math.trunc(12 / total);
        
        return "col-md-" + qtd + " col-xs-" + qtd + " col-sm-" + qtd + " col-lg-" + qtd;
    }

    classErro() {
        return this.props.erro !== undefined && this.props.erro !== '' ? 'form-controll-erro' : '';
    }                    

    render() {
        return (
            <div
                className={"col-md-" + this.props.md + " col-xs-" + this.props.xs + " col-sm-" + this.props.sm + " col-lg-" + this.props.lg}
                style={{marginBottom: '17px'}}
            >
                <div className={this.className() + ' ' + this.classErro()}> 
                    {
                        this.props.options.map((item, i) => {
                            return (
                                <div key={i} className={this.classNameInput() + " form-check " + (item.disabled ? 'disabled' : '')}>                                    
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name={item.valor}
                                        id={item.valor + i}
                                        value={item.valor}
                                        checked={item.checked}
                                        disabled={this.props.disabled}
                                        onClick={(e) => this.props.onClick(e)}
                                        onChange={() => this.props.onChange(item.valor)}
                                    />

                                    <label 
                                        className="form-check-label" 
                                        htmlFor={item.valor + i}                                        
                                    >
                                        {item.texto}
                                    </label>
                                </div>
                            )
                        })
                    }                    

                    <span className="help-block">
                        {this.erro()}
                    </span>
                </div>
            </div>
        )
    }
}

InputRadio.defaultProps = {
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12,
    disabled: false
};

export default InputRadio;