import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Preload from '../preload/Preload';
import InputSelect from './InputSelect';
import UFService from '../../../service/UFService';

class InputSelectUF extends AbstractComponent {

    constructor(props) {
        super(props);  
        
        this.state = {
            listas: [{valor: 0, texto: 'Selecione'}],
            preload: true                       
        }        
    }

    onChange(input) {
        if (this.props.onChange !== undefined) {
            this.props.onChange(input);
        }
    }
    
    componentDidMount() {         
        UFService.listar().then(resposta => {                                   
            const lista = [{valor: 0, texto: 'Selecione'}];

            resposta.data.map(item => {
                lista.push({
                    valor: item.sigla, 
                    texto: item.sigla, 
                    selected: (parseInt(item.sigla, 10) === parseInt(this.props.editarId, 10))
                });
                
                return item;
            });

            this.setState({listas: lista, preload: false});            
        }).catch(error => {            
            this.setState({preload: false});

            if(error.response === undefined || error.response.data === '') {
                this.setState({erro: this.P_ERRO_PADRAO()});
                return;
            }            

            this.setState({erro: error.response.data});
        });        
    }          

    render() {
        return (    
            <Fragment>
                <Preload exibir={this.state.preload} />
                
                <InputSelect 
                    md={this.props.md} 
                    xs={this.props.xs} 
                    sm={this.props.sm} 
                    lg={this.props.lg}                         
                    nome={this.props.nome}                             
                    classNameInput={this.props.classNameInput}
                    id={this.props.nome}                               
                    value={this.props.value}     
                    defaultValue={this.props.defaultValue}     
                    erro={this.props.erro}                  
                    options={this.state.listas}
                    onChange={e => this.onChange(e)}
                >                            
                    UF
                </InputSelect>            
            </Fragment>
        );
    }
}

export default InputSelectUF;
