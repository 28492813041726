import React, { Fragment } from "react";
import AbstractComponent from "../AbstractComponent";
import Input from "../layout/input/Input";
import InputCheckBox from "../layout/input/InputCheckBox";
import Button from "../layout/button/Button";
import ModalAlerta from "../layout/modal/ModalAlerta";
import EnderecoContent from "./content/EnderecoContent";

import EnderecoService from "../../service/EnderecoService";

import TipoCalculoFreteEnum from "../../helper/enumerador/TipoCalculoFreteEnum";
import TipoEnderecoEnum from "../../helper/enumerador/TipoEnderecoEnum";

import { buscaConfiguracao } from "../../helper/Validar";

class EnderecoCadastro extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,
      erro: "",
    };
  }

  limpaForm() {
    var erroForm = this.props.erroForm;

    erroForm.destinatario = "";
    erroForm.tipo_endereco = "";
    erroForm.cep = "";
    erroForm.logradouro = "";
    erroForm.endereco = "";
    erroForm.numero = "";
    erroForm.bairro = "";
    erroForm.cidade = "";
    erroForm.uf = "";
    erroForm.ponto_de_interesse = "";
    erroForm.ponto_de_interesse_descricao = "";

    this.props.onErro(erroForm);
  }
  cidadesDisponiveisFrete() {
    let response = true;
    if (this.props.cidadesDisponiveis) {
      for (let i = 0; i < this.props.cidadesDisponiveis.length; i++) {
        if (
          this.props.cidadesDisponiveis[i].codigo_municipio ==
          this.props.form.codigo_municipio
        )
          response = false;
      }
    }
    return response;
  }

  valida() {
    this.limpaForm();

    var erro = false;
    var form = this.props.form;
    var erroForm = this.props.erroForm;

    if (this.cidadesDisponiveisFrete()) {
      erroForm.cep = "Cidade indisponivel para entrega.";
      erro = true;
    }

    if (!form.destinatario) {
      erroForm.destinatario = "Informe o campo Destinatário";
      erro = true;
    }

    if (form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name) {
      if (!form.cep) {
        erroForm.cep = "Informe o campo Cep";
        erro = true;
      }

      if (!form.logradouro) {
        erroForm.logradouro = "Informe o campo Logradouro";
        erro = true;
      }

      if (!form.endereco) {
        erroForm.endereco = "Informe o campo Endereço";
        erro = true;
      }

      if (!form.numero) {
        erroForm.numero = "Informe o campo Número";
        erro = true;
      }

      if (!form.bairro) {
        erroForm.bairro = "Informe o campo Bairro";
        erro = true;
      }

      if (!form.cidade) {
        erroForm.cidade = "Informe o campo Cidade";
        erro = true;
      }

      if (!form.uf) {
        erroForm.uf = "Informe o campo Uf";
        erro = true;
      }

      if (
        buscaConfiguracao(
          this.props.empresa.configuracao,
          "Empresa",
          "Tipo_de_cálculo_do_frete",
          "Cidade"
        ) === TipoCalculoFreteEnum.BAIRRO_POI.name
      ) {
        if (!form.ponto_de_interesse || form.ponto_de_interesse === "0") {
          erroForm.ponto_de_interesse = "Informe o ponto de interesse";
          erro = true;
        }
      }
    }

    if (form.tipo_endereco === TipoEnderecoEnum.POI.name) {
      if (!form.ponto_de_interesse) {
        erroForm.ponto_de_interesse = "Informe o ponto de interesse";
        erro = true;
      }

      if (!form.ponto_de_interesse_descricao) {
        erroForm.ponto_de_interesse_descricao =
          "Informe o campo descritivo do ponto de interesse";
        erro = true;
      }
    }

    this.props.onErro(erroForm);

    return !erro;
  }

  salvar() {
    if (!this.valida()) return;

    this.setState({ preload: true });

    EnderecoService.gravar(this.props.form)
      .then((resposta) => {
        this.setState({
          preload: false,
        });

        if (this.props.onChangeStep !== undefined) this.props.onChangeStep();

        let endereco = {};

        endereco.destinatario = this.props.form.destinatario;

        endereco.endereco = {};

        endereco.endereco.cep = this.props.form.cep;
        endereco.endereco.logradouro = this.props.form.logradouro;
        endereco.endereco.endereco = this.props.form.endereco;
        endereco.endereco.numero = this.props.form.numero;
        endereco.endereco.complemento = this.props.form.complemento;
        endereco.endereco.bairro = this.props.form.bairro;
        endereco.endereco.cidade = this.props.form.cidade;
        endereco.endereco.uf = this.props.form.uf;
        endereco.endereco.codigo_municipio = this.props.form.codigo_municipio;

        endereco.tipo_endereco = this.props.form.tipo_endereco;
        endereco.ponto_de_interesse = this.props.form.ponto_de_interesse;
        endereco.ponto_de_interesse_manual =
          this.props.form.ponto_de_interesse_manual;
        endereco.ponto_de_interesse_descricao =
          this.props.form.ponto_de_interesse_descricao;

        this.props.onUpdateEnderecoEntrega(endereco, resposta.data);

        this.props.onNew();
      })
      .catch((error) => {
        this.setState({
          preload: false,
          erro: this.trataErro(error),
        });
      });
  }


  render() {
    return (
        <Fragment>
        <ModalAlerta
          show={this.state.erro !== ""}
          texto={this.state.erro}
          fechar={() => this.setState({ erro: "" })}
        />

        <div className="address-cadastro">
          <div
            className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12"
          >
            <div className="row">
              <Input
                md={12}
                xs={12}
                sm={12}
                lg={12}
                inputRef={(e) => (this.destinatario = e)}
                id="destinatario"
                nome="destinatario"
                value={this.props.form.destinatario}
                defaultValue={this.props.form.destinatario}
                defaultFoco={true}
                erro={this.props.erroForm.destinatario}
                proxFoco={() => this.foco("cep")}
                onChange={(e) => this.props.onChange(e)}
              >
                Destinatário
              </Input>
            </div>

            <EnderecoContent
              addEndereco={true}
              form={this.props.form}
              empresa={this.props.empresa}
              erroForm={this.props.erroForm}
              forcaPontoInteresse={
                this.props.forcaPontoInteresse ||
                this.props.form.ponto_de_interesse_manual === "Sim"
              }
              isEndereco={this.props.form.tipo === "Casa"}
              onChange={(e) => this.props.onChange(e)}
              onRadioTipoEnderecoClick={(tipo_endereco) =>
                this.props.onRadioTipoEnderecoClick(tipo_endereco)
              }
              onBlurCep={(e) => this.props.onBlurCep(e)}
              onBlurBairro={(pontoDeInteresse, forcaPontoInteresse) =>
                this.props.onBlurBairro(pontoDeInteresse, forcaPontoInteresse)
              }
            />
          </div>
        </div>

        <div
          style={{
            height: "auto",
            bottom: "0",
            borderTop: "1px solid #e6e4e6",
            marginTop: "10px",
          }}
        >
          <div className="row">
            <InputCheckBox
              md={6}
              xs={12}
              sm={6}
              lg={6}
              id="padrao"
              nome="padrao"
              value={this.props.form.padrao}
              defaultValue={this.props.form.padrao}
              checked={parseInt(this.props.form.padrao, 10) === 1}
              onChange={(e) => this.props.onChange(e)}
            >
              Padrão para entregas
            </InputCheckBox>

            <div
              className="col-md-6
                                        col-sm-6
                                        col-lg-6
                                        col-xs-12
                                        text-right"
            >
              <Button
                type="button"
                preload={this.state.preload}
                className={
                  "btn btn-primary " +
                  (this.state.preload ? "btn--preload" : "")
                }
                background={3}
                disabled={this.state.preload}
                onClick={() => this.salvar()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}

export default EnderecoCadastro;
