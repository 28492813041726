import React from 'react';
import AbstractComponent from '../AbstractComponent';
import ContentMini from '../layout/content/ContentMini';
import SobreContent from './SobreContent';

class Sobre extends AbstractComponent {    
    componentDidMount() {     
        this.onTitulo("Sobre");
    }

    render() {
        return (   
            <ContentMini
                component={(props) => {                     
                    return <SobreContent
                        {...props}                                 
                    />   
                }}                 
            />                    
        )
    }
}

export default Sobre;