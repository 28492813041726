import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import InputSelect from '../../layout/input/InputSelect';

import FormasPagamentoService from '../../../service/FormasPagamentoService';

import { moeda } from '../../../helper/Formatar';

class InputSelectParcelamento extends AbstractComponent {

    constructor(props) {
        super(props);  
        
        this.state = {
            listas: [{valor: 0, texto: 'Aguarde...'}],

            disabled: false
        }        
    }        
    
    onBlur(e) {
        var resp = '';

        this.state.listas.forEach(element => {
            if(element.valor === parseInt(e.target.value, 10)) {
                resp = element.texto;

                return ;
            }
        });

        this.props.onBlur(resp);
    }

    getParcela(item) {
        let parcela = item.parcela + 'x ';

        if(item.parcela === 1) {
            parcela = 'À Vista ';
        }

        if(item.acrescimo > 0) {
            parcela += 'com juros ';
        } else {
            parcela += 'sem juros ';
        }
        
        var valorParcela = this.props.carrinho.totalizador.total / item.parcela;        

        parcela += moeda(valorParcela);

        return parcela;
    }

    componentDidMount() {         
        FormasPagamentoService.getParcelas(
            this.props.empresa.id,
            this.props.pagamento.id,
            this.props.carrinho.totalizador.total
        ).then(resposta => {
            const lista = [{valor: 0, texto: 'Selecione'}];
            
            if(resposta.data.length === 1) {
                this.props.onUpdateEditarId(resposta.data[0].id, this.getParcela(resposta.data[0]));
            }

            resposta.data.map(item => {
                lista.push({valor: item.id, 
                            texto: this.getParcela(item), 
                            selected: (parseInt(item.id, 10) === parseInt(this.props.editarId, 10)) 
                })
                
                return item;
            });                        

            this.setState({
                listas: lista,
                disabled: resposta.data.length === 1
            });
        }).catch(erro => {

        });        
    }            

    render() {
        return (    
            <Fragment>                
                <InputSelect 
                    md={this.props.md} 
                    xs={this.props.xs} 
                    sm={this.props.sm} 
                    lg={this.props.lg} 
                    classNameInput={this.props.classNameInput}                        
                    nome={this.props.nome}                                                         
                    id={this.props.nome} 
                    options={this.state.listas}                            
                    value={this.props.value}                             
                    defaultValue={this.props.defaultValue}
                    erro={this.props.erro}
                    disabled={this.state.disabled}
                    onChange={e => this.props.onChange(e)}
                    onBlur={e => this.onBlur(e)}
                >
                    Parcelamento
                </InputSelect>            
            </Fragment>
        );
    }
}

export default InputSelectParcelamento;
