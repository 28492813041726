import React, { Fragment } from 'react';
import AbstractFilter from '../../filter/AbstractFilter';
import MobileMenu from '../../menu//mobile/MobileMenu';
import MenuPerfil from '../../menu/MenuPerfil';
import Title from '../../title/Title';
import Button from '../../button/Button';
import ButtonEscolher from '../../button/ButtonEscolher';
import CategoriaMobileModal from '../../../categoria/CategoriaMobileModal';
import InputToggle from '../../input/InputToggle';

import { route } from '../../../../helper/Route'

class NavBottom extends AbstractFilter {
    constructor(props) {
        super(props);

        this.state = {
            active: -1,

            showSuaContaMenu: false,

            showCategoriaMenu: false,            

            filtroType: '',

            filtro: []            
        }        
    }        

    showSuaContaMenu() {
        return (         
            <div id="carrinhoMobile" className={"context-card context-card--visible " + (!this.state.showSuaContaMenu ? '' : 'context-card--opened')}>
                <div className={"context-card__maximized " + (!this.state.showSuaContaMenu ? '' : 'context-card__maximized--opened') + " checkout-context-card__maximized cardMenus"}>
                    {
                        this.state.showSuaContaMenu &&
                        <Fragment>
                            <Title
                                title={"Sua Conta"}
                                fechar={() => this.setState({showSuaContaMenu: false})}
                            />
                                
                            <MenuPerfil
                                cliente={this.props.cliente}
                                  
                                onClick={(item) => { 
                                    this.setState({ showSuaContaMenu: false }); 
                                    this.props.route(item)
                                }}
                            />

                        </Fragment>                    
                    }
                </div>
            </div>
        );
    }       
    
    onApplyFiltro(items) {
        let filtro = this.preparaFiltro(items, this.state.filtro, this.state.filtroType);  

        this.setState({
            filtro: filtro,
            filtroType: ''
        });

        this.props.onApplyFiltro(this.state.filtro); 
        this.setState({showCategoriaMenu: false});           
    }    

    onChange(e) {
        let filtro = this.preparaFiltro(e.target.value, this.state.filtro, e.target.name);  

        this.setState({
            filtro: filtro
        });        

        setTimeout(() => { 
            this.props.onApplyFiltro(this.state.filtro); 
            this.setState({showCategoriaMenu: false});
        }, 1000);
    }

    limparFiltro() {
        let filtro = this.state.filtro;

        for(var cont = 0; cont <= filtro.length; cont++)
            filtro.pop();        

        this.setState({filtro: filtro});        

        this.props.onApplyFiltro(this.state.filtro);
        this.setState({showCategoriaMenu: false});
    }

    showFiltros() {        
        if(!this.state.showCategoriaMenu)
            return ;
        
        return (
            <Fragment>
                <Title
                    title={"Categoria"}
                    fechar={() => this.setState({showCategoriaMenu: false})}
                />
                
                <div 
                    style={{
                        padding: '0px 15px',                                         
                    }}
                >
                    <ButtonEscolher                                
                        type="button"                
                        texto="Departamento"
                        textoSelecionar="Escolher"                                                                                            
                        subtitulo={this.getCategoria('Departamento', this.props.filtros, this.state.filtro)}
                        showSubtitulo={this.getCategoria('Departamento', this.props.filtros, this.state.filtro) !== ''} 
                        onClick={() => this.setState({                        
                            filtroType: 'departamento'
                        })}
                    />

                    <ButtonEscolher                                
                        type="button"                        
                        texto="Marca"
                        textoSelecionar="Escolher"                                                                                            
                        subtitulo={this.getCategoria('Marca', this.props.filtros, this.state.filtro)}
                        showSubtitulo={this.getCategoria('Marca', this.props.filtros, this.state.filtro)  !== ''}
                        onClick={() => this.setState({                        
                            filtroType: 'marca'
                        })}                                    
                    />

                    <ButtonEscolher                                
                        type="button"                                                
                        texto="Grupo"
                        textoSelecionar="Escolher"                                                                                            
                        subtitulo={this.getCategoria('Grupo', this.props.filtros, this.state.filtro)}
                        showSubtitulo={this.getCategoria('Grupo', this.props.filtros, this.state.filtro)  !== ''}   
                        onClick={() => this.setState({                        
                            filtroType: 'grupo'
                        })}                                 
                    />

                    <InputToggle
                        md={12} 
                        xs={12} 
                        sm={12} 
                        lg={12} 
                        nome="ofertas"                                                                                                             
                        value={this.getCategoria('ofertas', this.props.filtros, this.state.filtro)}
                        checked={parseInt(this.getCategoria('ofertas', this.props.filtros, this.state.filtro),10) === 1}                        
                        onChange={(e) => this.onChange(e)}
                    >     
                        <span style={{fontSize: '16px'}}>
                            Ofertas            
                        </span>
                    </InputToggle>                    
                </div>

                
                <div 
                    className="col-md-12
                               col-sm-12
                               col-lg-12
                               col-xs-12"
                    style={{
                        position: 'fixed',
                        bottom: '10px'
                    }}
                >
                    <Button
                        type="button"       
                        disabled={this.state.filtro.length === 0}                 
                        className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"                        
                        onClick={() => this.limparFiltro()}
                    >
                        Limpar Filtros
                    </Button>
                </div>
            </Fragment> 
        );       
    }

    showCategoriaMenu() {
        return (         
            <div id="carrinhoMobile" className={"context-card context-card--visible " + (!this.state.showCategoriaMenu ? '' : 'context-card--opened')}>
                {
                    this.state.filtroType !== '' &&                
                    <CategoriaMobileModal
                        show={true}
                        header={this.state.filtroType}                                            
                        filtros={this.getCategoria(this.state.filtroType, this.props.filtros, this.state.filtro)}

                        fechar={() => this.setState({filtroType: ''})}                        
                        onApply={(items) => this.onApplyFiltro(items)}
                    />
                }

                <div className={"context-card__maximized " + (!this.state.showCategoriaMenu ? '' : 'context-card__maximized--opened') + " checkout-context-card__maximized cardMenus"}>
                    {this.showFiltros()}
                </div>
            </div>
        );
    }

    verificaLogado() {
        if (!this.isLogged()) {
            return this.login2();
        }

        this.setState({ showSuaContaMenu: !this.state.showSuaContaMenu });
    }

    render() {
        return (
            <Fragment>
                {this.showSuaContaMenu()}

                { this.showCategoriaMenu() }

                <div className="tab-bar">                
                    <MobileMenu
                        icon="pe-7s-home"
                        title="Início"
                        onClick={() => this.homePage2() }
                    />

                    <MobileMenu
                        icon="fas fa-list"
                        title="Categoria"
                        onClick={() => this.setState({ showCategoriaMenu: !this.state.showCategoriaMenu }) }
                    />

                    <MobileMenu
                        badge={this.props.badge.favorito}
                        icon="pe-7s-like"
                        title="Favorito"
                        onClick={() => this.props.route(route('', 'Favorito', 'favorito'))}
                    />

                    <MobileMenu
                        badge={this.props.badge.notificacao}
                        icon="pe-7s-bell"
                        title="Notificação"
                        onClick={() => this.props.route(route('', 'Notificação', 'notificacao'))}
                    />                    

                    <MobileMenu
                        icon="pe-7s-user"
                        title="Sua conta"
                        onClick={() => this.verificaLogado()}
                    />
                </div>
            </Fragment>
        )
    }
}

export default NavBottom;