import React from 'react';
import {Col} from 'react-bootstrap';
import Cards from 'react-credit-cards';
import AbstractComponent from '../../AbstractComponent';

import 'react-credit-cards/es/styles-compiled.css'

class ImageCredCard extends AbstractComponent {        
    render() {
        return (
            <Col 
                md={this.props.md} 
                xs={this.props.xs} 
                sm={this.props.sm} 
                lg={this.props.lg}
                className="my-auto text-center"
            >
                <Cards                    
                    focused={this.props.focused}

                    number={this.props.number}
                    name={this.props.name}
                    expiry={this.props.expiry}
                    cvc={this.props.cvc}    
                    
                    callback={(card) => this.props.onUpdateBrand(card)}
                />                
            </Col>
        )
    }
}

ImageCredCard.defaultProps = {
    md: 12,    
};

export default ImageCredCard;