import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import InputNumber from '../../layout/input/InputNumber';
import Config from '../../../Config';

import ClienteService from "../../../service/ClienteService";

import {validarCpf, validarEmail} from "../../../helper/Validar";

class Cpf extends AbstractComponent {    
    constructor(props) {
        super(props);

        this.state = {            
            cpf: '',            

            erro: '',

            btnVerificarCPFTxt: 'VERIFICAR',
        }
    }    

    validarEmailCPF() {
        if(validarEmail(this.state.cpf))
            return true;

        if(validarCpf(this.state.cpf))
            return true;

        return false;
    }
    
    valida() {
        this.setState({ erro: '' });        

        if (!this.state.cpf) {                        
            this.setState({ erro: "Informe um CPF válido" });

            return false;
        }

        if (!this.validarEmailCPF()) {                        
            this.setState({ erro: "Email/CPF inválido" });

            return false;
        }

        return true;
    }     

    verificarCPFByRedeSocial() {
        this.props.social.tipo_rede_social.verificarCPFByRedeSocial(            
            this.state.cpf,
            this.props.social.idRedeSocial
        ).then(resposta => {
            let form = this.props.form;

            form.id = resposta.data.id;
            form.cpf = this.state.cpf;
            form.email = resposta.data.email;
            form.nome = resposta.data.nome;            
            form.foto_perfil = resposta.data.foto_perfil;

            this.setState({ btnVerificarCPFTxt: 'VERIFICAR' });
            this.props.onCpfOk(form)
        }).catch((erro) => {     
            this.setState({ btnVerificarCPFTxt: 'VERIFICAR' });

            if(erro.response.status === 402) {
                let form = this.props.social;
            
                form.cpf = this.state.cpf;
                form.idRedeSocial = this.props.social.idRedeSocial;
                form.email = this.props.social.email;
                form.nome = this.props.social.nome;            
                form.foto_social = this.props.social.foto_social;
                form.tipo_rede_social = this.props.social.tipo_rede_social;
            
                this.props.onCpfErro(form);

                return ;
            }

            this.setState({ erro: this.trataErro(erro) });
        });
    }

    verificarCPF(e) {
        if (!this.valida())
            return;

        e.preventDefault();

        this.setState({ btnVerificarCPFTxt: 'Aguarde...' });

        if(this.props.social.idRedeSocial !== undefined && this.props.social.idRedeSocial !== null && this.props.social.idRedeSocial !== '') {
            this.verificarCPFByRedeSocial();

            return 
        }
         
        ClienteService.verificarCPF(            
            this.state.cpf            
        ).then(resposta => {
            let form = this.props.form;

            form.id = resposta.data.id;
            form.cpf = this.state.cpf;
            form.email = resposta.data.email;
            form.userName = resposta.data.nome;
            form.foto_perfil = Config.urlImgCliente + resposta.data.foto_perfil
            form.foto_perfil = resposta.data.foto_google ? resposta.data.foto_google : resposta.data.foto_facebook;            

            this.setState({ btnVerificarCPFTxt: 'VERIFICAR' });
            this.props.onCpfOk(form)
        }).catch(() => {
            let form = this.props.form;

            form.id = 0;
            form.cpf = this.state.cpf;
            form.email = '';
            form.userName = '';
            form.foto_perfil = '';                       

            this.setState({ btnVerificarCPFTxt: 'VERIFICAR' });
            this.props.onCpfErro(form);            
        });
    }
    
    onKeyPress(e) {
        if (e.key === "Enter") {
            if (!this.verificarCPF(e))
                return;            
        }
    }                

    showComponent() {
        if(!this.props.show)
            return ;
            
        return (
            <Fragment>
                <div className="row"></div>

                <div className="row">                                      
                    <InputNumber                            
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}                            
                        inputRef={e => this.cpf = e}
                        id="cpf"
                        nome="cpf"
                        value={this.state.cpf}
                        defaultValue={this.state.cpf}
                        defaultFoco={true}
                        erro={this.state.erro}
                        onChange={e => this.setState({cpf: e.target.value})}
                        onKeyPress={e => this.onKeyPress(e)}>
                        CPF
                    </InputNumber>                                        
                </div>

                <div className="row">
                    <div className='col-md-12 text-center'>                        
                        <h4>
                            Digite seu CPF para entrar
                            <br />
                            ou cadastrar-se
                        </h4>                                             
                    </div>
                </div>

                <div className="row"></div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <button id="btnVerificar"
                            type="button"
                            className="btn btn-control btn-warning btn-lg"
                            onClick={e => this.verificarCPF(e)}
                            disabled={((this.state.btnVerificarCPFTxt !== 'VERIFICAR')||(this.props.readOnly))}>
                            <span className="text-size">{this.state.btnVerificarCPFTxt}</span>
                        </button>
                    </div>
                </div>

                <div className="row"></div>
                <div className="row"></div>                
            </Fragment>            
        );
    }

    render() {
        return (
            <Fragment>
                {this.showComponent()}
            </Fragment>
        );
    }
}

export default Cpf;