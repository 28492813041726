import React from 'react';
import AbstractComponent from '../AbstractComponent';

class NotificacaoEmpty extends AbstractComponent {   
    render() {
        return(            
            <div style={{padding: '80px 0'}}>
                <div className="area-cart__empty">
                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-center">
                        <i className="far fa-bell-slash" style={{fontSize: '95px'}} />
                    </div>
                    
                    <br/>

                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-center">
                        <span className="area-cart__empty--large">
                            Não há notificações
                        </span>                                    
                    </div>
                </div>
            </div>
        )
    }
}

export default NotificacaoEmpty;