import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from './Input';
import Preload from '../preload/Preload';

import TipoCalculoFreteEnum from '../../../helper/enumerador/TipoCalculoFreteEnum';

import { buscaConfiguracao } from '../../../helper/Validar';

import EmpresaFreteService from '../../../service/EmpresaFreteService';

class InputBairro extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: false
        }
    }   

    onBlur(e) {
        if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.CIDADE.name) 
            return ;
        
        if(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade') === TipoCalculoFreteEnum.DISTANCIA.name)
            return ;        
            
        this.setState({preload: true});

        let bairro = e.target.value;

        EmpresaFreteService.pontoDeInteresseExists(            
            this.props.tipo_endereco,
            bairro
        ).then(() => {   
            this.props.onBlur(bairro, false);            

            this.setState({preload: false});
        }).catch(() => {                                    
            this.props.onBlur('', true);

            this.setState({preload: false});
        }); 
    }   

    render() {
        return (
            <Fragment>
                <Preload
                    exibir={this.state.preload}                
                />

                <Input
                    md={this.props.md}
                    xs={this.props.xs}
                    sm={this.props.sm}
                    lg={this.props.lg}
                    nome={this.props.nome}                    
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    erro={this.props.erro}
                    onChange={e => this.props.onChange(e)}
                    onBlur={e => this.onBlur(e)}
                >
                    {this.props.children}
                </Input>
            </Fragment>
        )
    }
}

InputBairro.defaultProps = {
    md: 12,
    type: 'text'
};

export default InputBairro;