import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import FechamentoButton from './FechamentoButton';
import OpcaoEntrega from './opcao-entrega/OpcaoEntrega';
import OpcaoPagamento from './opcao-pagamento/OpcaoPagamento';

import PagamentoEnum from '../../../helper/enumerador/PagamentoEnum';
import AtivoEnum from '../../../helper/enumerador/AtivoEnum';

class Fechamento extends AbstractComponent {    
    constructor(props) {
        super(props);

        this.state = {   
            chooseOpcaoPagamento: false,

            chooseOpcaoEntrega: false             
        }        
    }    

    onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento) {
        this.props.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento);        
        this.setState({ chooseOpcaoPagamento: false });        
    }

    onUpdateCart(cart) {        
        this.setState({ chooseOpcaoEntrega: false });
        this.props.onUpdateCart(cart);
    }    

    chooseOpcaoEntrega() {
        if(!this.autenticar())
            return ;

        this.setState({chooseOpcaoEntrega: true})
    }    

    chooseOpcaoPagamento() {
        if(!this.autenticar())
            return ;
            
        this.setState({chooseOpcaoPagamento: true})
    }

    componentDidUpdate() {        
        if((this.props.pagamento.id === PagamentoEnum.DINHEIRO)&&(this.props.carrinho.totalizador.total > 0.00)&&(this.props.carrinho.totalizador.total !== this.props.carrinho.totalizador.total)) {            
            this.props.onUpdatePagamento(
                0,
                '',
                0,
                '',
                AtivoEnum.NAO
            );            
        }
    }

    render() {
        return(
            <Fragment>
                <FechamentoButton
                    form={this.state.form} 
                    cliente={this.props.cliente}
                    carrinho={this.props.carrinho}
                    pagamento={this.props.pagamento}
                    empresa={this.props.empresa}

                    chooseOpcaoPagamento={() => this.chooseOpcaoPagamento()}  
                    chooseOpcaoEntrega={() => this.chooseOpcaoEntrega()}
                    refreshCart={() => this.props.refreshCart()}

                    route={(item) => this.props.route(item)}
                /> 

                <div className={"area-cart__line area-cart-footer__button-wrapper " + (this.state.showPagamentoMinimized ? '' : 'context-card--opened')} >
                    <div className="payment-info">
                        <p className="payment-info__title">
                            Fechamento
                        </p>
                        
                        <OpcaoEntrega   
                            cidadesDisponiveis = {this.props.cidadesDisponiveis}                                 
                            cliente={this.props.cliente}  
                            empresa={this.props.empresa}                       
                            carrinho={this.props.carrinho}                                      
                            opcaoEntrega={this.props.opcaoEntrega}

                            chooseOpcaoEntrega={this.state.chooseOpcaoEntrega}

                            onUpdateCart={(cart) => this.onUpdateCart(cart)}
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}                    
                            fecharChooseOpcaoEntrega={() => this.setState({chooseOpcaoEntrega: false})}

                            route={(item) => this.props.route(item)}
                        />

                        <OpcaoPagamento                                                            
                            carrinho={this.props.carrinho}
                            cliente={this.props.cliente}  
                            empresa={this.props.empresa}                  
                            modalidadePagamento={this.props.modalidadePagamento}
                            pagamento={this.props.pagamento}     
                            opcaoEntrega={this.props.opcaoEntrega}                    
                            
                            chooseOpcaoPagamento={this.state.chooseOpcaoPagamento}
                            chooseOpcaoEntrega={this.state.chooseOpcaoEntrega}
                                                
                            onUpdatePagamento={(id, descricao, troco, opcaoPagamento, permite_parcelamento) => this.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento)}
                            onUpdateCart={(cart) => this.onUpdateCart(cart)}
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                            fecharChooseOpcaoPagamento={() => this.setState({chooseOpcaoPagamento: false})}

                            route={(item) => this.props.route(item)}
                        />
                                        
                        <span className="line-separator">
                        </span>
                    </div>
                </div> 
            </Fragment>
        )
    }
}

export default Fechamento;