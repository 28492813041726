import React, { Fragment } from 'react';
import AbstractComponent from '../../../AbstractComponent';
import ModalQuestion from '../../../layout/modal/ModalQuestion';
import ButtonModalidadePagamento from './ButtonModalidadePagamento';
import TrocoModal from './TrocoModal';

import AtivoEnum from '../../../../helper/enumerador/AtivoEnum';

class PagarNaEntrega extends AbstractComponent {  
    constructor(props) {
        super(props);

        this.state = {
            form: {
                idModalidadePagamento: 0,

                descricao: '', 

                valorTroco: 0,
            },

            modalTroco: false,            

            modalValorTroco: false
        }
    }   

    btnSimClick() {
        this.setState({
            modalTroco: false,
            modalValorTroco: true
        });        
    }

    btnNaoClick() {
        this.setState({modalTroco: false});
        this.props.onUpdatePagamento(this.state.form.idModalidadePagamento, this.state.form.descricao.toUpperCase(), this.state.form.valorTroco, AtivoEnum.NAO);
    }

    onTroco(troco) {        
        let form = this.state.form;

        form.valorTroco = troco;            

        this.setState({
            form: {},
            modalValorTroco: false
        });

        this.props.onUpdatePagamento(form.idModalidadePagamento, form.descricao.toUpperCase(), form.valorTroco, AtivoEnum.NAO);
    }

    onClick(id, descricao, hasTroco) {
        let form = this.state.form;

        form.idModalidadePagamento = id;
        form.descricao = descricao;
        form.valorTroco = 0;            

        if(hasTroco) {
            this.setState({   
                form: form,                            
                modalTroco: true
            });

            return ;            
        }

        this.props.onUpdatePagamento(form.idModalidadePagamento, form.descricao.toUpperCase(), form.valorTroco, AtivoEnum.NAO);
    } 

    showComponent() {
        if(!this.props.show) {
            return ;
        }

        return (            
            <>     
                <ModalQuestion
                    styleHeader="__title"
                    show={this.state.modalTroco}
                    header={this.state.form.descricao}
                    texto="Você vai precisar de troco?"            
                    btnSimClick={() => this.btnSimClick()}
                    btnNaoClick={() => this.btnNaoClick()}                    
                />

                <TrocoModal
                    className="text-center"
                    show={this.state.modalValorTroco}
                    header={"Troco pra quanto?"} 
                    totalPedido={this.props.totalPedido}
                    fechar={() => this.setState({modalValorTroco: false})} 
                    onTroco={(troco) => this.onTroco(troco)}
                />

                {this.props.modalidadePagamento.map((grupo, index ) => {                    
                    return (                        
                        grupo.dados.length > 0 &&
                        <ButtonModalidadePagamento
                            md={12}
                            xs={12}
                            sm={12}
                            lg={12}     
                            key={index} 
                            grupo={grupo}  
                            showGrupo={(this.props.modalidadePagamento.length > 1 ? 1 : 2)}

                            onClick={(id, descricao, hasTroco) => this.onClick(id, descricao, hasTroco)}
                        />                                         
                    )
                })} 
            </>
        )
    }              
        
    render() {
        return(
            <Fragment>
                {this.showComponent()}
            </Fragment> 
        )
    }
}

export default PagarNaEntrega;