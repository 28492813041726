import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import Content from '../layout/content/Content';
import FavoritoContent from './FavoritoContent';
import Config from '../../Config';

class Favorito extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: true,
            erro: '',                                    
            breadcrumb: []
        }
    }       

    componentDidMount() {        
        this.props.onTitulo('Favoritos');

        let breadcrumb = [];                

        breadcrumb.push(this.addMenu('', 'Início', 'inicio', '', Config.urlSite));
        breadcrumb.push(this.addMenu('', 'Favorito', 'favorito', '', undefined));

        this.setState({breadcrumb: breadcrumb});
    }        

    render() {
        return (
            <Fragment>                
                <Content
                    preload={this.state.preload}

                    showSummary={false}
                    showHeader={false}
                    breadcrumb={this.state.breadcrumb}                    

                    modalErro={this.state.erro}

                    onModalErroClick={() => this.setState({ erro: '' })}                    

                    component={(props) => {
                        return <FavoritoContent
                            {...props}

                            carrinho={this.props.carrinho}

                            route={(item) => this.props.route(item)}                        

                            showPreload={(value) => this.setState({preload: value})}                            
                            onUpdateCart={(carrinho, indiceItem, indiceCarrinhoItem, quantidade) => this.props.onUpdateCart(carrinho, indiceItem, indiceCarrinhoItem, quantidade)}
                            onUpdateBadgeFavorito={(value) => this.props.onUpdateBadgeFavorito(value)}
                        />
                    }}
                />
            </Fragment>
        )
    }
}

export default Favorito;