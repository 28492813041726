import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import ReactFacebookLogin from 'react-facebook-login';

import { buscaConfiguracao } from '../../../helper/Validar';

class ButtonFacebook extends AbstractComponent {      
    onFailure() {
        if(this.props.onFailure === undefined)
            return ;

        this.props.onFailure();
    }         

    shouldComponent() {
        if(this.props.empresa.configuracao.length === 0) {
            return (                
                <span style={{transition: 'opacity 0.5s ease 0s'}}>                        
                    <button 
                        type="button" 
                        className="kep-login-facebook medium" 
                        style={{
                            borderRadius: '35px',
                            width: '45px',
                            height: '45px',
                            padding: '13px 21px',
                            backgroundColor: '#4c69ba'
                        }}
                    >                                                        
                        <i 
                            className="fa fa-facebook-customize fab fa-2x fa-facebook-square" 
                            style={{
                                marginLeft: '-11px',
                                marginTop: '-3px'
                            }}
                        />
                    </button>                                            
                </span>                
            );
        }

        return (
            <ReactFacebookLogin
                appId={buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'ID_Facebook', '')}
                autoLoad={this.props.autoLoad}
                size="medium"
                buttonStyle={{ 
                    borderRadius: "35px", 
                    width: '45px', 
                    height: '45px',
                    padding: '13px 21px',                        
                }}
                textButton={""}                    
                fields="name,email,picture"                    
                callback={(e) => this.props.onSuccess(e)}
                onFailure={() => this.onFailure()}
                icon="fa-facebook-customize fab fa-2x fa-facebook-square" 
            /> 
        );
    }

    render() {
        return (
            <>
                {this.shouldComponent()}               
            </>
        )
    }
}

ButtonFacebook.defaultProps = {
    readOnly: false,
    autoLoad: true
};

export default ButtonFacebook;