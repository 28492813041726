import React from 'react';
import { moeda, formataQuantidadeFloat } from "../Formatar";

var TableTypeEnum = {
    TEXT: {
        enumName: 'TEXT',        
        format: function(value) {
            return value;
        }
    },
    MOEDA: {
        enumName: 'MOEDA',        
        format: function(value) {
            return moeda(value);
        }
    },
    NUMBER: {
        enumName: 'NUMBER',        
        format: function(value, scale, isInteger) {
            if(isInteger === undefined)
                return formataQuantidadeFloat(value, scale);

            if(Number.isInteger(value))
                return value;

            return formataQuantidadeFloat(value, scale);
        }
    },
    CHECK: {
        enumName: 'CHECK',        
        format: (value) => { 
            if(!value)
                return ;
                
            return (
                <i className="fa fa-check" aria-hidden="true"></i>
            )
        }        
    }, 
    CHECKBOX: {
        enumName: 'CHECKBOX',        
        format: (value) => { 
            return value;
        }
    },
    get(value) {              
        if(value === null)
            return TableTypeEnum.TEXT;

        var array = Object.values(TableTypeEnum);
        var tableTypeEnumFound = {};

        array.forEach(element => {            
            if(element.enumName === value) {
                tableTypeEnumFound = element;      
                return ;          
            }            
        });   
        
        return tableTypeEnumFound;
    }
}

export default TableTypeEnum;