import React from 'react';
import AbstractComponent from '../../../AbstractComponent';
import Config from '../../../../Config';
import { Item } from 'react-bootstrap/lib/Breadcrumb';

class ButtonModalidadePagamento extends AbstractComponent { 
    showImage(tipo, imagem) {
        if(imagem.indexOf('.png') === -1) {
            return (
                <i className={imagem} style={{fontSize: '18px', color: (tipo ===  'Credito' ? 'blue' : 'red')}} />
            );            
        }

        return (
            <img 
                id={this.props.id}
                className={this.props.className}
                src={Config.urlImgBase + imagem}
                alt={this.props.alt}
                style={{ 
                    width: '24px', 
                    height: 'unset',                                                                                                               
                }}                 
            />
        );
    }   

    showButtons() {
        return (
            this.props.grupo.dados.map((dados, indexDados) => {
                return (   
                    <div 
                        key={indexDados}                        
                        className="col-md-4
                                col-sm-4
                                col-lg-4
                                col-xs-12" 
                        style={{
                            marginTop: '15px',
                            paddingRight: '5px', 
                            paddingLeft: '5px',
                            height: '58px',  
                        }}                           
                    >
                        <button 
                            type="button" 
                            className="button-modalidades-pagamento"
                            onClick={() => this.props.onClick(dados.id, dados.descricao, dados.troco === 1, dados.permite_parcelamento)}                                     
                        >        
                            {this.showImage(dados.tipo, dados.imagem)}
                            
                            &nbsp;&nbsp;

                            <span>
                                {dados.descricao}
                            </span>                                                
                        </button>
                    </div>  
                )
            })
        );
    }

    showComponent() {
        switch(this.props.showGrupo) {
            case 1:        
                return (
                    <div id="modalidadePagamento" className={"col-md-" + this.props.md + " col-xs-" + this.props.xs + " col-sm-" + this.props.sm + " col-lg-" + this.props.lg}>                                
                        <div 
                            className="row" 
                            style={{
                                padding: '0px 15px', 
                                backgroundColor: '#e6e4e6'
                            }}
                        >
                            <strong>
                                {this.props.grupo.grupo}
                            </strong>
                        </div>                
                                    
                        <div className="row">
                            {this.showButtons()}
                        </div>                
                    </div>
                );

                break;
            
            case 2: 
                return (
                    <div id="modalidadePagamento" className={"col-md-" + this.props.md + " col-xs-" + this.props.xs + " col-sm-" + this.props.sm + " col-lg-" + this.props.lg}>                                
                        <div style={{ marginTop: '-10px' }}>                        

                        </div>               
                                    
                        <div className="row">
                            {this.showButtons()}
                        </div>                
                    </div>
                );

                break;

            case 3: 
                return this.showButtons();                

                break;
        }
    }

    render() {
        return (
            <>
                {this.showComponent()}  
            </>
        );
    }
}

ButtonModalidadePagamento.defaultButton = {
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12,
    showGrupo: 1
}

export default ButtonModalidadePagamento;