import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import ContentMini from '../../layout/content/ContentMini';
import NotificacaoContent  from './NotificacaoContent';

class Notificacao extends AbstractComponent {
    constructor(props) {
        super(props);        

        this.state = {  
            preload: false,             
            erro: ''
        }        
    }                   

    componentDidMount() {                        
        this.onTitulo("Notificação");                                                
    } 
    
    render() {
        return (
            <Fragment>
                <ContentMini 
                    preload={this.state.preload}                               
                    modalErro={this.state.erro}                                                  

                    onCloseAlerta={() => this.setState({erro: ''})}
                    
                    component={(props) => { 
                        return (
                            <NotificacaoContent 
                                {...props}                                                                                                                                                              
                                
                                route={(item) => this.props.route(item)}  
                                
                                showPreload={(value) => this.setState({preload: value})}
                                onErro={(erro) => this.setState({erro: erro})}
                            />
                        )
                    }}
                />
            </Fragment> 
        )
    }
}

export default Notificacao;