export function changeQuantidade(quantidade) {                
    if((quantidade === "")||(quantidade === "-")||(quantidade === "+")) 
        return quantidade;    
    
    quantidade = parseInt(quantidade, 10);        

    if(quantidade === 0)             
        return 0;                            
        
    return quantidade;    
}

export function clickQuantidade(value) {      
    const quantidade = parseInt(value, 10);

    if(quantidade === 0)             
        return ;                                            

    return quantidade;
}

export function calculaTotal(venda, quantidade) {        
    var quant = Number.isInteger(quantidade) ? quantidade : 1;

    return (venda * quant);
} 

export function buscaItemVitrine(vitrine, uuid) {    
    if(vitrine.length === 0)
        return -1;           

    let index = 0;
    let resp = false;

    do {                
        if(vitrine[index].uuid === uuid) resp = true;
        else index++;        
    } while((!resp)&&(index < vitrine.length));

    return resp ? index : -1;
}

export function buscaItemCarrinho(carrinhoItem, id) {    
    if(carrinhoItem.length === 0)
        return -1;           

    let index = 0;
    let resp = false;

    do {                
        if(carrinhoItem[index].id === id) resp = true;
        else index++;        
    } while((!resp)&&(index < carrinhoItem.length));

    return index;
}