import Config from "../Config";
import Requisicao from "./RequisicaoService";

class ConfiguracaoService {       
    static async inicializa() {
        return Requisicao.post(Config.urlBaseApi + 'configuracao', {
            
        });
    } 

    static async gravar(uuidAparelho, documentoCliente, configuracao) {
        return Requisicao.post(Config.urlBaseApi + 'configuracao/gravar', {
            uuidAparelho: uuidAparelho, 
            documentoCliente: documentoCliente, 
            configuracao: configuracao
        });
    } 

    static async getAll(uuidAparelho) {                                
        return Requisicao.get(Config.urlBaseApi + 'configuracao/' + uuidAparelho);
    } 

    static async notificacao(uuidAparelho) {                        
        return Requisicao.get(Config.urlBaseApi + 'configuracao/notificacao/' + uuidAparelho);
    }           
}

export default ConfiguracaoService;