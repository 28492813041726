import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

class PreLoadCarrinho extends AbstractComponent {       
    visible() {
        return this.props.exibir === false 
            ? 'display-none'
            : ''
    }

    render() {
        return(            
            <div className={this.visible()} style={{padding: '80px 0'}}>
                <div className="area-cart__empty">
                    <div className="animated-svg">
                        <span className="area-card-empty-icon">
                            <img src={Config.urlImgBase + "preload_botao.gif"} alt="Loading..." width='35px' height='35px'/> 
                        </span>
                    </div>
                    
                    <span className="area-cart__empty--large">
                        
                    </span>
                    
                    <span className="area-cart__empty--small">
                        
                    </span>
                </div>
            </div>
        )
    }
}

PreLoadCarrinho.defaultProps = {
    exibir: false
}

export default PreLoadCarrinho;