import React from 'react';
import AbstractComponent from '../../../AbstractComponent';
import InputSearchProduto from '../../../produto/InputSearchProduto';
import InputEndereco from '../../input/InputEndereco';
import MenuTop from '../../menu/desktop/MenuTop';
import LogoTipo from './LogoTipo';

import NavMenuDesktop from '../menu/NavMenuDesktop';

class NavContentDesktop extends AbstractComponent {    
    onClearItems() {        
        var input = document.getElementById("buscaProduto");

        if(input === undefined)
            return ; 

        input.value = '';        
    }

    onItemClick(value) {
        var input = document.getElementById("buscaProduto");

        if(input === undefined)
            return ; 

        input.value = value;
    }

    render() {
        return(    
            <div className="desktop" style={{padding: '10px 20px'}}>
                <div className="row">
                    <LogoTipo
                        md={1} 
                        xs={1} 
                        sm={1} 
                        lg={1}                     
                        route={(item) => this.props.route(item)}
                    />
                    
                    <InputSearchProduto 
                        md={5} 
                        xs={5} 
                        sm={5} 
                        lg={5}                                                              
                        id="buscaProduto"
                        name="buscaProduto"  
                        vitrine={this.props.vitrine}  
                        search={() => this.props.search()}
                        onClearItems={() => this.onClearItems()} 
                        onItemClick={(value) => this.onItemClick(value)}                   
                    />
                    
                    <InputEndereco
                        md={4} 
                        xs={4} 
                        sm={4} 
                        lg={4}
                        inPreload={this.props.inPreload}
                        cliente={this.props.cliente}
                    />
                    
                    <MenuTop
                        md={2} 
                        xs={2} 
                        sm={2}                         
                        lg={2}                    
                        cliente={this.props.cliente}  
                        badge={this.props.badge}
                        carrinho={this.props.carrinho}                  
                        route={(item) => this.props.route(item)}
                    /> 
                </div>

                    <div className="navMenuDesktop">
                <div className="row" >
                    <NavMenuDesktop
                    
                        filtros={this.props.filtros}

                        route={(item) => this.props.route(item)}
                        onApplyFiltro={(filtros) => this.props.onApplyFiltro(filtros)}
                    />     
                </div>
                         </div>        
            </div>                                         
        )
    }
}

export default NavContentDesktop;