import React from "react";
import AbstractComponent from "../../AbstractComponent";
import Config from "../../../Config";

import "./Cupons.css";

class ResumoDiario extends AbstractComponent {
  render() {
    return (
      <>
        {this.props.resumo.map((item, a) => (
          <tr key={a}>
            <td>
              <div
                className="col-md-3
                                               col-sm-3 
                                               col-lg-3 
                                               col-xs-3"
              >
                <p className="h4 text-body">{item.dataa}</p>
              </div>
            </td>

            <td>
              <div
                className="col-md-3 
                                               col-sm-3 
                                               col-lg-3 
                                               col-xs-3"
                style={{
                  padding: "2px",
                  height: "100%",
                }}
              >
                <label className="h3 text-dark">
                  {parseFloat(item.total).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </label>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  }
}
export default ResumoDiario;
