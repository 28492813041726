import React from 'react';
import AbstractComponent from '../AbstractComponent';
import OpcaoEntregaEndereco from '../shopping/fechamento/opcao-entrega/OpcaoEntregaEndereco';
import Content from '../layout/content/Content';
import ModalEndereco from './ModalEndereco';

import AutenticarService from '../../service/AutenticarService';
import ClienteService from '../../service/ClienteService';

class EnderecoLista extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            modalQuestion: false,

            modalEndereco: false,

            cliente: {
                id: 0,
                cpf: '',
                nome: '',
                telefone: '',
                dataNascimento: '',
                cep: '',
                logradouro: '',
                endereco: "",
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                uf: '',
                codigo_municipio: '',
                email: '',
                frete: {}
            },

            form: {},

            modalidadePagamento: {

            },

            passo: 0,

            novo: false,

            erro: '',

            preload: false
        }
    }

    onChangeStep(passo) {
        this.setState({ passo: passo });
    }

    onUpdateEnderecoEntrega(endereco, clienteEndereco) {
        console.log('passo 2');
        var cliente = this.state.cliente;
        if ((clienteEndereco !== undefined) && (endereco !== null))
            cliente.cliente_endereco = clienteEndereco;

        if(endereco !== undefined) {
            cliente.destinatario = endereco.destinatario;
            cliente.cep = endereco.endereco.cep;
            cliente.logradouro = endereco.endereco.logradouro;
            cliente.endereco = endereco.endereco.endereco;
            cliente.numero = endereco.endereco.numero;
            cliente.complemento = endereco.endereco.complemento;
            cliente.bairro = endereco.endereco.bairro;
            cliente.cidade = endereco.endereco.cidade;
            cliente.uf = endereco.endereco.uf;
            cliente.codigo_municipio = endereco.endereco.codigo_municipio;
        }

        this.setState({ cliente: cliente });
    }

    showModal() {
        if(!this.state.modalEndereco)
            return ;

        return (

            <ModalEndereco
            cidadesDisponiveis = {this.props.cidadesDisponiveis}
            show={true}
            title={this.state.novo ? "NOVO ENDEREÇO" : "ALTERAR ENDEREÇO" }
            form={this.state.form}
            empresa={this.props.empresa}

            fechar={() => {this.setState({modalEndereco: false}); this.onTitulo("LISTA DE ENDEREÇOS");}}
            onUpdateEnderecoEntrega={(endereco, clienteEndereco) => { this.onUpdateEnderecoEntrega(endereco, clienteEndereco); this.onTitulo("LISTA DE ENDEREÇOS");} }
            />
        );
    }

    componentDidMount() {
        this.onTitulo("LISTA DE ENDEREÇOS");

        if(!this.isLogged())
            return this.login2();

        this.setState({preload: true});

        ClienteService.cliente(            
            AutenticarService.getDocumentoCliente()
        ).then(response => {
            let cliente = this.state.cliente;

            cliente.email = response.data.usuario.email;

            cliente.id = response.data.cliente.id;

            if (!cliente.endereco) {
                cliente.destinatario = response.data.cliente.destinatario;
                cliente.cep = response.data.cliente.endereco.cep;
                cliente.logradouro = response.data.cliente.endereco.logradouro;
                cliente.endereco = response.data.cliente.endereco.endereco;
                cliente.numero = response.data.cliente.endereco.numero;
                cliente.complemento = response.data.cliente.endereco.complemento;
                cliente.bairro = response.data.cliente.endereco.bairro;
                cliente.cidade = response.data.cliente.endereco.cidade;
                cliente.uf = response.data.cliente.endereco.uf;
                cliente.codigo_municipio = response.data.cliente.endereco.codigo_municipio;
                cliente.tipo = 'Casa';                
                cliente.tipo_endereco = response.data.cliente.tipo_endereco;
                cliente.ponto_de_interesse = response.data.cliente.ponto_de_interesse;
                cliente.ponto_de_interesse_manual = response.data.cliente.ponto_de_interesse_manual;
                cliente.ponto_de_interesse_descricao = response.data.cliente.ponto_de_interesse_descricao;

                if(cliente.ponto_de_interesse === '')
                    cliente.ponto_de_interesse_manual = 'Sim';
            }

            cliente.cliente_endereco = response.data.cliente.cliente_endereco;

            this.setState({
                cliente: cliente,
                preload: false
            });
        }).catch((e) => {
            this.setState({
                preload: false,
                erro: this.P_ERRO_PADRAO()
            });
        });
    }    
    rediEndereco(caminho){
        
    }
    render() {
        return (
            
            <div className="__lista-endereco">
                {this.showModal()}

                <Content
                    className={"__lista-endereco"}
                    preload={this.state.preload}
                    showSummary={false}

                    modalErro={this.state.erro}

                    onCloseAlerta={() => this.setState({ erro: '' })}

                    component={(props) => {
                        return <OpcaoEntregaEndereco
                            {...props}
                            cidadesDisponiveis = {this.props.cidadesDisponiveis}
                            className={"__lista-endereco"}                            
                            cliente={this.state.cliente}     
                            empresa={this.props.empresa}
                            escolhaEntrega={false}
                            rediEndereco={(caminho) =>this.rediEndereco(caminho)}
                            onTitulo={(titulo) => this.props.onTitulo(titulo)}
                            onNew={(value, form) => this.setState({novo: value, modalEndereco: true, form: form})}

                            showPreload={(value) => this.setState({ preload: value })}
                            showErro={(value) => this.setState({ erro: value })}
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.onUpdateEnderecoEntrega(endereco, clienteEndereco)}

                            route={item => this.browserRoute.history.push(item.link)}
                        />
                    }}

                />
            </div>
        )
    }
}

export default EnderecoLista;