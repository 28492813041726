import React from 'react';
import AbstractComponent from '../AbstractComponent';
import ContentMini from '../layout/content/ContentMini';
import EsqueciMeuEmailContent from './EsqueciMeuEmailContent';

class EsqueciMeuEmail extends AbstractComponent {  
    constructor(props) {
        super(props);

        this.state = {
            preload: false,

            erro: ''            
        }
    }  

    componentDidMount() {     
        this.onTitulo("Esqueci Meu E-mail");
    }

    render() {
        return (   
            <ContentMini
                className="login"

                preload={this.state.preload}                               
                modalErro={this.state.erro} 
                modalSucesso={this.state.sucesso}                                                 

                onCloseAlerta={() => this.setState({erro: ''})}
                onCloseSucesso={() => { this.setState({sucesso: ''}); this.homePage2() }}

                component={(props) => {                     
                    return (
                        <EsqueciMeuEmailContent
                            {...props}  
                        
                            showPreload={(value) => this.setState({preload: value})}                            
                            onErro={(erro) => this.setState({erro: erro})}      
                            route={(item) => this.props.route(item)}                      
                        />   
                    )
                }}                 
            />                    
        )
    }
}

export default EsqueciMeuEmail;