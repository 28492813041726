import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

import AutenticarService from '../../../service/AutenticarService';

import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';

import { getUrlBase } from '../../../helper/Url';

class VitrineEmpty extends AbstractComponent {
    constructor(props) {
        super(props);

        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    }

    render() {
        return (
            <div className="col-md-12
                            col-sm-12
                            col-lg-9
                            col-xs-12">
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-6">
                        <div className="text-center mt-4">                            
                            <img
                                className="mb-4 img-error"
                                alt="Produto não encontrado"
                                src={Config.urlImgBase + "product-not-found-" + this.shoppingBagType.enumName + ".png"}
                            />                            

                            <h5 className="lead">
                                <b>
                                    <span className="product-not-found">
                                        Ops! &nbsp;
                                    </span>

                                    {
                                        this.props.telaFavorito &&
                                        <Fragment>
                                            nenhum favorito encontrado &nbsp;

                                            <br></br>

                                            <span className="product-not-found">
                                            <a  style={{color: "unset", textDecoration: "unset"}}
                                                href={getUrlBase()} >Acesse a home </a>
                                            </span>
                                        </Fragment>
                                    }

                                    {
                                        this.props.searchKey !== '' &&
                                        <Fragment>
                                            nenhum resultado encontrado para &nbsp;

                                            <span className="product-not-found">
                                                "{this.props.searchKey}"
                                            </span>
                                        </Fragment>
                                    }

                                    {
                                        this.props.searchKey === '' &&
                                        <Fragment>
                                            Ainda não existem produtos na vitrine
                                        </Fragment>
                                    }
                                </b>
                            </h5>
                        </div>

                        {
                            this.props.searchKey !== '' &&
                            <div className="text-left mt-4 product-not-found__description">
                                <p>
                                    <b>O que eu faço?</b>
                                </p>

                                <div className="ml-5">
                                    <ul>
                                        <li>Verifique os termos digitados ou os filtros selecionados</li>
                                        <li>Utilize termos genéricos na busca</li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default VitrineEmpty;