import AmbienteEnum from '../helper/enumerador/AmbienteEnum';

import { soNum } from './Formatar';

export const CARTAO_CREDITO_HOMOLOGACAO = new Array(
    {valor: '0000000000000001', texto: 'Sucesso (Cod. 1)'}, 
    {valor: '0000000000000004', texto: 'Sucesso (Cod. 4)'}, 
    {valor: '0000000000000002', texto: 'Nao autorizado'},
    {valor: '0000000000000003', texto: 'Cartao expirado'},
    {valor: '0000000000000005', texto: 'Cartao bloqueado'},
    {valor: '0000000000000006', texto: 'Time out'},
    {valor: '0000000000000007', texto: 'Cartao cancelado'},
    {valor: '0000000000000008', texto: 'Problemas CC'},
    {valor: '0000000000000009', texto: 'Sucesso / Timeout (Cod.9)'}
);

export function validaData(value) {
    var date = value;
    var ardt = [];
    var ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    
    if(!date)
        return false;
        
    ardt = date.split("/");    
    
    if(date.search(ExpReg) === -1) 
        return false;                

    if(((ardt[1] === 4)||(ardt[1] === 6)||(ardt[1] === 9)||(ardt[1] === 11))&&(ardt[0] > 30)) 
        return false;
            
    if(ardt[1] === 2) {
        if ((ardt[0] > 28)&&((ardt[2]%4) !== 0)) 
            return false;

        if ((ardt[0]>29)&&((ardt[2]%4)===0))
            return false;
    }
            
    return true;
} 

export function temTexto(logradouro, endereco) {                
    let txtEndereco = endereco;            
    var enderecos = txtEndereco.split(' ');                  

    if(enderecos[0] === logradouro)
        return true;

    return false;
} 

export function isNumero(input) {                        
    input = input || window.event;

    var key = input.keyCode || input.which;                        

    if(key === 13)
        return true;

    if((key < 48)||(key > 57))             
        return false;        

    return true;
}

export function validarEmail(value) {
    const usuario = value.substring(0, value.indexOf("@"));
    const dominio = value.substring(value.indexOf("@")+ 1, value.length);
     
    if ((usuario.length >= 1) &&
        (dominio.length >= 3) && 
        (usuario.search("@") === -1) && 
        (dominio.search("@") === -1) &&
        (usuario.search(" ") === -1) && 
        (dominio.search(" ") === -1) &&
        (dominio.search(".") !== -1) &&      
        (dominio.indexOf(".") >= 1)&& 
        (dominio.lastIndexOf(".") < dominio.length - 1)) 
        return true;    
    
    return false;
}    

export function validarCpf(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '' ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999") {
        return false;
    }

    let add = 0;
    let i = 0;
    let rev = 0;

    for (i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i), 10) * (10 - i);

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11)
        rev = 0;

    if (rev !== parseInt(cpf.charAt(9), 10))
        return false;

    add = 0;

    for (i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i), 10) * (11 - i);

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11)
        rev = 0;

    if (rev !== parseInt(cpf.charAt(10), 10))
        return false;

    return true;
}

export function buscaConfiguracao(configuracao, grupo, chave, valorPadrao) {
    try {
        return configuracao[grupo][chave];
    } catch(e) {
        return valorPadrao;
    }
}

export function getCartaoCreditoHomologacaoIndex(numero) {
    var resp = -1;

    CARTAO_CREDITO_HOMOLOGACAO.forEach((element, index) => {
        if(element.valor === numero) {
            resp = index;
            return ;
        }
    });

    return resp;
}

export function validarNumeroCartaoCredito(ambiente, numero) { 
    numero = soNum(numero);

    if(ambiente === AmbienteEnum.DEVELOPMENT) {
        if(getCartaoCreditoHomologacaoIndex(numero) > -1) {
            return true;
        }
    }

	if ((numero.length > 16)||(numero[0] === 0)){		
		return false;
	} 
    
    var total = 0;
    var arr = Array();
    
    for(var i = 0; i < numero.length; i++){
        if ((i % 2) === 0) {
            const dig = numero[i] * 2;
                
            if (dig > 9) {
                const dig1 = dig.toString().substr(0,1);
                const dig2 = dig.toString().substr(1,1);
                arr[i] = parseInt(dig1, 10) + parseInt(dig2, 10);
            } else {
                arr[i] = parseInt(dig, 10);
            }
                        
            total += parseInt(arr[i]);	
        } else {	
            arr[i] = parseInt(numero[i], 10);
            total += parseInt(arr[i], 10);
        } 
    }
            
    if ((parseInt(numero[0], 10) < 3) || (parseInt(numero[0], 10) > 5)) {
        return false;
    }			    
	
    if(total%10 !== 0){
        return false;
    } 
    
    return true;
}

export function validarMesAno(data) {    
    if(data.length !== 5) {
        return false;
    }

    let array = data.split('/');

    let mes = parseInt(array[0], 10);
    let ano = parseInt(array[1], 10);    

    if ((mes < 1) || (mes > 12)) {
        return false;
    }                
    
    let hoje = new Date(); 
    let hojeY = hoje.getFullYear();   
    let hojeM = hoje.getMonth() + 1;   

    ano = parseInt(hojeY.toString().substring(0, 2) + ano.toString(), 10);
    
    if (ano < hojeY) {
        return false;
    }

    if ((ano === hojeY)&&(mes < hojeM)) {
        return false;
    }

    return true;
}