
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class ProdutoMarcaService {    
    static async getAll(pagina, limite, searchKey) {
        return Requisicao.post(Config.urlBaseApi + 'produto/marca', {            
            pagina: pagina,
            limite: limite,
            searchKey: searchKey
        });
    }   

    static async autoComplete(search) {        
        return Requisicao.get(Config.urlBaseApi + 'produto/marca/autocomplete/' + search);
    }
}

export default ProdutoMarcaService;