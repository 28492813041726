import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import CarrinhoItem from './CarrinhoItem';
import CarrinhoEmpty from './CarrinhoEmpty';
import PreLoadCarrinho from '../../layout/preload/PreLoadCarrinho';

class Carrinho extends AbstractComponent {        
    render() {
        return(
            <div 
                id="sideShoppingBag"
                className="order-column order-column--default"
            >                                             
                <div className={"area-cart" + ((this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length) === 0 ? '-empty' : '')}>
                    <PreLoadCarrinho
                        exibir={this.props.preload}
                    />

                    <CarrinhoItem
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}
                        visible={!this.props.preload && (this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length) > 0}                                                                    
                        cliente={this.props.cliente}
                        carrinho={this.props.carrinho}  
                        pagamento={this.props.pagamento}  
                        empresa={this.props.empresa}                                                
                        opcaoEntrega={this.props.opcaoEntrega}
                        modalidadePagamento={this.props.modalidadePagamento}
                        
                        onUpdateCart={(carrinho, item, indiceCarrinhoItem, quantidade) => this.props.onUpdateCart(carrinho, item, indiceCarrinhoItem, quantidade)}
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}                       
                        onUpdatePagamento={(id, descricao, troco, opcaoPagamento, permite_parcelamento) => this.props.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento)}
                        
                        refreshCart={() => this.props.refreshCart()}
                        
                        route={(item) => this.props.route(item)}
                    />
                    
                    <CarrinhoEmpty                                
                        visible={!this.props.preload && (this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length) === 0}
                    />
                </div>                                                       
            </div>
        )
    }
}

Carrinho.defaultProps = {
    preload: false
}

export default Carrinho;