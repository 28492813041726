import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { trataErro } from '../../helper/Messageria';

import ClienteAparelhoService from '../../service/ClienteAparelhoService';
import AutenticarService from '../../service/AutenticarService';

var firebaseConfig = {
    apiKey: "AIzaSyB-e_NromeEi5w5U_yC3dOO3BgBKJ-comQ",
    authDomain: "avance-appshop.firebaseapp.com",
    databaseURL: "https://avance-appshop.firebaseio.com",
    projectId: "avance-appshop",
    storageBucket: "avance-appshop.appspot.com",
    messagingSenderId: "1065042674786",
    appId: "1:1065042674786:web:213518fe0e2f1338743d3b",
    measurementId: "G-8YBQM1P2YS"
};

firebase.initializeApp(firebaseConfig);

const messaging = navigator.platform === 'iPad' 
                    ? null : navigator.platform === 'iPhone' 
                    ? null : navigator.platform === 'iOS'
                    ? null : firebase.messaging();

export const getToken = (setError) => {     
    AutenticarService.setNotificacaoOk(1);    
    
    if(messaging === null) {
        return; 
    }    
    
    return messaging.getToken({                
        vapidKey: 'BHYnCnt8I4TZj2BOHh4JxLgznKE_068066LcmZg9GREJkDtScuDqEjsdFTJiAr38WCpNtard5IR4ZKYKUnun2q8'
    }).then((currentToken) => { 
        console.log('token ok');

        if (currentToken) {                                             
            ClienteAparelhoService.incluir(            
                AutenticarService.getUuidAparelho(),
                AutenticarService.getDocumentoCliente(),
                currentToken            
            ).then((response) => {                
                AutenticarService.setUuidAparelho(response.data),
                console.log('Incluiu aparelho ok');                          
            }).catch(erro => { 
                console.error('Erro ' + erro);

                if(setError !== undefined) {                              
                    trataErro(erro, setError);
                }
            });            
        } else {
            console.error('Não gerou o token');

            if(setError !== undefined) {
                setError('Nenhum token disponível para registrar. Solicite permissão para gerar um');
            }            
        }       
    }).catch(error => {
        console.error('Erro ao gerar token = ' + error);

        if(setError !== undefined) {
            setError('Erro ao gerar Token = ' + error);
        }
    });
}

export const onMessageListener = () => 
    new Promise((resolve) => {        
        if(messaging === null) {
            return ;
        }

        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });