import React from 'react';
import AbstractComponent from '../AbstractComponent';
import InfiniteScrollTable from '../layout/infinitescroll/InfiniteScrollTable';
import FiltrosSelecionados from '../layout/pesquisa/FiltrosSelecionados';
import Button from '../layout/button/Button';

import TableTypeEnum from '../../helper/enumerador/TableTypeEnum';

class CategoriaMobileModalContent extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {                                    
            selecionados: []
        }

        this.head = [
            {nome: 'DESCRIÇÃO', campo: 'descricao', type: TableTypeEnum.TEXT, width: '92%'}
        ]

        if(this.props.filtros.length > 0)
            this.state.selecionados = this.props.filtros;
    }                                   

    remove(item) {
        let selecionados = this.state.selecionados;                

        const index = selecionados.indexOf(item);        

        if(index > -1)
            selecionados.splice(index, 1);

        this.setState({selecionados: selecionados}); 
    }

    onInfiniteScrollCallback(item) {    
        if(this.props.onCallback !== undefined)
            this.props.onCallback(item.descricao);

        const chosen = item;
        let selecionados = this.state.selecionados;        

        chosen.marcado = chosen.marcado === 1 ? 0 : 1;
        
        if(chosen.marcado === 0) {                        
            this.remove(item);

            return ;
        }

        const index = selecionados.indexOf(item.descricao);

        if(index > -1)
            return ;

        selecionados.push(item.descricao);

        this.setState({selecionados: selecionados});
    }

    render() {
        return (
            <div style={{backgroundColor: '#ffffff', height: '80vh'}}>                
                <FiltrosSelecionados
                    md={12} 
                    xs={12} 
                    sm={12} 
                    lg={12}
                    filtro={this.state.selecionados}

                    onRemove={(item) => this.remove(item)}
                    clearFiltro={() => this.setState({selecionados: []})}
                />

                <InfiniteScrollTable
                    md={12} 
                    xs={12} 
                    sm={12} 
                    lg={12}                                      

                    className={"fixed-scroll-bottom"}
                    style={{
                        marginTop: '0px',
                        padding: '0px',                        
                    }}

                    scroll='var(--height-fixed-scroll-bottom)'

                    head={this.head}
                    body={this.props.body}  

                    cadastro={false}                    
                    clickCallback={true}  
                    
                    preloadFetch={this.props.preloadFetch}

                    onCallback={(item) => this.onInfiniteScrollCallback(item)}
                    fetchMoreData={() => this.props.fetchMoreData()} 
                />                          

                <div 
                    className="col-md-12
                               col-sm-12
                               col-lg-12
                               col-xs-12"                
                    style={{
                        bottom: '0px',                         
                        padding: '0px',                        
                        backgroundColor: '#ffffff'
                    }}
                >
                    <Button
                        type="button"                        
                        className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"  
                        onClick={() => this.props.onApply(this.state.selecionados)}  
                                                                 
                    >                    
                        APLICAR/FECHAR
                    </Button>                    
                </div>
            </div>
        )
    }
}

export default CategoriaMobileModalContent;