import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';
import ModalTitle from '../layout/modal/ModalTitle';

import { buscaConfiguracao } from '../../helper/Validar';

class ShoppingSuccess extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            textoButton: 'Novo Pedido'            
        }        
    }    

    onClick() {
        this.setState({textoButton: 'Aguarde...'});

        this.props.fechar();
        this.props.route(this.homePage());
    }

    getMensagem() {
        if(this.props.empresa.configuracao.length === 0)
            return '';            

        return buscaConfiguracao(this.props.empresa.configuracao, 'Pedido', 'Mensagem_ao_finalizar_pedido', '');
    }

    render() {
        return (
            <Modal show={this.props.show}
                   className={this.props.className}
                   style={{zIndex: 9999}}
                   onHide={() => this.props.fechar()}>
                <ModalTitle
                    icon="none"
                    escondeLupa={false}
                    header='PEDIDO REALIZADO COM SUCESSO'                    
                />                 

                <Modal.Body>
                    <div className="row">
                        <div className='col-md-12 text-center'>                            
                            <h1 className="h1">
                                <strong>
                                    Pedido
                                    <br/>
                                    #{this.props.idPedido}
                                </strong>
                            </h1>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '20px'}}>
                        <div className='col-md-12 text-center'>                            
                            <label style={{fontSize: '30px'}}>
                                {this.getMensagem()}
                            </label>
                        </div>
                    </div>                        

                    <div className="row" style={{marginTop: '20px'}}>
                        <div className="col-md-12 text-center">
                            <button type="button"
                                    className="btn btn--default btn--size-m btn--full-width area-cart-footer__button btn btn--default btn--size-m"
                                    onClick={() => this.onClick()}>
                                <span className="text-size-voltar-area-restrita">{this.state.textoButton}</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ShoppingSuccess;