import React from "react";
import { Modal } from "react-bootstrap";
import AbstractComponent from "../../AbstractComponent";
import ModalTitle from "../../layout/modal/ModalTitle";
import Alerta from "../../layout/alert/Alerta";
import MyCarousel from "../../layout/carousel/MyCarousel";
import InputSpin from "../../layout/input/InputSpin";
import InputLabelMoedaDetail from "../../layout/input/InputLabelMoedaDetail";
import AddCartButton from "./AddCartButton";
import ButtonsProduto from "../ButtonsProdutos";
import ShowMoreText from "react-show-more-text";

import TipoCalculoEnum from "../../../helper/enumerador/TipoCalculoEnum";

import "../../layout/modal/Modal.css";

class VitrineModalDetail extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      quantidade: 0,

      quantidadeNoCarrinho: 0,

      alerta: "",

      className: "",
    };

    this.tipoCalculoType = TipoCalculoEnum.get(this.props.item.tipo_calculo);

    this.state.quantidade = this.tipoCalculoType.show(
      this.props.item.qtde_minima
    );

    if (props.update === true) {
      this.state.quantidade = this.tipoCalculoType.show(props.quantidade);

      if (this.props.update) this.state.quantidadeNoCarrinho = props.quantidade;
    }
  }

  onClickQuantidade(value) {
    const quantidade = value;

    if (quantidade === 0) return;

    this.setState({
      quantidade: this.tipoCalculoType.show(quantidade),
    });

    this.calculaTotal();
  }

  onChangeQuantidade(input) {
    let quantidade = input.target.value;

    if (quantidade === "") {
      this.setState({
        quantidade: quantidade,
      });

      return;
    }

    if (quantidade === "-" || quantidade === "+") {
      this.setState({
        quantidade: quantidade,
      });

      return;
    }

    if (quantidade === 0) return;

    this.setState({
      quantidade: this.tipoCalculoType.show(quantidade),
    });

    this.calculaTotal();
  }

  getValor() {
    return this.props.item.promocao === 0
      ? this.props.item.venda
      : this.props.item.promocao;
  }

  calculaTotal() {
    var venda = this.getValor();
    var quantidade = this.tipoCalculoType.getValue(this.state.quantidade);

    return venda * quantidade;
  }

  galeria() {
    if (this.props.item.galeria_imagem === undefined) return [];

    return this.props.item.galeria_imagem;
  }
  executeOnClick(isExpanded) {

  }

  render() {
    return (
      <Modal
        show={this.props.show}
        className={this.props.className}
        style={{ zIndex: 9999, overflow: "auto"}}
        onHide={() => this.props.fechar()}
      >
        <ModalTitle
          icon="down"
          header={this.props.header}
          fechar={() => this.props.fechar()}
        />

        <Alerta exibir={this.state.alerta !== ""} texto={this.state.alerta} />

        <Modal.Body
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight:"80vh",
          }}
        >
          <div
            className={"row " + this.props.className}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div
              className="col-md-12
                                        col-sm-12
                                        col-lg-12
                                        col-xs-12
                                        text-center
                                        my-carousel"
            >
              <MyCarousel
                className="carousel-size"
                galeria={this.galeria()}
                descricao={this.props.item.descricao}
                maxThumbs={3}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "15px" }}></div>

          <div className="product-content">
            <div
              className="row"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <div
                className="col-md-12
                                            col-sm-12
                                            col-lg-12
                                            col-xs-12"
              >
                <div className="product-content__title__description">
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more="Ver mais"
                    less="Ver menos"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    onClick={this.executeOnClick}
                    expanded={false}
                    width={window.innerWidth < 577 ? 255 : 420}
                    truncatedEndingComponent={"... "}
                  >
                    {this.props.item.descricao}
                  </ShowMoreText>
                </div>

                <div
                  className={
                    window.innerWidth < 577
                      ? (this.state.className = "product-content__titlemobile")
                      : (this.state.className = "product-content__description")
                  }
                >
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Ver mais"
                    less="Ver menos"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    onClick={this.executeOnClick}
                    expanded={false}
                    width={window.innerWidth < 577 ? 315 : 420}
                    truncatedEndingComponent={"... "}
                  >
                    {this.props.item.especificacao}
                  </ShowMoreText>
                </div>
              </div>

              <div
                className="col-md-8
                                            col-sm-8
                                            col-lg-8
                                            col-xs-8"
              >
                {/* <div className="product-content__container product-content__details">
                                    Mussarela, frango desfiado, tomate e salsa
                                </div> */}

                {/* <div className="product-content__container product-content__container-price">
                                    <span>
                                        <InputLabelMoeda
                                            symbol="R$"
                                            value={this.props.item.venda}
                                        >De:
                                        </InputLabelMoeda>
                                    </span>
                                </div> */}

                <InputLabelMoedaDetail
                  symbol="R$"
                  de={this.props.item.venda}
                  por={this.props.item.promocao}
                />
              </div>

              <ButtonsProduto
                md={4}
                xs={4}
                sm={4}
                lg={4}
                show={true}
                uuid={this.props.item.uuid}
                favorito={this.props.item.favorito}
                onUpdateVitrine={() =>
                  this.props.onUpdateVitrine(this.props.indice)
                }
                onUpdateBadgeFavorito={(tipo) =>
                  this.props.onUpdateBadgeFavorito(tipo, this.props.indice)
                }
                route={(item) => {
                  this.props.fechar();
                  this.props.route(item);
                }}
              />
            </div>

            <div className="row">
              <div className="product-tooltip">
                <InputSpin
                  md={4}
                  xs={4}
                  sm={12}
                  lg={4}
                  className="product-action__counter__label"
                  nome={"qtd"}
                  value={this.state.quantidade}
                  unidade={this.props.item.unidade}
                  defaultValue={this.state.quantidade}
                  minValue={this.props.item.qtde_minima}
                  incrementoValue={this.props.item.qtde_incremento}
                  //maxValue={this.props.estoque + this.state.quantidadeNoCarrinho}
                  maxValue={this.props.item.qtde_maxima}
                  erro={this.state.erroQtd}
                  textoComplMaxValueSingular={"disponível"}
                  textoComplMaxValuePlural={"disponíveis"}
                  tipoCalculoType={this.tipoCalculoType}
                  onChange={(input) => this.onChangeQuantidade(input)}
                  onMenosClick={(value) => this.onClickQuantidade(value)}
                  onMaisClick={(value) => this.onClickQuantidade(value)}
                />

                <AddCartButton
                  md={8}
                  xs={8}
                  sm={12}
                  lg={8}
                  quantidade={this.state.quantidade}
                  idCarrinhoItem={this.props.idCarrinhoItem}
                  value={this.calculaTotal()}
                  item={this.props.item}
                  carrinho={this.props.carrinho}
                  update={this.props.update}
                  onError={(erro) => this.setState({ alerta: erro })}
                  onSuccess={(carrinho, indiceCarrinhoItem, quantidade) =>
                    this.props.onUpdateCart(
                      carrinho,
                      indiceCarrinhoItem,
                      quantidade
                    )
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

VitrineModalDetail.defaultProps = {
  header: "",
  update: false,
  item: [],
  id: 0,
};

export default VitrineModalDetail;
