// eslint-disable-next-line 

import React from 'react';
import AbstractComponent from '../AbstractComponent';

class Offline extends AbstractComponent {               
    onClick() {        
        //eslint-disable-next-line
        return "javascript:history.go()"; 
    }

    render() {
        return(
            <div id="offline" className="offline-sistema display-none">
                <div className="row">   
                    <div className="col-md-1                                 
                                    col-sm-1                                                
                                    col-lg-1                                                
                                    col-xs-1">
                    </div>             

                    <div 
                        id="offline-txt" 
                        className="col-md-10                                 
                                   col-sm-10                                                
                                   col-lg-10                                                
                                   col-xs-10
                                   conteudo
                                   text-center
                                   disconnected">                        
                        Offline <div></div>                        
                    </div>

                    <div className="col-md-1                                 
                                    col-sm-1                                                
                                    col-lg-1                                                
                                    col-xs-1">
                    </div>             
                </div>

                <div className="row">
                    <div className="col-md-12 conteudo text-center">
                        <h1 className="h1 text-white"
                            style={{
                                marginBottom: '50px',
                                marginTop: '50px',
                                fontSize: '21px'
                            }}>
                            Não foi possível conectar a internet
                        </h1>
                        
                        <a className="btn btn-danger" onClick={() => this.onClick()}>
                            Atualizar
                        </a>                                                
                    </div>                                
                </div>
            </div>
        )
    }
}

export default Offline;