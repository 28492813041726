import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Form from '../../layout/form/Form';
import InputToggle from '../../layout/input/InputToggle';

import AutenticarService from '../../../service/AutenticarService';
import ConfiguracaoService from '../../../service/ConfiguracaoService';

class NotificacaoContent extends AbstractComponent {                    
    constructor(props) {
        super(props);

        this.state = {                         
            componentsCelular: [],

            componentsEmail: [],

            preloadCelular: -1,

            preloadEmail: false,
        }
    }               

    onCelularChange(e, i) {                        
        var components = this.state.componentsCelular;        
        
        components[i].valor = parseInt(e.target.value, 10);
        
        this.setState({preloadCelular: i});

        ConfiguracaoService.gravar(            
            AutenticarService.getUuidAparelho(),
            AutenticarService.getDocumentoCliente(),                         
            components[i]
        ).then(() => {            
            this.setState({
                componentsCelular: components,
                preloadCelular: -1
            });
        }).catch(erro => {
            this.setState({preloadCelular: -1});
            this.props.onErro(this.trataErro(erro));
        });         
    }        

    onEmailChange(e, i) {                        
        var components = this.state.componentsEmail;        
        
        components[i].valor = parseInt(e.target.value, 10);
                
        this.setState({preloadEmail: i});

        ConfiguracaoService.gravar(            
            AutenticarService.getUuidAparelho(),
            AutenticarService.getDocumentoCliente(),                         
            components[i]
        ).then(() => {            
            this.setState({
                componentsEmail: components,
                preloadEmail: -1
            });
        }).catch(erro => {
            this.setState({preloadEmail: -1});
            this.props.onErro(this.trataErro(erro));
        });                           
    }        

    componentDidMount() {
        this.props.showPreload(true);

        ConfiguracaoService.notificacao(            
            AutenticarService.getUuidAparelho()
        ).then(resposta => {            
            this.setState({
                componentsCelular: resposta.data.celular,
                componentsEmail: resposta.data.email
            }); 

            this.props.showPreload(false);                    
        }).catch(erro => {
            this.props.onErro(this.trataErro(erro));
            this.props.showPreload(false);
        });
    }

    render() {
        return(                                   
            <Form id="form" onSubmit={(e) => this.onSubmit(e)} className={"form " + (this.props.preload ? 'display-none' : '')}> 
                <div 
                    className="area-cart-content"
                    style={{overflowX: 'hidden'}}
                >
                    <div className="row">
                        <div 
                            className="col-md-12                                 
                                    col-sm-12                                                
                                    col-lg-12                                                
                                    col-xs-12
                                    border-bottom-padrao"
                            style={{height: '27px'}}
                        >
                            <strong>Receber notificações pelo celular</strong>
                        </div>                            
                    </div>
        
                    {
                        this.state.componentsCelular.map((componente, i) => {
                            return (
                                <div className="row" key={i}>
                                    <InputToggle
                                        md={12} 
                                        xs={12} 
                                        sm={12} 
                                        lg={12}
                                        id={i}
                                        nome={i}
                                        value={componente.valor}            
                                        preload={this.state.preloadCelular === i}                                     
                                        checked={parseInt(componente.valor,10) === 1}                        
                                        onChange={(e) => this.onCelularChange(e, i)}
                                    >
                                        {componente.chave}
                                    </InputToggle>
                                </div>
                            )
                        })
                    }  

                    <div className="row" style={{marginTop: '10px'}}>
                        <div 
                            className="col-md-12                                 
                                    col-sm-12                                                
                                    col-lg-12                                                
                                    col-xs-12
                                    border-bottom-padrao"
                            style={{height: '27px'}}
                        >
                            <strong>Receber notificações pelo e-mail</strong>
                        </div>                            
                    </div>
        
                    {
                        this.state.componentsEmail.map((componente, i) => {
                            return (
                                <div className="row" key={i}>
                                    <InputToggle
                                        md={12} 
                                        xs={12} 
                                        sm={12} 
                                        lg={12}
                                        id={i}
                                        nome={i}
                                        value={componente.valor}                                                 
                                        preload={this.state.preloadEmail === i}
                                        checked={parseInt(componente.valor,10) === 1}                        
                                        onChange={(e) => this.onEmailChange(e, i)}
                                    >
                                        {componente.chave}
                                    </InputToggle>
                                </div>
                            )
                        })
                    }
                </div>                                              
            </Form>            
        )
    }
}

NotificacaoContent.defaultProps = {
    preload: false
}

export default NotificacaoContent;