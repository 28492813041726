import React from 'react';
import AbstractComponent from '../AbstractComponent';
import InputSearch from '../layout/input/InputSearch';

import ProdutoService from '../../service/ProdutoService';

class InputSearchProduto extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {                        
            items: []          
        }        
    }       

    onChange(e) {
        if(this.props.onChange !== undefined)
            this.props.onChange(e);
    }

    onClearItems() {
        this.setState({items: []});
        this.props.onClearItems();
    }    

    autoComplete(e) {       
        const search = e.target.value;        
        
        if(search === '')
            return ;        

        ProdutoService.autoComplete(            
            search
        ).then(response => {              
            this.setState({                
                search: search,
                items: response.data,                
            });            
        });        
    }             

    render() {
        return(     
            <InputSearch 
                md={this.props.md} 
                xs={this.props.xs} 
                sm={this.props.sm} 
                lg={this.props.lg}              
                className={this.props.className}
                style={this.props.style}
                id={this.props.id}
                name={this.props.name}
                arrowLeft={this.props.arrowLeft}
                vitrine={this.props.vitrine}  
                placeholder={"O que você está procurando?"}
                items={this.state.items}       

                search={() => this.props.search()}
                autoComplete={(e) => this.autoComplete(e)}
                onClearItems={() => this.onClearItems()} 
                onChange={(e) => this.onChange(e)}
                onItemClick={(value) => this.props.onItemClick(value)}  
                onCloseButtonClick={() => this.props.onCloseButtonClick()}                 
            />                                        
        )
    }
}

export default InputSearchProduto;