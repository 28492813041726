import { temTexto } from './Validar';

export function formataLogradouro(logradouros, logradouro) {
    if (logradouro === "")
        return logradouro;

    if (logradouros === undefined)
        return logradouro;

    let lista = logradouros;
    let retorno = '';
    let str = logradouro.toUpperCase();

    lista.map(item => {
        if (retorno === "") {
            if (temTexto(item.valor, str))
                retorno = item.valor;
        }

        return item;
    });

    return retorno;
}

export function preparaEndereco(logradouro, endereco) {
    if (!logradouro)
        return endereco;

    if (!endereco)
        return endereco;

    let txtEndereco = endereco;
    var enderecos = txtEndereco.split(' ');
    let str = '';

    for (var cont = 1; cont < enderecos.length; cont++) {
        if (str !== '')
            str += " ";

        str += enderecos[cont];
    }

    return str.trim();
}

export function moeda(valor) {
    if (valor === undefined)
        valor = 0;

    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function formatarData(data) {
    if (data === '' || data === null)
        return '';

    data = "" + data + "";

    if (data.indexOf(':') > 1) {
        return data.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})(.*)$/, '$3/$2/$1 $4').substr(0, 10);
    } else {
        return data.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/, '$3/$2/$1');
    }
}

export function formatarDataHora(datahora) {
    if (datahora === '' || datahora === null)
    return '';

    datahora = "" + datahora + "";
    
    return datahora.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})\D(.*)$/, '$3/$2/$1 $4').substr(0, 16);;
}

export function formatarDia(data) {
    if (data === '' || data === null)
        return '';

    return data.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})(.*)$/, '$3').substr(0, 10);
}

export function formatarMes(data) {
    if (data === '' || data === null)
        return '';

    data = data.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})(.*)$/, '$2').substr(0, 10);
    //converte o numero em nome do mês
    switch (data) {
        case "01":
            data = "Jan";
            break;
        case "02":
            data = "Fev";
            break;
        case "03":
            data = "Mar";
            break;
        case "04":
            data = "Abr";
            break;
        case "05":
            data = "Mai";
            break;
        case "06":
            data = "Jun";
            break;
        case "07":
            data = "Jul";
            break;
        case "08":
            data = "Ago";
            break;
        case "09":
            data = "Set";
            break;
        case "10":
            data = "Out";
            break;
        case "11":
            data = "Nov";
            break;
        case "12":
            data = "Dez";
            break;
        default:
            data = "";
    }
    return data;
}

export function strZero(value, num, paddingChar) {
    var length = num - value.toString().length + 1;

    return Array(length).join(paddingChar || '0') + value;
};

export function firstName(value) {
    if (value === '')
        return '';

    var firstName = value.split(' ');

    if (firstName.length === 0)
        return '';

    return firstName[0];
}

export function formataEndereco(logradouro, endereco, upperCase = true) {
    var enderecoFormat = '';

    if (logradouro !== '')
        enderecoFormat = logradouro;

    if (endereco !== '')
        enderecoFormat += (enderecoFormat !== '' ? ' ' : '') + endereco;

    if (upperCase)
        return enderecoFormat.toUpperCase();

    return enderecoFormat.toLowerCase();
}

export function dataHoraAtual() {
    return new Date();
}

export function getMesAnoAtual(ano4digitos = true) {
    var hoje = dataHoraAtual();    
    var hojeY = hoje.getFullYear().toString();  
    
    if(ano4digitos === false) {
        hojeY = hojeY.substring(2, 4);
    }

    var hojeM = (hoje.getMonth() + 1).toString();
    hojeM = (hojeM.length === 1 ? '0' : '') + hojeM;

    return hojeM + '/' + hojeY;
}

export function incHourInDateTime(dataHora, hour) {
    //var data = new Date(dataHora.getTime() + (hour * 60 * 60 * 1000));
    var data = new Date(dataHora.getTime() + (hour * 60 * 1000));

    return data;
}

export function formataQuantidadeFloat(valor, scale) {
    let format = valor.toFixed(scale);

    return format.replace('.', ',');
}

export function strTofloat(value, scale) {            
    if(typeof value !== "string")
        return value;

    let valor = value.replace(',', '.');    

    valor = parseFloat(valor);

    return valor.toFixed(scale);
}

export function soNum(value) {
    if(!value)
        return '';

    var result = '';

    for(var cont = 0; cont <= value.length; cont++) {
        if(Number.isInteger(parseInt(value[cont], 10)))
            result += value[cont];
    }

    return result;
}

export function retiraAcentos(str) {
    const com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    const sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    var novastr = "";

    for(var i = 0; i < str.length; i++) {
        var troca = false;
        for(var a = 0; a < com_acento.length; a++) {
            if (str.substr(i,1) === com_acento.substr(a,1)) {
                novastr += sem_acento.substr(a,1);
                troca = true;
                break;
            }
        }

        if (troca === false) {
            novastr += str.substr(i,1);
        }
    }

    return novastr;
}

export function mesAnoToMesAno4(mesAno) {
    var hoje = getMesAnoAtual();

    var ano = hoje.toString();        
    ano = ano.substring(3, 5);
    ano += mesAno.substring(3, 5);

    return mesAno.substring(0, 3) + ano;
}