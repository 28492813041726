var TipoCalculoFreteEnum = {
    CIDADE: {
        enumName: 'CIDADE',        
        name: "Cidade",        
        field: "cidade"
    },
    BAIRRO_POI: {
        enumName: 'BAIRRO_POI',        
        name: "Bairro/PoI",        
        field: "bairro"        
    },
    DISTANCIA: {
        enumName: 'DISTANCIA',        
        name: "Distância",        
        field: "distancia"        
    },
    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return TipoCalculoFreteEnum.CIDADE;

        var array = Object.values(TipoCalculoFreteEnum);
        var tipoCalculoFreteFound = {};

        array.forEach(element => {            
            if(element.name === value) {
                tipoCalculoFreteFound = element;      
                return ;          
            }            
        });   
        
        return tipoCalculoFreteFound;
    }
}

export default TipoCalculoFreteEnum;