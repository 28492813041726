import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import VitrineModalDetail from '../vitrine/VitrineModalDetail';
import Alerta from '../../layout/alert/Alerta';
import Button from '../../layout/button/Button';
import Fechamento from '../fechamento/Fechamento';

import ProdutoService from '../../../service/ProdutoService';
import AutenticarService from '../../../service/AutenticarService';
import CarrinhoService from '../../../service/CarrinhoService';

import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';
import AtivoEnum from '../../../helper/enumerador/AtivoEnum';
import TipoCalculoEnum from '../../../helper/enumerador/TipoCalculoEnum';

import {moeda} from '../../../helper/Formatar';
import { route } from '../../../helper/Route';

class CarrinhoItem extends AbstractComponent {   
    constructor(props) {
        super(props);          
    
        this.state = {
            editar: false,

            preloadEditar: false,

            preloadTipoCalculoEditar: '',

            preloadRemover: false, 
            
            preloadTipoCalculoRemover: '',

            itemPreload: -1,            

            item: [],            

            idCarrinhoItem: 0,

            quantidade: 0,

            estoque: 0,

            erro: ''
        }

        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());        
    } 

    visible() {          
        return this.props.visible === false 
            ? 'display-none'
            : ''
    }

    avisoValMin() {        
        if((!this.isLogged())||
           (parseInt(this.props.carrinho.entrega.id, 10) === 0)||                      
           (this.props.carrinho.entrega.valor_pedido_minimo === 0)||
           (this.props.carrinho.entrega.valor_pedido_minimo <= this.props.carrinho.totalizador.sub_total))
            return ;

        return (
            <div className="area-cart__warning">                
                Entregas somente para compras <br/>
                acima de <strong>{moeda(this.props.carrinho.entrega.valor_pedido_minimo)}</strong>.
            </div>
        );
    }    

    getTaxaEntrega() {                
        if(this.props.carrinho.totalizador.taxa_entrega === 0.00)
            return 'Grátis';

        return moeda(this.props.carrinho.totalizador.taxa_entrega);
    }

    showMessagePedidoMinimo() { 
        if(this.props.carrinho.entrega.valor_pedido_minimo === 0.00)
            return ;
            
        if(this.props.carrinho.entrega.valor_pedido_minimo <= this.props.carrinho.totalizador.sub_total)
            return ;    

        return (
            <div className="area-cart-footer__delivery-fee" style={{marginTop: '-15px'}}>
                <span className="area-cart-footer__info-taxa-complemento">
                    * Seu pedido não atingiu o valor mínimo para entrega
                </span>                
            </div>
        );
    }    

    showTaxaEntrega() {
        if(!this.isLogged())
            return (
                <div className="area-cart__delivery">
                    Faça o <strong>login</strong> para o sistema calcular a taxa de entrega
                </div>
            );

        if(parseInt(this.props.carrinho.entrega.id, 10) === 0)        
            return (
                <div className="area-cart__delivery">
                    Selecione uma <strong>opção de entrega</strong> para o sistema calcular a taxa de entrega
                </div>
            );

        if(this.props.carrinho.entrega.tem_taxa_entrega === AtivoEnum.NAO)
            return ;

        return (
            <Fragment>
                <div className="area-cart-footer__delivery-fee area-cart__justify">
                    <span className="area-cart-footer__info-taxa">
                        Taxa de entrega                       

                        &nbsp;

                        <span 
                            className="btn__label cursor-pointer" 
                            onClick={() => this.props.route(new route(
                                '',
                                'Taxa de Entrega',
                                'taxa-entrega'
                            ))}
                        >
                            <i className="fa fa-question-circle"></i>
                        </span>  
                    </span>                                                   

                    <span>
                        { this.getTaxaEntrega() }                                        
                    </span>
                </div>                
            </Fragment>
        )
    }

    onUpdateCart(carrinho, indiceCarrinhoItem, quantidade) {                
        const item = quantidade === undefined ? null : this.state.item;        
        const itemPreload = (indiceCarrinhoItem === undefined ? this.state.itemPreload : indiceCarrinhoItem);

        this.setState({
            erro: '',
            editar: false,
            item: [],
            itemPreload: -1
        });                 
                    
        this.props.onUpdateCart(carrinho, item, itemPreload, quantidade);        
    }    

    onEditClick(item, i) {     
        this.setState({
            preloadEditar: true, 
            itemPreload: i,
            preloadTipoCalculoEditar: item.tipo_calculo
        });

        ProdutoService.buscarProdutoByUuid(            
            item.uuid_produto
        ).then(response => {            
            this.setState({
                erro: '',
                editar: true, 
                item: response.data,    
                estoque: item.estoque,            
                quantidade: item.quantidade,
                idCarrinhoItem: item.id,
                preloadEditar: false,
                preloadTipoCalculoEditar: ''               
            });        
        }).catch(erro => {            
            this.setState({
                erro: this.trataErro(erro),
                preloadEditar: false,
                preloadTipoCalculoEditar: ''
            });
        });
    }

    onRemoveClick(item, i) {  
        this.setState({
            preloadRemover: true,
            itemPreload: i,
            preloadTipoCalculoRemover: item.tipo_calculo,
            item: item
        });                        

        CarrinhoService.remove(            
            AutenticarService.getDocumentoCliente(),
            AutenticarService.getIdCarrinho(),
            item.id
        ).then(response => {            
            let carrinho = this.props.carrinho;
            
            carrinho.tem_produto_pesavel = response.data.tem_produto_pesavel;
            
            if(item.tipo_calculo.toUpperCase() === TipoCalculoEnum.QUANTIDADE.enumName) {
                let carrinhoItem = this.props.carrinho.carrinho_item_quantidade;            

                carrinhoItem.splice(i, 1);
                carrinho.carrinho_item_quantidade = carrinhoItem;
            }
            else {
                let carrinhoItem = this.props.carrinho.carrinho_item_peso;

                carrinhoItem.splice(i, 1);
                carrinho.carrinho_item_peso = carrinhoItem;
            }
            
            let totalizador = this.props.carrinho.totalizador;

            totalizador.sub_total_quantidade = response.data.sub_total_quantidade;
            totalizador.sub_total_peso = response.data.sub_total_peso;
            totalizador.sub_total = response.data.subtotal;
            totalizador.taxa_entrega = response.data.taxaEntrega;
            totalizador.total = response.data.total;

            carrinho.totalizador = totalizador;            
            
            let itemAux = item;

            itemAux.uuid = itemAux.uuid_produto;

            this.setState({item: itemAux});

            this.onUpdateCart(carrinho, this.state.itemPreload, (-1) * item.quantidade);            

            this.setState({
                preloadRemover: false,
                preloadTipoCalculoRemover: ''
            });
        }).catch(erro => {
            this.setState({
                erro: this.trataErro(erro),
                preloadRemover: false,
                preloadTipoCalculoRemover: ''
            });
        });
    }

    showModal() {
        if(!this.state.editar)
            return ;
            
        return (
            <VitrineModalDetail
                className={"modal-detail"}                                      
                header="DETALHES DO PEDIDO"

                show={true}
                update={true}                    
                
                fechar={() => this.setState({editar: false})}

                item={this.state.item}
                estoque={this.state.estoque}
                carrinho={this.props.carrinho}

                idCarrinhoItem={this.state.idCarrinhoItem}
                quantidade={this.state.quantidade}
                
                onUpdateCart={(carrinho, indiceCarrinhoItem, quantidade) => this.onUpdateCart(carrinho, indiceCarrinhoItem, quantidade)}
            />  
        )                         
    }

    complementoTotalizador() {
        if(this.props.carrinho.carrinho_item_peso.length === 0)
            return ;

        return ' Aproximado';
    }

    showSubtotal(total, tipoCalculo) {        
        return (
            <div className="area-cart-footer">
                <div className="area-cart-footer__info area-cart__line">
                    <div className="area-cart__justify">
                        <span className="area-cart-footer__info-subtotal">
                            Subtotal {tipoCalculo === TipoCalculoEnum.PESO.enumName ? ' a Confirmar' : ''}
                        </span>
                        
                        <span>
                            {moeda(total)}
                        </span>
                    </div>                                                                
                </div>                                                
            </div>
        );
    }

    renderCarrinhoQuantidade() {
        return (       
            <Fragment>
                {this.props.carrinho.carrinho_item_quantidade.map((item, i ) => {
                    return(
                        <div 
                            className={"area-cart-item area-cart__line"}
                            key={i}
                        >
                            <div
                                className="area-cart-item__description
                                    area-cart__justify"
                            >
                                <span className={(((item.ativo === AtivoEnum.NAO)||((item.estoque < 0)&&(item.controla_estoque === AtivoEnum.SIM))) ? 'text-decoration-line-through' : '')}>                                            
                                    {TipoCalculoEnum.get(item.tipo_calculo).show(item.quantidade) + ' ' + item.unidade} x {item.descricao} 
                                </span>
                                
                                <span className={(((item.ativo === AtivoEnum.NAO)||((item.estoque < 0)&&(item.controla_estoque === AtivoEnum.SIM))) ? 'text-decoration-line-through' : '')}>
                                    {item.ativo === AtivoEnum.NAO ? 'INDISPONÍVEL' : moeda(item.total)}
                                </span>
                            </div>
                            
                            <div className="area-cart-item__buttons-wrapper">    
                                {
                                    item.ativo === AtivoEnum.SIM &&                                
                                    <Button
                                        type="button"    
                                        preload={this.state.preloadEditar && this.state.itemPreload === i && this.state.preloadTipoCalculoEditar === item.tipo_calculo}
                                        disabled={this.state.preloadRemover || this.state.preloadEditar}
                                        width={'95px'}
                                        background={2}
                                        color={true}
                                        onClick={() => this.onEditClick(item, i)}
                                    >
                                        Editar
                                    </Button>
                                }
                
                                <Button
                                    type="button"                                                                 
                                    disabled={this.state.preloadRemover || this.state.preloadEditar}
                                    preload={this.state.preloadRemover && this.state.itemPreload === i && this.state.preloadTipoCalculoRemover === item.tipo_calculo}
                                    width={'95px'}
                                    background={2}
                                    color={false}
                                    onClick={() => this.onRemoveClick(item, i)}
                                >
                                    Remover
                                </Button>                                    
                            </div>                                                    
                        </div>
                    )
                })}

                {
                    this.props.carrinho.carrinho_item_peso.length > 0 && this.props.carrinho.carrinho_item_quantidade.length > 0 &&
                    this.showSubtotal(this.props.carrinho.totalizador.sub_total_quantidade, TipoCalculoEnum.QUANTIDADE.enumName)
                }
            </Fragment>                       
        );
    }

    renderCarrinhoPeso() {
        return (           
            <Fragment>
                {this.props.carrinho.carrinho_item_peso.map((item, i ) => {
                    return(
                        <div 
                            className={"area-cart-item area-cart__line"}
                            key={i}
                        >
                            <div
                                className="area-cart-item__description
                                    area-cart__justify"
                            >
                                <span className={(((item.ativo === AtivoEnum.NAO)||(item.estoque < 0)) ? 'text-decoration-line-through' : '')}>                                            
                                    {TipoCalculoEnum.get(item.tipo_calculo).show(item.quantidade) + ' ' + item.unidade} x {item.descricao} 
                                </span>
                                
                                <span className={(((item.ativo === AtivoEnum.NAO)||(item.estoque < 0)) ? 'text-decoration-line-through' : '')}>
                                    {item.ativo === AtivoEnum.NAO ? 'INDISPONÍVEL' : moeda(item.total)}
                                </span>
                            </div>
                            
                            <div className="area-cart-item__buttons-wrapper">    
                                {
                                    item.ativo === AtivoEnum.SIM &&                                
                                    <Button
                                        type="button"    
                                        preload={this.state.preloadEditar && this.state.itemPreload === i && this.state.preloadTipoCalculoEditar === item.tipo_calculo}
                                        disabled={this.state.preloadRemover || this.state.preloadEditar}
                                        width={'95px'}
                                        background={2}
                                        color={true}
                                        onClick={() => this.onEditClick(item, i)}
                                    >
                                        Editar
                                    </Button>
                                }
                
                                <Button
                                    type="button"                                                                 
                                    disabled={this.state.preloadRemover || this.state.preloadEditar}
                                    preload={this.state.preloadRemover && this.state.itemPreload === i && this.state.preloadTipoCalculoRemover === item.tipo_calculo}
                                    width={'95px'}
                                    background={2}
                                    color={false}
                                    onClick={() => this.onRemoveClick(item, i)}
                                >
                                    Remover
                                </Button>                                    
                            </div>                                                    
                        </div>
                    )
                })}

                {
                    this.props.carrinho.carrinho_item_peso.length > 0 && this.props.carrinho.carrinho_item_quantidade.length > 0 &&
                    this.showSubtotal(this.props.carrinho.totalizador.sub_total_peso, TipoCalculoEnum.PESO.enumName)
                }
            </Fragment>                    
        );
    }

    render() {
        return(            
            <div className={this.visible()} >                
                <div className="area-cart-content">
                    {this.avisoValMin()}                                        

                    <div className="area-cart-header">                    
                        <div className="area-cart-header__pretitle">
                            {this.shoppingBagType.description()}
                        </div>

                        <Alerta
                            style={{marginTop: '10px'}}
                            exibir={this.state.erro !== ''}
                            text={this.state.erro}
                        />
                    </div>                                                                    

                    <div className="area-cart-items-list">
                         {this.renderCarrinhoQuantidade()}

                         {this.renderCarrinhoPeso()}
                    </div>
                    
                    <div className="area-cart-footer">
                        <div className="area-cart-footer__info area-cart__line">
                            <div className="area-cart__justify">
                                <span className="area-cart-footer__info-subtotal">
                                    Subtotal {this.complementoTotalizador()}
                                </span>
                                
                                <span>
                                    {moeda(this.props.carrinho.totalizador.sub_total)}
                                </span>
                            </div>
                                                        
                            {this.showTaxaEntrega()}     

                            { this.showMessagePedidoMinimo() }

                            <div className="area-cart-footer__total area-cart__justify">
                                <span className="area-cart-footer__info-description">
                                    Total {this.complementoTotalizador()}
                                </span>
                                
                                <span>
                                    {moeda(this.props.carrinho.totalizador.total)}
                                </span>
                            </div>
                        </div>                                                
                    </div>                     

                    <Fechamento 
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}                                                                   
                        cliente={this.props.cliente}
                        empresa={this.props.empresa}
                        carrinho={this.props.carrinho} 
                        pagamento={this.props.pagamento}
                        modalidadePagamento={this.props.modalidadePagamento}                       
                        opcaoEntrega={this.props.opcaoEntrega}                        

                        onUpdateCart={(carrinho) => this.onUpdateCart(carrinho, -1, undefined)}
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)}                        
                        onUpdatePagamento={(id, descricao, troco, opcaoPagamento, permite_parcelamento) => this.props.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento)}
                        
                        refreshCart={() => this.props.refreshCart()}
                        
                        route={(item) => this.props.route(item)}
                    />
                </div>

                {this.showModal()}
            </div>
        )
    }
}


export default CarrinhoItem;