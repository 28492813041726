import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import DadosPessoaisContent from './content/DadosPessoaisContent';

import { validaData } from '../../helper/Validar';

class DadosPessoais extends AbstractComponent {
    constructor(state) {
        super(state);

        this.state = {
            form: {
                cpf: '',
                nome: '',
                dataNascimento: '',                
                telefone: ''
            },

            erroForm: {
                nome: '',
                dataNascimento: '',                
                telefone: ''
            }            
        }

        let form = this.state.form;

        form.cpf = this.props.cpf;

        if(this.props.form !== undefined) {
            form.nome = this.props.form.nome;                    
        }

        this.state.form = form;
    }

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;  
        this.setState({ form: form });
    }

    onErroData(e) {
        let erro = this.state.erroForm;

        erro.dataNascimento = e;

        this.setState({ erroForm: erro });
    }        

    limpaVariavelErro() {
        let erro = this.state.erroForm;

        erro.nome = '';
        erro.dataNascimento = '';        
        erro.telefone = '';        

        this.setState({ erroForm: erro });
    } 

    valida() {
        this.limpaVariavelErro();

        let form = this.state.form;
        let erro = this.state.erroForm;
        let exibeErro = false;

        if (!form.nome) {
            erro.nome = "Informe o campo Nome";
            exibeErro = true;
        }

        if ((!form.dataNascimento) || (form.dataNascimento === '  /  /    ')) {
            erro.dataNascimento = "Informe o campo Data Nascimento";
            exibeErro = true;
        }

        if (!validaData(form.dataNascimento)) {
            erro.dataNascimento = "Data inválida";
            exibeErro = true;
        }

        if (!form.telefone) {
            erro.telefone = "Informe o campo Telefone";
            exibeErro = true;
        }

        if (exibeErro) {            
            this.foco("nome");
            return;     
        }   
        
        this.props.onComplete(this.state.form);
    }

    componentDidMount() {
        if(this.props.onTitulo !== undefined)
            this.props.onTitulo('Passo 1: Dados Pessoais');
    }

    showComponent() {
        if(!this.props.show)
            return ;
        
        return (
            <Fragment>
                <DadosPessoaisContent                    
                    form={this.state.form}                    
                    erroForm={this.state.erroForm}
                    
                    onChange={e => this.onChange(e)}
                    onErroData={e => this.onErroData(e)}
                    />
                
                <div className="row">
                    <div className="text-right col-md-12">
                        <button
                            type="button"
                            className="btn btn-success  btn-lg btn-block"
                            onClick={() => this.valida()}>
                            { this.props.btnName }
                            &nbsp;
                            &nbsp;

                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                {this.showComponent()}
            </Fragment>
        );
    }
}

export default DadosPessoais;