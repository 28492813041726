import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AbstractComponent from '../../AbstractComponent';
import VitrineEmpty from './VitrineEmpty';
import Button from '../../layout/button/Button';
import ButtonsProduto from '../ButtonsProdutos';
import config from '../../../Config';

import AutenticarService from '../../../service/AutenticarService';

import VitrineEnum from '../../../helper/enumerador/VitrineEnum';
import AtivoEnum from '../../../helper/enumerador/AtivoEnum';

import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';
import { changeQuantidade, calculaTotal } from '../../../helper/InputSpin';
import ButtonTellMe from '../../layout/button/ButtonTellMe';

class VitrineProdutoContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            erroQtd: '',

            alerta: ''
        }

        this.vitrineType = this.props.telaFavorito ? VitrineEnum.FAVORITO : VitrineEnum.GRADE;        
        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    }

    onChangeQuantidade(input, item) {
        let quantidade = changeQuantidade(input.target.value);

        this.setState({
            quantidade: quantidade
        });

        calculaTotal(this.getValor(item), quantidade);
    }

    onClickQuantidade(value, item) {
        const quantidade = parseInt(value, 10);

        if(quantidade === 0)
            return ;

        this.props.changeQuantidade(item, quantidade)
    }

    next() {
        if (this.props.fetchMoreData === undefined)
            return;

        this.props.fetchMoreData();
    }

    showVitrine() {
        if(this.props.inPreload)
            return ;

        if(this.props.vitrine.length === 0)
            return (
                <VitrineEmpty
                    searchKey={this.props.searchKey}                    
                />
            );

        return (
            <Fragment>
                <InfiniteScroll
                    dataLength={this.props.vitrine.length}
                    next={() => this.next()}
                    hasMore={true}
                    style={{overflow: 'none'}}
                >
                    {this.props.vitrine.map((item, i) => {                        
                        return (
                            <div className={"vitrine-container-" + this.vitrineType.enumName + " " + this.vitrineType.css()} key={i}>                                                                                                                                    
                                <a onClick={() => this.props.onClick(item, i)}>
                                    { this.vitrineType.content(item) }

                                    {
                                        (((item.carrinho_item_quantidade === undefined)&&((item.estoque > 0)||(item.controla_estoque === AtivoEnum.NAO)))||(((item.estoque <= 0)&&(item.controla_estoque === AtivoEnum.SIM))||(item.ativo === AtivoEnum.NAO))) &&
                                        <div style={{height: '50px'}} />
                                    }

                                    {
                                        ((item.carrinho_item_quantidade !== undefined)&&((item.estoque > 0)||(item.controla_estoque === AtivoEnum.NAO))) &&
                                        <Button
                                            type="button"
                                            className="product-action__counter__button text-center"
                                            onClick={() => this.props.onClick(item, i)}
                                            display={'block'}
                                        >
                                            Alterar
                                        </Button>
                                    }                                    
                                </a>

                                <ButtonTellMe
                                    item={item}
                                    show={(((item.estoque <= 0)||(item.ativo === AtivoEnum.NAO))&&(item.controla_estoque === AtivoEnum.SIM))}

                                    route={(item) => this.props.route(item)}
                                />

                                <ButtonsProduto
                                    show={(((item.estoque > 0)||(item.controla_estoque === AtivoEnum.NAO))&&(item.ativo === AtivoEnum.SIM))}
                                    uuid={item.uuid}
                                    favorito={item.favorito}                                    

                                    onUpdateBadgeFavorito={(tipo) => this.props.onUpdateBadgeFavorito(tipo, i)}

                                    route={(item) => this.props.route(item)}
                                /> 

                                {/* <div className="row">
                                    <Alerta
                                        exibir={this.state.alerta !== ''}
                                        texto={this.state.alerta}
                                    />
                                </div>

                                <div className="row">
                                    <InputSpin
                                        md={5}
                                        xs={5}
                                        sm={12}
                                        lg={5}
                                        className="product-action__counter__label"
                                        nome={"qtd"}
                                        value={(item.carrinho_item_quantidade === undefined ? 1 : item.carrinho_item_quantidade.quantidade)}
                                        defaultValue={(item.carrinho_item_quantidade === undefined ? 1 : item.carrinho_item_quantidade.quantidade)}
                                        minValue={1}
                                        maxValue={item.estoque}
                                        erro={this.state.erroQtd}
                                        textoComplMaxValueSingular={'disponivel'}
                                        textoComplMaxValuePlural={'disponiveis'}

                                        onChange={(input) => this.onChangeQuantidade(input, item)}
                                        onMenosClick={(value) => this.onClickQuantidade(value, item)}
                                        onMaisClick={(value) => this.onClickQuantidade(value, item)}
                                    />

                                    <AddCartButton
                                        md={7}
                                        xs={7}
                                        sm={12}
                                        lg={7}
                                        textoButton={"Adicionar " + this.shoppingBagType.add()}

                                        idCarrinhoItem={(item.carrinho_item_quantidade === undefined ? 0 : item.carrinho_item_quantidade.id)}
                                        quantidade={(item.carrinho_item_quantidade === undefined ? 1 : item.carrinho_item_quantidade.quantidade)}

                                        item={item}
                                        carrinho={this.props.carrinho}

                                        update={item.carrinho_item_quantidade !== undefined}

                                        onError={(erro) => this.setState({alerta: erro})}
                                        onSuccess={(carrinho, indiceCarrinhoItem, quantidade) => this.props.onUpdateCart(carrinho, indiceCarrinhoItem, quantidade)}
                                    />
                                </div>
                                */}
                            </div>
                        )
                    })}
                </InfiniteScroll>

                {
                    this.props.vitrinePreload &&
                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-center" >
                        <img src={config.urlImgBase + "preload_botao.gif"} alt="Loading..." width='35px' height='35px'/>
                    </div>
                }
            </Fragment>
        );
    }

    render() {
        return (
            <div className="vitrine">
                { this.showVitrine() }
            </div>
        )
    }
}

VitrineProdutoContent.defaultProps = {
    vitrine: [],
    telaFavorito: false
}

export default VitrineProdutoContent;