import React, { Fragment } from 'react';
import AbstractFilter from '../../filter/AbstractFilter';
import DesktopMenu from '../../menu/desktop/DesktopMenu';
import CategoriaMobileModal from '../../../categoria/CategoriaMobileModal';
import InputToggle from '../../input/InputToggle';

class NavMenuDesktop extends AbstractFilter {   
    constructor(props) {
        super(props);

        this.state = {
            filtroType: '',

            filtro: []
        }        
    } 

    onApplyFiltro(items) {
        let filtro = this.preparaFiltro(items, this.state.filtro, this.state.filtroType);  

        this.setState({
            filtro: filtro,
            filtroType: ''
        });

        this.props.onApplyFiltro(this.state.filtro);                  
    }

    onChange(e) {
        let filtro = this.preparaFiltro(e.target.value, this.state.filtro, e.target.name);  

        this.setState({
            filtro: filtro
        });

        this.props.onApplyFiltro(this.state.filtro);
    }

    limparFiltro() {
        let filtro = this.state.filtro;

        for(var cont = 0; cont <= filtro.length; cont++)
            filtro.pop();        

        this.setState({filtro: filtro});

        this.props.onApplyFiltro(this.state.filtro);
    }

    render() {        
        return(    
            <Fragment >
                {
                    this.state.filtroType !== '' &&    
                    <CategoriaMobileModal
                    
                        show={true}
                        header={this.state.filtroType}                                                                    
                        filtros={this.getCategoria(this.state.filtroType, this.props.filtros, this.state.filtro)}

                        fechar={() => this.setState({filtroType: ''})}                        
                        onApply={(items) => this.onApplyFiltro(items)}
                    />
                }

                <div 
                    className="col-md-1
                               col-sm-1
                               col-lg-1
                               col-xs-1
                               text-center"
                    style={{marginRight: '4px'}}
                >
                </div>
                
                <DesktopMenu
                
                    md={1}
                    xs={1} 
                    sm={1} 
                    lg={1}
                    badge={this.getCategoria('Departamento', this.props.filtros, this.state.filtro).length}
                    menuName="Departamento"
                    buttonClick={() => this.setState({ filtroType: 'departamento' })}
                />                 

                <DesktopMenu
                    md={1}
                    xs={1} 
                    sm={1} 
                    lg={1}
                    menuName="Marca"
                    badge={this.getCategoria('Marca', this.props.filtros, this.state.filtro).length}
                    buttonClick={() => this.setState({ filtroType: 'marca' })}
                />                 

                <DesktopMenu
                    md={1}
                    xs={1} 
                    sm={1} 
                    lg={1}
                    menuName="Grupo"
                    badge={this.getCategoria('Grupo', this.props.filtros, this.state.filtro).length}
                    buttonClick={() => this.setState({ filtroType: 'grupo' })}
                />                

                <div                     
                    style={{
                        height: 'auto',
                        width: '9.33333333%',
                        marginTop: '-10px'
                    }}
                >
                    <InputToggle
                        md={12} 
                        xs={12} 
                        sm={12} 
                        lg={12} 
                        className="ofertas"
                        nome="ofertas"
                        style={{
                            padding: '0px'
                        }}                    
                        align="right" 
                        lineHeight="unset"                  
                        value={this.getCategoria('ofertas', this.props.filtros, this.state.filtro)}
                        checked={parseInt(this.getCategoria('ofertas', this.props.filtros, this.state.filtro),10) === 1}                        
                        onChange={(e) => this.onChange(e)}
                    >     
                        Ofertas &nbsp;               
                    </InputToggle>
                </div>

                {
                    this.state.filtro.length > 0 &&                
                    <DesktopMenu
                        md={2}
                        xs={2} 
                        sm={2} 
                        lg={2}
                        menuName="Limpar Filtros"                    
                        buttonClick={() => this.limparFiltro()}
                    />   
                }
            </Fragment>
        )
    }
}

export default NavMenuDesktop;