import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

class Input extends AbstractComponent {        
    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }

    className() {
        return this.props.className !== undefined ? this.props.className : '';
    }    

    classNameInput() {
        return this.props.classNameInput !== undefined ? this.props.classNameInput : '';
    }
    
    classErro() {
        return this.props.erro !== undefined && this.props.erro !== '' ? 'form-controll-erro' : '';
    }    

    onChange(input) {        
        if (this.props.onChange !== undefined) {
            this.props.onChange(input);
        }
    }    

    onKeyPress(input) {        
        if (this.props.onKeyPress !== undefined) {
            this.props.onKeyPress(input);
        }
    }   

    onBlur(input) {        
        if (this.props.onBlur !== undefined) {
            this.props.onBlur(input);
        }
    }

    onSelect(input) {
        if (this.props.onSelect !== undefined) {
            this.props.onSelect(input);
        }
    }
    
    onFocus(input) {
        if (this.props.onFocus !== undefined) {
            this.props.onFocus(input);
        }
    }

    componentDidMount() {
        if(!this.props.defaultFoco)
            return ;

        this.foco(this.props.nome);
    }

    render() {
        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>
                <div className={this.className() + ' ' + this.classErro()} >
                    <label htmlFor={this.props.nome}>
                        {this.props.children}
                    </label>

                    <input 
                        ref={this.props.inputRef}
                        autoComplete="off"                           
                        type={this.props.type} 
                        name={this.props.nome} 
                        id={this.props.id}             
                        value={this.props.value}               
                        //defaultValue={this.props.defaultValue} 
                        className={"form-control " + this.classNameInput()}
                        disabled={this.props.disabled} 
                        maxLength={this.props.maxLength}                           

                        onChange={input => this.onChange(input)}                            
                        onKeyPress={input => this.onKeyPress(input)}
                        onBlur={e => this.onBlur(e)} 
                        onSelect={e => this.onSelect(e)}
                        onFocus={e => this.onFocus(e)}                        
                    />

                    <span className="help-block">
                        {this.erro()}
                    </span>
                </div>
            </Col>
        )
    }
}

Input.defaultProps = {
    md: 12,
    type: 'text',
    defaultFoco: false
};

export default Input;