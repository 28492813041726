import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Form from "../../layout/form/Form";
import Input from "../../layout/input/Input";
import InputPassword from "../../layout/input/InputPassword";

import ClienteService from '../../../service/ClienteService';

import { validarEmail } from '../../../helper/Validar';

class CredentialsContent extends AbstractComponent {
    constructor(state) {
        super(state);

        this.state = {                                    
            form: {},            

            erroForm: {
                email: '',
                senha: '',
                confirmar_senha: ''                
            },
            
            btnFinalizarTxt: 'FINALIZAR'
        }

        this.state.form = this.props.form;
    }

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;                
        this.setState({ form: form });
    }        

    limpaVariavelErro() {
        let erro = this.state.erroForm;

        erro.email = '';
        erro.senha = '';        
        erro.confirmar_senha = '';        

        this.setState({ erroForm: erro });
    } 

    valida() {
        this.limpaVariavelErro();

        let form = this.state.form;
        let erro = this.state.erroForm;
        let exibeErro = false;

        if (!form.email) {
            erro.email = "Informe o campo e-mail";
            exibeErro = true;
        }

        if (!validarEmail(form.email)) {
            erro.email = "E-mail inválido";
            exibeErro = true;
        }

        if (!form.senha) {
            erro.senha = "Informe o campo senha";
            exibeErro = true;
        }

        if (form.senha.length < 6) {
            erro.senha = "O Campo senha deve conter no mínimo 6 caracteres";
            exibeErro = true;
        }
        if (exibeErro) {
            this.setState({ erroForm: erro });

            return false;
        }

        return true;
    }
    
    onSubmit() {        
        if(!this.valida())
            return false;

        this.setState({ btnFinalizarTxt: 'AGUARDE...' });        

        ClienteService.cadastro(this.state.form).then(() => {
            this.props.onRegisterOk();            
        }).catch(error => {            
            this.props.onError(this.trataErro(error));

            this.setState({                
                btnFinalizarTxt: 'FINALIZAR'                
            });
        });
    }                                   

    componentDidMount() {
        this.props.onTitulo('Passo 3: Dados de Acesso');
    }

    render() {
        return (            
            <Form onSubmit={() => this.onSubmit()} id="form">
                <div className="row">
                    <Input
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        id="email"
                        nome="email"
                        value={this.state.form.email}
                        defaultValue={this.state.form.email}
                        defaultFoco={true}
                        erro={this.state.erroForm.email}
                        onChange={e => this.onChange(e)}>
                        E-mail
                    </Input>
                </div>

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        type="password"
                        nome="senha"
                        value={this.state.form.senha}
                        defaultValue={this.state.form.senha}
                        erro={this.state.erroForm.senha}
                        onChange={e => this.onChange(e)}>
                        Senha
                    </InputPassword>
                </div>

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        type="password"
                        nome="confirmar_senha"
                        value={this.state.form.confirmar_senha}
                        defaultValue={this.state.form.confirmar_senha}
                        erro={this.state.erroForm.confirmar_senha}
                        onChange={e => this.onChange(e)}>
                        Confirmar Senha
                    </InputPassword>
                </div>

                <input type="hidden" name="id" value={this.state.form.id} />

                <div className="row">
                    <div className="text-right col-md-12">
                        <button 
                            className="btn btn-success btn-lg btn-block"
                            type="submit"
                            disabled={(this.state.btnFinalizarTxt !== 'FINALIZAR')}
                        >
                            {this.state.btnFinalizarTxt}
                        </button>
                    </div>
                </div>
            </Form>            
        );
    }
}

export default CredentialsContent;