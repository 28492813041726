import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Preload from '../preload/Preload';
import InputSelect from './InputSelect';
import LogradouroService from '../../../service/LogradouroService';

class InputSelectLogradouro extends AbstractComponent {

    constructor(props) {
        super(props);  
        
        this.state = {
            listas: [{valor: 0, texto: 'Selecione'}], 
            preload: true           
        }        
    }    

    onChangeSelect(input) {        
        if (this.props.onChange !== undefined) {
            this.props.onChange(input);
        }
    }
    
    componentDidMount() {         
        LogradouroService.listar().then(resposta => { 
            const lista = [{valor: 0, texto: 'Selecione'}];

            resposta.data.map(item => {
                lista.push({valor: item.logradouro, 
                            texto: item.logradouro, 
                            selected: (parseInt(item.id, 10) === parseInt(this.props.editarId, 10))
                })
                
                return item;
            });

            if(this.props.onLoad != null)
                this.props.onLoad(lista);

            this.setState({listas: lista, preload: false});            
        }).catch(error => {            
            this.setState({preload: false});

            if(error.response === undefined || error.response.data === '') {
                this.setState({erro: this.P_ERRO_PADRAO()});
                return;
            }            

            this.setState({erro: error.response.data});
        });        
    }            

    render() {
        return (    
            <Fragment>
                <Preload exibir={this.state.preload} />

                <InputSelect md={this.props.md} 
                            xs={this.props.xs} 
                            sm={this.props.sm} 
                            lg={this.props.lg} 
                            classNameInput={this.props.classNameInput}                        
                            nome={this.props.nome}                                                         
                            id={this.props.nome} 
                            options={this.state.listas}                            
                            value={this.props.value}                             
                            defaultValue={this.props.defaultValue}
                            erro={this.props.erro}
                            onChange={e => this.onChangeSelect(e)}>                            
                    Logr 
                </InputSelect>            
            </Fragment>
        );
    }
}

export default InputSelectLogradouro;
