import React from 'react';
import AbstractComponent from '../../AbstractComponent';

class Alerta extends AbstractComponent {
    constructor(props) {
        super(props);
    
        this.state = {
            show: false,
            texto: ''            
        };
    }

    exibir(texto) {
        this.setState({
            show: true, 
            texto: texto
        });
    }

    fechar() {
        this.setState({show: false});
        
        if(this.props.exibir !== undefined) {
            this.props.fechar();
        }
    }    

    textoNegrito() {
        if(this.props.textoNegrito === '') {
            return ;
        }

        return (
            <b>{this.props.textoNegrito}</b>
        )
    }

    texto() {        
        if(this.props.texto === undefined || this.props.texto === '' || this.props.texto ===  null)
            return this.P_ERRO_PADRAO();

        if(Array.isArray(this.props.texto)) {
            return(
                <ul>
                    {this.props.texto.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.texto;                      
    }

    show() {
        const value = (this.state.show || this.props.exibir);                

        if((value)&&(this.props.timeOut > 0)) {
            setTimeout(() => this.props.onTimeOut(), this.props.timeOut * 1000);
        } 

        return value;
    }

    style() {
        if(! this.props.hasStyle) {
            return this.props.style;            
        }

        return {
            position: 'absolute',
            right: 20,
            top: 0,
            marginTop: '10px',
            boxShadow: '5px 5px 5px #e7e7e7',
            zIndex: '9999'
        }
    }

    render() {
        return (
            <div>
                {
                    this.show() &&
                    <div className="alert alert-danger" style={this.style()} role="alert">
                        {this.texto()}
                    </div>                    
                }
            </div>
        )
    }
}

Alerta.defaultProps = {
    timeOut: 0,
    hasStyle: false
}

export default Alerta;